<template>
  <div class="container static-content">
    <h1>{{ $t("top_menu.about") }}</h1>
    <es-about v-if="$i18n.locale == 'es'" />
    <pt-about v-else-if="$i18n.locale == 'pt'" />
    <fr-about v-else-if="$i18n.locale == 'fr'" />
    <en-about v-else />

    <h2>{{ $t("top_menu.disclaimer") }}</h2>
    <p>
      {{ $t("top_menu.disclaimer_content") }}
    </p>
  </div>
</template>
<script>
import enAbout from "./translations/about/enAbout.vue";
import EsAbout from "./translations/about/esAbout.vue";
import FrAbout from "./translations/about/frAbout.vue";
import PtAbout from "./translations/about/ptAbout.vue";

export default {
  components: { enAbout, EsAbout, PtAbout, FrAbout },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables.scss";
@import "../assets/styles/typography.scss";

// h2 {
//   margin: auto;
//   text-align: center;
//   vertical-align: middle;
//   padding: 5px;
// }
// p {
//   margin: auto;
//   text-align: center;
//   vertical-align: middle;
// }
// img {
//   background-color: $white;
// }
</style>
