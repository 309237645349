<template>
  <div>
    <h1>POLITIQUE DE CONFIDENTIALITÉ DES SITES ET SYSTÈMES WEB DE BIREME/OPAS</h1>

    <p>
      L'utilisation des produits, services et/ou outils que le Centre latino-américain et
      caribéen d'information en sciences de la santé (BIREME), Centre spécialisé de
      l'Organisation panaméricaine de la santé, Bureau régional pour les Amériques de
      l'Organisation mondiale de la santé, ci-après BIREME/OPAS, met à disposition des
      utilisateurs, constitue l'acceptation de la Politique de confidentialité suivante.
      En cas de divergence entre le texte en espagnol et toute autre langue de cette
      Politique de confidentialité, le document en espagnol prévaudra.
    </p>

    <p>
      Cette politique de confidentialité est complétée par la
      <a
        href="https://www.paho.org/hq/index.php?option=com_content&view=article&id=3201:paho-website-privacy-policy&Itemid=2410&lang=en"
        target="_blank"
        >Politique générale de confidentialité de l'OPS.
        <font-awesome-icon icon="external-link-alt" class="ml-1" /></a
      >.
    </p>

    <h1>CONFIDENTIALITÉ</h1>
    <p>
      Votre vie privée est d'une importance primordiale pour BIREME/OPAS. Cette politique
      de confidentialité décrit les pratiques de BIREME/OPAS en ce qui concerne la
      collecte et l'échange d'informations des utilisateurs du présent site de
      BIREME/OPAS.
    </p>

    <h2>Quel type d'informations BIREME/OPAS collecte-t-elle ?</h2>
    <h3>Utilisation des sites</h3>

    <p>
      En général, vous pouvez consulter les sites de BIREME/OPAS sans vous identifier. Les
      informations collectées lors d'une consultation générale proviennent des
      enregistrements ordinaires du serveur et comprennent votre adresse IP (protocole
      Internet), le nom de domaine, le type de navigateur, le système d'exploitation, les
      informations sur la façon dont vous êtes arrivé chez nous, les fichiers que vous
      avez téléchargés, les sites que vous avez consultés et la date et l'heure de ces
      consultations. Des données démographiques peuvent également être collectées lors
      d'une consultation, si vous êtes authentifié sur un service tiers autorisé à
      partager vos informations d'âge, de sexe et de catégories d'intérêt.
    </p>

    <h3>Collecte d'informations permettant d'identifier la personne</h3>

    <p>
      Si vous vous inscrivez pour accéder à un site, un produit ou un service de
      BIREME/OPAS, comme les Services personnalisés Mi BVS et FI-Admin, vous devrez
      fournir des informations personnelles telles que votre nom, votre adresse e-mail,
      l'institution dans laquelle vous travaillez, votre pays d'origine, votre niveau
      d'études, votre domaine d'activité professionnelle, votre sexe, votre date de
      naissance et les données de vos réseaux sociaux. Ces informations ne seront
      collectées qu'avec votre consentement et seront conservées dans les bases de données
      de BIREME/OPAS, ainsi que dans les listes de diffusion.
    </p>

    <p>
      Pour certains sites de BIREME/OPAS, l'inscription ou certaines informations
      personnelles entraînent l'installation d'un cookie* sur votre ordinateur, qui
      permettra à BIREME/OPAS de se souvenir de vos informations la prochaine fois que
      vous consultez le site, afin que vous n'ayez pas à saisir vos données à nouveau,
      nous aidant à vous offrir un meilleur service.
    </p>

    <p>
      Si vous rejoignez un groupe de discussion électronique, d'autres participants du
      groupe (y compris des personnes externes à l'OPAS) pourront voir vos informations
      personnelles. Dans le cas des groupes de discussion ouverts, ces informations seront
      publiques.
    </p>

    <h2>Que fait BIREME/OPS des informations collectées?</h2>

    <p>
      Les informations collectées lors d'une consultation générale sont utilisées pour
      analyser les tendances et l'utilisation des sites de BIREME/OPS, ainsi que pour
      améliorer l'utilité de ces sites.
    </p>

    <h3>Informations permettant d'identifier les personnes</h3>

    <p>
      BIREME/OPS peut utiliser les informations personnelles fournies, entre autres, pour
      :
    </p>

    <ul>
      <li>
        entrer en contact avec vous afin de répondre à une demande ou une suggestion, ou
        pour vous envoyer des bulletins d'information, des documents, des publications,
        etc.;
      </li>
      <li>confirmer vos informations d'inscription sur le site;</li>
      <li>"se souvenir" de votre profil et de vos préférences en ligne;</li>
      <li>
        vous aider à trouver rapidement des informations utiles en fonction de vos centres
        d'intérêt et nous aider à créer un contenu plus bénéfique pour vous;
      </li>
      <li>réaliser une analyse statistique.</li>
    </ul>

    <h2>
      Que se passe-t-il si vous ne souhaitez pas fournir d'informations personnelles ?
    </h2>

    <p>
      Il est volontaire de fournir des informations personnelles sur les sites de
      BIREME/OPAS. Si vous choisissez de ne pas fournir ce type d'information, vous pouvez
      toujours consulter et utiliser les sites de BIREME/OPAS, mais vous ne pourrez pas
      utiliser certaines options telles que l'accès aux Services personnalisés Mi BVS ou
      la gestion des sources d'information de la BVS via le système web FI-Admin.
    </p>

    <h3>Option de modification d'informations ou demande de suppression</h3>

    <p>
      Vous pouvez modifier vos informations ou demander leur suppression à tout moment ;
      pour cela, vous devez accéder de nouveau au site où vous avez saisi les informations
      pour la première fois et contacter le point focal de ce site. Si aucune information
      de contact n'est fournie sur le site, vous pouvez contacter bir.online@paho.org.
    </p>

    <h1>SÉCURITÉ</h1>
    <p>
      Toutes les informations fournies à la BIREME/OPAS par les utilisateurs des sites de
      la BIREME/OPAS sont conservées avec beaucoup de soin et de sécurité, et ne seront
      pas utilisées différemment de ce qui est établi par cette politique de
      confidentialité, toute politique propre du site, ou de celles que vous avez
      explicitement autorisées.
    </p>

    <p>
      Tous nos employés qui ont accès aux données personnelles et qui sont liés à leur
      traitement sont tenus de respecter la confidentialité des affaires officielles, y
      compris des données personnelles.
    </p>

    <p>
      Les sites de la BIREME/OPAS contiennent des liens vers des sites externes. La
      BIREME/OPAS n'est pas responsable des pratiques de confidentialité ni du contenu de
      ces sites.
    </p>

    <h2>Notification de changements</h2>

    <p>
      BIREME/OPAS peut modifier cette Politique de confidentialité et publiera ces
      modifications sur le site actuel.
    </p>

    <h2>Contact</h2>
    <p>
      Pour toute question ou demande concernant cette politique de confidentialité,
      veuillez nous contacter à bir.online@paho.org.
    </p>

    <p>* Cookie</p>

    <p>
      Un cookie est un petit ensemble de données envoyé par un serveur Web à votre
      navigateur. Il est généralement utilisé pour attribuer une identité unique à votre
      ordinateur et stocker en toute sécurité des informations telles que l'ID
      utilisateur, les mots de passe, les préférences et les profils électroniques. Il est
      stocké sur le disque dur de votre ordinateur. Vous avez la possibilité de refuser
      l'installation de cookies de l'OPS/OMS si cela change la configuration de votre
      navigateur. Plusieurs sites peuvent envoyer leurs propres cookies sur votre
      ordinateur. Pour protéger votre vie privée, le navigateur permet uniquement à un
      site d'accéder à ses propres cookies et n'autorise pas l'accès aux cookies installés
      par d'autres sites.
    </p>

    <strong
      >SI VOUS N'ÊTES PAS D'ACCORD AVEC L'UN DES TERMES ET CONDITIONS DE CETTE POLITIQUE
      DE CONFIDENTIALITÉ, VOUS DEVEZ ABSTENIR DE UTILISER LE SITE ET TOUT PRODUIT, SERVICE
      ET/OU OUTIL QUI Y EST CONTENU.</strong
    >
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/variables.scss";
@import "../../../assets/styles/typography.scss";
// h2 {
//   margin: auto;
//   text-align: center;
//   vertical-align: middle;
//   padding: 5px;
// }
// p {
//   margin: auto;
//   text-align: center;
//   vertical-align: middle;
// }
// img {
//   background-color: $white;
// }
</style>
