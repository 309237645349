
export default class References {
  static getLocale (reference, lang) {
    for (let field of ['title', 'abstract', 'notes', 'population', 'intervention', 'translation_status']) {
      reference[field] = reference[field + '_' + lang] || reference[field + '_en'] || reference[field + '_es']
      // TODO: borrar llaves *_en, *_es
    }

    for (let guideline of reference.guidelines || []) {
      guideline = References.getLocale(guideline, lang)
    }

    return reference
  }
}
