<template>
  <div>
    <div class="container-fluid px-2 px-md-5 pb-2 static-content">
      <div class="row">
        <div class="col-12 mt-5">
          <div class="d-flex justify-content-end goback">
            <b-button variant="link" class="d-flex" @click="handleBackToRecommendation"
              ><b-icon-arrow-left></b-icon-arrow-left
              >{{ $t("map.back_button") }}</b-button
            >
          </div>
          <h1 class="p-0 m-0">
            {{ $t("map.recommendations_map") + ":" }}
            <b-dropdown
              id="dropdown-1"
              text="Dropdown Button"
              bottom
              variant="none"
              class="m-md-2 pr-2 border rounded topic-dropdown"
            >
              <template #button-content>
                <span class="h4 pr-4">{{
                  $t("general.sdg3_targets." + ui.currTopic.value) || ""
                }}</span>
              </template>
              <b-dropdown-item
                v-for="option in listOfTopics"
                :key="option.value"
                @click="changeTopic(option.value)"
                class=""
                >{{ option.text }}</b-dropdown-item
              >
            </b-dropdown>
          </h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-5">
          <div v-if="ui.loadingMap" class="row loading-references py-5">
            <div class="la-ball-atom la-2x mx-auto">
              <div v-for="n in 4" :key="n"></div>
            </div>
          </div>

          <b-table-simple
            v-else
            sticky-header="60vh"
            style="font-size: small"
            responsive
            bordered
            no-border-collapse
            hover
            v-dragscroll
          >
            <b-thead class="table-header">
              <b-tr>
                <b-th sticky-column class="corner-cell">
                  <svg
                    width="100%"
                    height="100%"
                    style="position: absolute; top: 0; left: 0"
                  >
                    <line
                      x1="0"
                      y1="0"
                      x2="100%"
                      y2="100%"
                      style="stroke: rgb(255, 255, 255); stroke-width: 4"
                    />
                  </svg>
                  <div>
                    <span class="bottom">{{ $tc("general.population", 1) }}</span>
                    <span class="top">{{ $tc("general.intervention", 1) }}</span>
                    <div class="line"></div>
                  </div>
                </b-th>
                <b-th
                  v-for="fieldx in currentTaxonomy.treatment"
                  :key="fieldx"
                  class="headers"
                >
                  <div class="align-top">
                    {{ $t("general.sdg3_interventions." + fieldx) }}
                  </div>
                  <div class="align-bottom">
                    <router-link
                      :to="{
                        name: 'RecommendationsMain',
                        params: { lang: $i18n.locale },
                        query: {
                          intervention: fieldx,
                          intervention_variable: $route.params.id,
                        },
                      }"
                    >
                      Total: {{ categories_map.categories[fieldx].total }}
                    </router-link>
                  </div>
                </b-th>
                <b-th class="headers">
                  <div class="align-top">
                    {{ $t("general.sdg3_interventions.other_interventions") }}
                    <div class="align-bottom otherpop__total">
                      Total: {{ categories_map.categories.other_treatment.total }}
                    </div>
                  </div>
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody class="table-cells">
              <b-tr v-for="fieldy in sortedCategoriesY" :key="fieldy">
                <b-th sticky-column>
                  <div class="align-top">{{ $t("general.sdg3_targets." + fieldy) }}</div>
                  <div class="align-bottom">
                    <router-link
                      :to="{
                        name: 'RecommendationsMain',
                        params: { lang: $i18n.locale },
                        query: { intervention_variable: fieldy },
                      }"
                    >
                      Total: {{ categories_map.categories[fieldy].total }}
                    </router-link>
                  </div>
                </b-th>
                <b-td
                  v-for="fieldx in currentTaxonomy.treatment"
                  :key="fieldx"
                  :class="
                    isButtonEnabled(fieldx, fieldy) ? 'font-weight-bold' : 'noHover'
                  "
                  :style="{
                    'background-color': getColor()[
                      categories_map.matrix[fieldx][fieldy]
                    ][0],
                  }"
                  @click="isButtonEnabled(fieldx, fieldy) ? recsUrl(fieldy, fieldx) : 0"
                >
                  <div
                    v-if="isButtonEnabled(fieldx, fieldy)"
                    :style="{
                      color: getColor()[categories_map.matrix[fieldx][fieldy]][1],
                    }"
                  >
                    {{ categories_map.matrix[fieldx][fieldy] }}
                  </div>
                </b-td>
                <b-td :class="'noHover'" class="font-weight-bold">{{
                  categories_map.categories[fieldy].total_other
                }}</b-td>
              </b-tr>
              <b-tr>
                <b-th sticky-column class="otherpop">
                  <div class="align-top">
                    {{ $t("general.sdg3_targets.other_populations") }}
                  </div>
                  <div class="align-bottom otherpop__total">
                    Total: {{ categories_map.categories.other_population.total }}
                  </div>
                </b-th>
                <b-td
                  v-for="fieldx in currentTaxonomy.treatment"
                  :key="fieldx"
                  :class="'noHover'"
                  class="font-weight-bold"
                >
                  {{ categories_map.categories[fieldx].total_other }}
                </b-td>
                <b-td :class="'noHover'" class="font-weight-bold">
                  <div>
                    {{ categories_map.categories.other_other.total }}
                  </div>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../utils/Api";
import platform_config from "../platform";
import { mapState } from "vuex";

export default {
  name: "RecommendationsMap",
  created: function () {
    this.sdg3_id = this.$route.params.id;
    this.categories = [...this.currentTaxonomy.treatment];
    this.getMap();
    this.target = platform_config.sdg3_goals.filter(
      (g) => g.category_id == this.sdg3_id
    )[0];
    this.ui.currTopic = this.listOfTopics.find((topic) => topic.value === this.sdg3_id);
    console.log(this.ui.currTopic);
  },
  data() {
    return {
      categories_map: null,
      target: null,
      dsl_query: {},
      items: [],
      fields: [],
      peaks: [],
      categoriesX: [],
      categoriesY: [],
      ui: {
        loadingMap: false,
      },
      form: {
        categories_x: "",
        categories_y: "",
        // categories_x: '5d062731dbe4d25bcebee20d\n5d062ae6c80dd41e58ba8457\n 5d062d5fc80dd41e58ba8459\n5e1d819969c00e2adfdfeadb',
        // categories_y: '5d25e3a8daeedf1d3bf33308\n5d25e614daeedf1d3af3331a\n5d4cb14b69c00e1a952a0ceb\n5d4d69be69c00e1ccc316766\n5d4d6ad369c00e1ccc316767\n5d4dc75369c00e2114db5ef3\n5d6580cf69c00e131b5a4c5e\n5d65854869c00e131b5a4c60\n5da2ec2a3c12ef5722a1d712',
        categories: [],
      },
    };
  },
  methods: {
    recsUrl: function (population, treatment) {
      // let population = data.field.key
      // let treatment = data.item.categories
      let url = `/${this.language}/recommendations?intervention_variable=${population}&intervention=${treatment}`;
      // let url = `/recommendations?population=${population}&intervention=${treatment}&intervention_variable=${this.sdg3_id}`
      return this.$router.push(url);
    },
    getMap: function () {
      this.ui.loadingMap = true;
      Api.post("/get_map/" + this.sdg3_id, {
        categories: this.categories.join(","),
      }).then((response) => {
        // Api.post('/get_map', {categories_x: this.categoriesX, categories_y: this.categoriesY}).then(response => {
        this.categories_map = response.data.categories_map;
        this.categoriesX = response.data.categories_x;
        this.categoriesY = response.data.categories_y;
        this.dsl_query = response.data.dsl_query;

        this.fields = ["corner"].concat(this.categoriesX);

        let _items = [];
        for (const cat_y of this.categoriesY) {
          let new_row = { categories: cat_y };
          for (const cat_x of this.categoriesX) {
            new_row[cat_x] = this.categories_map.matrix[cat_x][cat_y];
            this.peaks.push(new_row[cat_x]);
          }
          _items.push(new_row);
        }
        this.items = _items;
        this.ui.loadingMap = false;
      });
    },
    getColor: function () {
      let numbers = this.peaks.filter(onlyUnique).sort(function (a, b) {
        return a - b;
      });
      let C2 = [150, 161, 254]; // dark background
      let C1 = [221, 243, 255]; // light background

      // let tC2 = [0, 0, 0]  // dark text
      // let tC1 = [255, 255, 255] // light text
      let scale = {};

      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }
      function toHex(c) {
        let hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      }
      function linearInterpolator(color1, color2, invert = false, mix = 0) {
        let [R1, G1, B1] = color1;
        let [R2, G2, B2] = color2;

        let R = Math.floor((1 - mix) * R1 + R2 * mix);
        let G = Math.floor((1 - mix) * G1 + G2 * mix);
        let B = Math.floor((1 - mix) * B1 + B2 * mix);

        if (invert) {
          R = 255 - R;
          G = 255 - G;
          B = 255 - B;
        }

        return "#" + toHex(R) + toHex(G) + toHex(B);
      }
      for (var i = 0; i < numbers.length; i++) {
        let number = numbers[i];
        let max_num = Math.max(...numbers);
        // let per_mix = 1*(1 - i/numbers.length)
        let threshold = numbers.length - Math.ceil((numbers.length - 1) * 0.25) - 1;
        scale[number] = ["#E4E4E4", "black"];
        // scale[number] = ['white', 'black']
        if (number) {
          scale[number] = [linearInterpolator(C1, C2, false, number / max_num), "black"];
          if (i > threshold)
            scale[number] = [
              linearInterpolator(C1, C2, false, number / max_num),
              "black",
            ];
        }
      }
      return scale;
    },
    isButtonEnabled: function (intervention, population) {
      if (this.categories_map.matrix[intervention][population]) {
        return true;
      }
      return false;
    },
    handleBackToRecommendation() {
      const nextPath =
        this.currentRecommendationPath === "" ||
        !this.currentRecommendationPath.includes(this.$route.params.id)
          ? `/${this.language}/recommendations?intervention_variable=${this.$route.params.id}`
          : `/${this.language}/${this.currentRecommendationPath}`;
      this.$router.push(nextPath);
    },
    changeTopic(val) {
      window.location.href = `/${this.$i18n.locale}/map/${val}`;
    },
  },
  computed: {
    // categories: function () {
    //   return this.form.categories.split('\n').map(c => c.trim())
    // },
    // categoriesX: function () {
    //   return this.form.categories_x.split('\n').map(c => c.trim())
    // },
    // categoriesY: function () {
    //   return this.form.categories_y.split('\n').map(c => c.trim())
    // }
    // categoriesX: function () {
    //   return this.form.categories_x.split('\n').map(c => c.trim())
    // },
    // categoriesY: function () {
    //   return this.form.categories_y.split('\n').map(c => c.trim())
    // }
    currentTaxonomy: function () {
      return (
        platform_config.taxonomy[this.$route.params.id] ||
        platform_config.taxonomy.default
      );
    },
    ...mapState(["currentRecommendationPath", "language"]),
    sortedCategoriesY: function () {
      const sdg3TargetsTranslation = this.$i18n.messages[this.$i18n.locale].general
        .sdg3_targets;
      const sortTranslation = [];
      this.categoriesY.map((category) => {
        sortTranslation.push({
          id: category,
          text: sdg3TargetsTranslation[category],
        });
      });
      return sortTranslation
        .sort((a, b) => (a.text || "").localeCompare(b.text || ""))
        .map((population) => population.id)
        .filter(
          (id) =>
            platform_config.sdg3_goals
              .map((goal) => goal.category_id)
              .some((goalId) => goalId === id) &&
            this.$i18n.messages[this.$i18n.locale].general.sdg3_targets[id]
        );
    },
    listOfTopics() {
      let listOfTopics = [];
      platform_config.sdg3_goals
        .filter((s) => s.main)
        .map((topic) =>
          listOfTopics.push({
            value: topic.category_id,
            text: this.$t("general.sdg3_targets." + topic.category_id),
          })
        );
      return listOfTopics;
    },
  },
  watch: {},
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../assets/styles/variables.scss";
// .tooltip-inner {
//   max-width: 120px !important;
//   min-width: 120px !important;
// }
.corner-cell {
  background-color: $cell-background !important;
  border: 2px solid white !important;
  // padding: 0;
  // margin: 0;
  // height: 100px;
  // width: 200px;
  // position: relative;
  // height: 100%;
  // width: 100%;
  // top: 0;
  // left: 0;
  .bottom {
    position: absolute;
    bottom: 6px;
    left: 10px;
  }

  .top {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  //   .line {
  //     width: 222px;
  //     height: 40px;
  //     border-bottom: 4px solid #fff;
  //     transform: translateY(-72px) translateX(-15px) rotate(
  //     26deg);
  //     position: absolute;
  //     z-index: -1;
  //  }
}
.noHover {
  pointer-events: none;
}
.headers {
  background-color: #f5fbff !important;
  vertical-align: top !important;
  border: 2px solid white !important;
}
table {
  cursor: grabbing;
}

th,
td {
  margin: 3px;
}
.table-cells {
  th {
    border: 2px solid white !important;
    background: $left-header-background !important;
  }
  td {
    border: 2px solid white !important;
    vertical-align: middle !important;
    text-align: center;
    background: $cell-background;
  }
  td:hover {
    filter: brightness(65%);
    // background-color: inherit !important;
    transition: 0.5s;
    cursor: pointer;
    border: 0 !important;
    // opacity: 0;
  }
}
.table-header {
  th {
    min-width: 125px;
    max-width: 125px;
  }
}
.table-cells a,
.table-header a,
.otherpop__total {
  color: $dark-grey;
  font-size: 90%;
}
.table-bordered th,
.table-bordered td {
  border: 5px solid $white;
}
</style>
<style lang="scss" scoped>
@import "../assets/styles/variables.scss";
@import "../assets/styles/loading.scss";
@import "../assets/styles/typography.scss";

.metadata-options {
  padding-top: 10px;
  border-left: 1px solid $line;
}
.loading-container {
  text-align: center;
  .la-ball-circus {
    display: inline-block;
    height: 7px;
    width: 14px;
    margin-left: 15px;
    margin-top: 7px;
  }
}
a.planilla-magica-link {
  color: $green-font;
  .sheets-icon {
    height: 30px;
  }
}
.sticky {
  position: sticky;
  top: 10px;
  z-index: 100;
}
.la-ball-atom {
  color: $green-font;
  margin: 100px;
}
.uppercase {
  text-transform: uppercase;
}

.main {
  width: 80%;
  margin: 0 auto;
  padding-top: 1%;
  z-index: -1;
}

.title {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
  height: 75px;
  padding-top: 1%;
  padding-bottom: 6%;
  margin-bottom: 2%;
}
::-webkit-scrollbar {
  display: none;
}
.topic-dropdown {
  button {
    position: relative;
    span {
      color: $ops-portal;
    }
  }
  &:hover {
    span {
      color: $dark-blue;
    }
  }
}
.goback {
  color: $ops-portal;
}
</style>
