
export default class Taxonomy {
  static hierarchyView (categories) {
    // debugger
      let _hView = []
      if (!categories) {
        return _hView
      }
      var ids = categories.map(category => category.id)

      categories.forEach((_category) => {
        // if (!this.filteredCategories[_category.id]) {
        //   return
        // }
        let allPaths = (_category.paths || []).length ? _category.paths : [_category.id]

        for (const path of allPaths) {
          let tmpCategory = JSON.parse(JSON.stringify(_category))
          // delete tmpCategory.paths
          tmpCategory.path = path
          tmpCategory.paths = allPaths
          // tmpCategory.allPaths = allPaths

          let splittedPath = tmpCategory.path.split('.')
          // If the first part of this path is not present on the type, then it has 0 indent
          if (tmpCategory.path.split('.').length > 1) {
            tmpCategory.parent = tmpCategory.path.split('.')[tmpCategory.indent - 1]
          } else {
            tmpCategory.isRoot = true
          }
          if (ids.includes(splittedPath[0])) {
            tmpCategory.indent = splittedPath.length - 1
            _hView.push(tmpCategory)
          } else {
            tmpCategory.indent = 0
            _hView.push(tmpCategory)
            // if (!this.hasParentInTaxonomyType(allPaths, this.ui.currentTab)) {
            //   tmpCategory.indent = 0
            //   _hView.push(tmpCategory)
            // }
          }
        }
      })
      return _hView.sort((c1, c2) => {
        return c1.path > c2.path ? 1 : -1
      })
    }

}
