<template>
  <div>
    <p>
      The PAHO/WHO GRADE recommendations portal for the SDG-3 aims to collect all
      recommendations for clinical, public health, and health policy issued by WHO and
      PAHO guidelines that follow the
      <a href="https://www.gradeworkinggroup.org/" target="_target"
        >GRADE <font-awesome-icon icon="external-link-alt" class="ml-1"
      /></a>
      approach. Is a freely accessible repository at the Virtual Health Library (<a
        href="https://bvsalud.org/en/"
        target="_blank"
        >VHL<font-awesome-icon icon="external-link-alt" class="ml-1" /></a
      >) that allows decision-makers to identify relevant recommendations for their
      question of interest. This database provides users with an efficient means to search
      and find guidance based on their questions of interest, with built-in search,
      filter, and cross-tabulate functions, including the organization by the third
      sustainable development goal (SDG-3). We use the broadly accepted
      population, interventions, comparators, and outcomes (PICO) to map and catalogize
      recommendations. One of its primary targets is to allow contextualized local,
      provincial, regional and jurisdictional decision-making for health care workers and
      decision-makers by adopting or adapting these recommendations.
    </p>
    <p>
      This platform will feed on continued input from guideline developers which we
      encourage and users and, thus, it’s functions will continue to improve and it will
      be updated regularly to maintain relevance.
    </p>
    <p>
      It is a product of the collaboration between the
      <a href="https://www.paho.org/en" target="_blank"
        >Pan-American Health Organization<font-awesome-icon
          icon="external-link-alt"
          class="ml-1"
      /></a>
      and
      <a href="https://foundation.epistemonikos.org/" target="_blank"
        >Epistemonikos Foundation<font-awesome-icon icon="external-link-alt" class="ml-1"
      /></a>
      available at the Virtual Health Library (<a
        href="https://bvsalud.org/en/"
        target="_blank"
        >VHL<font-awesome-icon icon="external-link-alt" class="ml-1" /></a
      >).
    </p>

    <h2>Related links:</h2>
    <ul>
      <li>
        Pan-American Health Organization
        <br />
        <a href="https://www.paho.org/en" target="_blank"
          >https://www.paho.org/en<font-awesome-icon
            icon="external-link-alt"
            class="ml-1"
        /></a>
      </li>
      <li>
        Virtual Health Library
        <br />
        <a href="https://bvsalud.org/en" target="_blank"
          >https://bvsalud.org/en<font-awesome-icon icon="external-link-alt" class="ml-1"
        /></a>
      </li>
      <li>
        BIGG – International Database of GRADE Guidelines
        <br />
        <a href="https://sites.bvsalud.org/bigg/en/biblio/" target="_blank"
          >https://sites.bvsalud.org/bigg/en/biblio/<font-awesome-icon
            icon="external-link-alt"
            class="ml-1"
        /></a>
      </li>
      <li>
        GRADE Working Group
        <br />
        <a href="https://www.gradeworkinggroup.org" target="_blank"
          >https://www.gradeworkinggroup.org<font-awesome-icon
            icon="external-link-alt"
            class="ml-1"
        /></a>
      </li>
      <li>
        Epistemonikos Foundation
        <br />
        <a href="https://foundation.epistemonikos.org" target="_blank"
          >https://foundation.epistemonikos.org<font-awesome-icon
            icon="external-link-alt"
            class="ml-1"
        /></a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
