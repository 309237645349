<template>
  <div v-if="this.gradeCoE > 0" class="coe">

    <span class="coe-grade">
      {{'⊕'.repeat(this.gradeCoE)}}{{'○'.repeat(4 - this.gradeCoE)}}
    </span>
    <span class="text-uppercase coe-label">{{$t(`general.coe_labels.${this.normalizedCoE}`)}}</span>
  </div>
</template>

<script>
export default {
  name: 'CoE',
  data: function () {
    return {}
  },
  props: [
    'coe'
  ],
  methods: {},
  computed: {
    normalizedCoE: function () {
      return (this.coe || "").trim().toLowerCase()
    },
    gradeCoE: function () {
      if (!this.coe) {
        return 0
      }
      if (this.normalizedCoE == 'high') {
        return 4
      }
      else if (this.normalizedCoE == 'moderate') {
        return 3
      }
      else if (this.normalizedCoE == 'low') {
        return 2
      }
      else if (this.normalizedCoE == 'very low') {
        return 1
      }
      return 0
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../../assets/styles/variables.scss";
.coe {

}
.coe-grade {
  font-family: 'Epistemonikos';
  color: $warning;
  font-weight: bold;
}
.coe-label {
  top: -3px;
  position: relative;
  left: 6px;
}
</style>
