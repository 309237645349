import { render, staticRenderFns } from "./esPrivacyPolicy.vue?vue&type=template&id=20ac5036&scoped=true&"
import script from "./esPrivacyPolicy.vue?vue&type=script&lang=js&"
export * from "./esPrivacyPolicy.vue?vue&type=script&lang=js&"
import style0 from "./esPrivacyPolicy.vue?vue&type=style&index=0&id=20ac5036&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20ac5036",
  null
  
)

export default component.exports