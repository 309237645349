<template>
  <li :class="{'collapsable': item.children.length}">
    <div :class="{'highlighted': item.highlighted}" class="row">
      <div class="toggle-branch" v-if="item.children.length">
        <font-awesome-icon icon="caret-down" v-if="!item.collapsed" @click="toggleElement(item.id)"/>
        <font-awesome-icon icon="caret-right" v-if="item.collapsed" @click="toggleElement(item.id)"/>
      </div>

      <div class="col">
        <span class="pointer category" :class="{'selected': isSelected}" @click="selectCategory(item.id, criteria, true)">
          {{getCategoryName(item.id)}}
        </span>
        <a v-if="isSelected" class="pointer clear" @click="removeFilter(item)">{{$t("general.clear")}}</a>
      </div>
    </div>
    <!-- LOVE CATEGORIES -->
    <ul v-if="(item.children.length && !item.collapsed) || !item.visible_paths.includes(path)" class="ul-panel-left" :class="{'padded': true}">
      <CategoryListItem v-for="child in item.children"
                        :forceShow="forceShow"
                        :item="child"
                        :path="path + '.' + child.id"
                        :key="child.id"
                        :selectCategory="selectCategory"
                        :toggleElement="toggleElement"
                        :removeFilter="removeFilter"
                        :criteria="criteria"></CategoryListItem>
    </ul>
  </li>
</template>

<script>

export default {
  name: 'CategoryListItem',
  props: [
    'criteria',
    'forceShow',
    'item',
    'listName',
    'toggleElement',
    'path',
    'removeFilter',
    'selectCategory',
  ],
  data () {
    return {
    }
  },
  watch: {
  },
  methods: {
    getCategoryName: function (category_id) {
      let categories = {
        ...this.$t('general.sdg3_targets'), 
        ...this.$t('general.sdg3_populations'), 
        ...this.$t('general.sdg3_interventions')
      }
      return categories[category_id]
    } 
  },
  computed: {
    isSelected: function () {
      // return false
      return this.criteria.selectedMetadata.includes(this.item)
    }
  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/styles/variables.scss";
  .toggle-branch {
    max-width: 0;
    position: relative;
    left: -7px;
    z-index: 1000;
  }
  .svg-inline--fa {
    height: 20px;
    width: 20px;
    vertical-align: -0.4em;
    cursor: pointer;
    &:hover {
      color: $ops-portal-hover;
    }
  }
  .collapsable {
    left: -10px;
  }
  li {
    list-style: none;
  }
  ul {
    margin-bottom: 0px;
    margin-top: 0px;
    padding-left: 0px;
    &.padded {
      padding-left: 20px;
    }
  }
  .category {
    color: $txt;
    &.selected, &:hover {
      color: $green-font;
      text-decoration: underline;
    }
  }
  .highlighted {
    background-color: yellow;
  }
  a.pointer.clear {
    font-size: $smallsize;
    color: $strong;
    background-color: $grey;
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 5px;
    &:hover {
      color: $strong;
    }
  }
  .primary-badge {
    font-size: $smallsize;
    color: $green-font;
  }
  .beta-badge {
    font-size: $smallsize;
    color: $beta-font;
  }
</style>
