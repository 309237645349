<template>
  <div>
    <div class="main" v-if="guideline" :class="{'mobile': $mq == 'mobile'}">
      <template v-if="$mq != 'mobile'">
        <div class="title mb-5 d-flex justify-content-between">
          <div class="">
            <h1 class="">
              {{guidelineLocale.title}}
            </h1>
          </div>
          <div class="text-right d-flex flex-column justify-content-between align-items-center ml-3">
              <div class="d-inline-block" v-if="guidelineLocale.translation_status != 'blocked'">
                <img src="../assets/images/translate_black.svg"
                     alt="pending-translation"
                     v-b-tooltip.hover.left="$t('general.pending_translation')"
                 >
              </div>

              <div class="ref-menu d-flex align-items-end h-100">
                <b-dropdown dropleft variant="link" toggle-class="text-decoration-none" no-caret>
                  <template #button-content>
                    <font-awesome-icon class="" icon="ellipsis-h"/>
                  </template>
                    <b-dropdown-item @click="copyId(guideline.id)" id="guideline_id" class="">Copy reference ID <b-icon-files style="width: 12px"></b-icon-files></b-dropdown-item>
                    <b-dropdown-item target="_blank" :href="'https://epistemonikos.org/documents/' + guideline.id">{{$t('general.see_episte')}}</b-dropdown-item>
                    <b-dropdown-item target="_blank" :href="'https://app.iloveevidence.com/references/' + guideline.id + '/info'">{{$t('general.see_love')}}</b-dropdown-item>
                  </b-dropdown>
              </div>
          </div>
        </div>

        <div class="row rec-row">
          <div class="col-3 rec-label">
            <div>{{$t('general.year')}}</div>
          </div>
          <div class="col-9 rec-value">
            <div>{{guideline.year}}</div>
          </div>
        </div>

        <div class="row rec-row" v-if="guideline.author.length > 0">
          <div class="col-3 rec-label">
            <div>{{$t('guideline.institution')}}</div>
          </div>
          <div class="col-9 rec-value">
            <div>{{translatedInstitution}}</div>
          </div>
        </div>

        <div class="row rec-row" v-if="guideline.organization_type">
          <div class="col-3 rec-label">
            <div>{{$t('guideline.organization_type')}}</div>
          </div>
          <div class="col-9 rec-value">
            <div>{{$t('guideline.organization_types.' + guideline.organization_type)}}</div>
          </div>
        </div>

        <div class="row rec-row" v-if="guideline.focus">
          <div class="col-3 rec-label">
            <div>{{$t('guideline.focus')}}</div>
          </div>
          <div class="col-9 rec-value">
            <div>{{guideline.focus.map(f => $t('guideline.focus_labels.' + f)).join(', ')}}</div>
          </div>
        </div>

        <div class="row rec-row" v-if="guideline.users">
          <div class="col-3 rec-label">
            <div>{{$t('guideline.users')}}</div>
          </div>
          <div class="col-9 rec-value">
            <div>{{guideline.users.map(f => $t('guideline.users_labels.' + f)).join(', ')}}</div>
          </div>
        </div>

        <div class="row rec-row">
          <div class="col-3 rec-label">
            <div>{{$t("guideline.links")}}</div>
          </div>
          <div class="col-9 rec-value">
            <div>
              <a v-for="link in getLinks"
                 :key="link.name"
                 :href="link.link"
                 target="_blank">
                 {{link.name || link.link}}
                 <font-awesome-icon icon="external-link-alt" class="mr-1"/>
              </a>
            </div>
          </div>
        </div>

        <div class="row mt-5" v-if="guidelineLocale.abstract">
          <div class="col-12">
            <div class="grey-box rec_fulltext">{{guidelineLocale.abstract}}</div>
          </div>
        </div>
      </template>

      <template v-if="$mq == 'mobile'">
        <div class="row pre-title">
        </div>
        <div class="row title mb-5" >
          <div class="col">
            <h1 class="" style="font-size: 1.5rem"> 
              {{guidelineLocale.title}}
            </h1>
            <img src="../assets/images/translate_black.svg"
                 alt="pending-translation"
                 v-if="guidelineLocale.translation_status != 'blocked'"
                 v-b-tooltip.hover.left="$t('general.pending_translation')"
                 style="position: absolute; top: 8px; right: 0;"
             >
          </div>
        </div>

        <div class="row rec-row">
          <div class="col-12 rec-label">
            <div>{{$t('general.year')}}</div>
          </div>
          <div class="col-12 rec-value">
            <div>{{guideline.year}}</div>
          </div>
        </div>

        <div class="row rec-row" v-if="guideline.author.length > 0">
          <div class="col-12 rec-label">
            <div>{{$t('guideline.institution')}}</div>
          </div>
          <div class="col-12 rec-value">
            <div>{{translatedInstitution}}</div>
          </div>
        </div>

        <div class="row rec-row" v-if="guideline.organization_type">
          <div class="col-12 rec-label">
            <div>{{$t('guideline.organization_type')}}</div>
          </div>
          <div class="col-12 rec-value">
            <div>{{$t('guideline.organization_types.' + guideline.organization_type)}}</div>
          </div>
        </div>

        <div class="row rec-row" v-if="guideline.focus">
          <div class="col-12 rec-label">
            <div>{{$t('guideline.focus')}}</div>
          </div>
          <div class="col-12 rec-value">
            <div>{{guideline.focus.map(f => $t('guideline.focus_labels.' + f)).join(', ')}}</div>
          </div>
        </div>

        <div class="row rec-row" v-if="guideline.users">
          <div class="col-12 rec-label">
            <div>{{$t('guideline.users')}}</div>
          </div>
          <div class="col-12 rec-value">
            <div>{{guideline.users.map(f => $t('guideline.users_labels.' + f)).join(', ')}}</div>
          </div>
        </div>

        <div class="row rec-row">
          <div class="col-12 rec-label">
            <div>{{$t("guideline.links")}}</div>
          </div>
          <div class="col-12 rec-value">
            <div>
              <div v-for="link in getLinks" :key="link.name" class="mb-1">
                <a :href="link.link"
                   target="_blank">
                   {{link.name || link.link}}
                   <font-awesome-icon icon="external-link-alt" class="mr-1"/>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-5" v-if="guidelineLocale.abstract">
          <div class="col-12">
            <div class="grey-box rec_fulltext">{{guidelineLocale.abstract}}</div>
          </div>
        </div>
      </template>


      <div class="row my-5">
        <div class="col-12">
          <h4>{{$t('guideline.recs_included')}} ({{(guideline.recommendations || []).length}})</h4>
        </div>
        <div class="col-12">
          <recommendation-box v-for="rec in guideline.recommendations" :key="rec.id" :recommendation="rec" :showInstitution="false"></recommendation-box>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="la-ball-atom la-2x mx-auto"><div v-for="n in 4" :key="n"></div></div>
    </div>
  </div>
</template>

<script>

import Api from '../utils/Api'
import RecommendationBox from './RecommendationBox'
import References from '../utils/References'

export default {
  name: 'Guidelines',
  components: {
    'recommendation-box': RecommendationBox,
  },
  created: function () {
      Api.get('/guidelines/' + this.$route.params.id).then(response => {
      // Api.post('/get_map', {categories_x: this.categoriesX, categories_y: this.categoriesY}).then(response => {
        this.guideline = response.data.guideline
      })
    },
  data () {
    return {
      guideline: null,
    }
  },
  methods: {
    copyId: function (id) {
      let $tempInput = document.createElement('INPUT')
      let $body = document.getElementsByTagName('body')[0]
      $body.appendChild($tempInput)
      $tempInput.setAttribute('value', id)
      $tempInput.select()
      document.execCommand('copy')
      $body.removeChild($tempInput)
      this.copied = true
     },
  },
  computed: {
    translatedInstitution: function () {
      let institution_key = 'general.institutions.' + this.getInstitution
      let institution = this.$t(institution_key)
      if (institution_key == institution) {
        return this.getInstitution
      }
      return institution
    },
    guidelineLocale: function () {
      return References.getLocale(this.guideline, this.$i18n.locale)
    },
    getLinks: function () {
      if (!this.guideline.ext_links) {
        return this.guideline.ext_links.concat(this.guideline.links)
      }
      let uniqBy = function(a, key) {
          var seen = {};
          return a.filter(function(item) {
              var k = key(item);
              return Object.prototype.hasOwnProperty.call(seen, k) ? false : (seen[k] = true);
          })
      }
      return uniqBy(this.guideline.ext_links, el => el.link)
    },
    getInstitution: function () {
      let possible_patterns = {
        WHO: [
          /WHO/i,
          /OMS/i,
          /^(?=.*\bworld\b)(?=.*\bhealth\b)(?=.*\borganization\b)/i,
          /^(?=.*\borganizaci[ó|o]n\b)(?=.*\bmundial\b)(?=.*\bsalud\b)/i
        ],
        PAHO: [
          /PAHO/i,
          /OPS/i,
          /^(?=.*\bpan[\s]american\b)(?=.*\bhealth\b)(?=.*\borganization\b)/i,
          /^(?=.*\borganizaci[ó|o]n\b)(?=.*\bpan[\s]americana\b)(?=.*\bsalud\b)/i
        ]
      };
      for (var institution of Object.keys(possible_patterns)) {
        for (var possible_pattern of possible_patterns[institution]) {
          var result = this.guideline.author[0].match(possible_pattern);
          if (result) {
            return institution;
          }
        }
      }
      return this.guideline.author[0] || "unset"
    }

  },
  watch: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables.scss";
@import "../assets/styles/loading.scss";
.metadata-options {
  padding-top: 10px;
  border-left: 1px solid $line
}
.loading-container {
  text-align: center;
  .la-ball-circus {
    display: inline-block;
    height: 7px;
    width: 14px;
    margin-left: 15px;
    margin-top: 7px;
  }
}
a.planilla-magica-link {
  color: $green-font;
  .sheets-icon {
    height: 30px;
  }
}
.sticky {
  position: sticky;
  top: 10px;
  z-index: 100;
}
.la-ball-atom  {
  color: $green-font;
  margin: 100px;
}
.uppercase {
  text-transform: uppercase;
}

.main {
  &.mobile {
    .first-row {
      margin-top: 2em;
    }
    .rec-metadata {
      margin-bottom: 1em;
    }
    .top-metadata {
      margin-bottom: 1rem;
    }
    .rec-row {
      margin-bottom: 1em;
    }
    .see-more-container {
      display: block;
      margin: 0.7em 0;
    }
    .rec-value {
      margin-top: 0.3em;
      word-break: break-word;
    }
  }
  color: $text-dark-blue;
  .top-metadata {
    margin-bottom: 3rem;
    .rec-metadata {
      float: left;
      margin-right: 20px;
      color: $text-dark-blue;
      font-weight: bold;
      .meta-value {
        margin-left: 5px;
        padding: 12px;
        border-radius: 8\px;
        background-color: $badge-light-blue;
      }
    }
  }
  .rec-row {
    margin-bottom: 5px;
    color: $dark-grey-font;
    .rec-label, .rec-value {
      > div{
        border-radius: 5px;
        padding: 5px 20px;
      }
    }
    .rec-label > div{
      // margin-right: 3px;
      font-weight: bolder;
      background-color: $badge-blue;
    }
    .rec-value > div{
      background-color: $badge-light-blue;
      > a:not(:last-of-type):after {
        content: ", "
      }
    }
  }

  .title {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: white;
    min-height: 75px;
    padding: 15px 0;
    border-bottom: 1px solid #808080;
  }
  .pre-title {
    margin-top: 2em;
  }

  .notes {
    cursor: pointer;
  }
}
</style>
