const default_taxonomy = {
  population: [
    // '5cec39fcdbe4d24b26f43daf',
    // '5cf20e7bc80dd41e57ba82b8',
    // '5cfa863adbe4d25bcfbee2c7',
    // '5d0b5a7bdbe4d25bcebee2a3',
    '5d14114adaeedf730426b7b8',
    '5d234900daeedf1d3af33306',
    '5d27332adaeedf1d3bf3330a',
    // '5d27427edaeedf1d3af33328',
    // '5d3f3da269c00e04ee74f5ea',
    '5d4322c869c00e198a009df3',
    '5d44e01769c00e198b009e39',
    '5d5832c369c00e490b8f5553',
    // '5d5e8d9d69c00e507e1c8882',
    '5d7e231569c00e72a5188e3c',
    '5d7f88af69c00e72a3188e15',
    '5d94f1d13c12ef641517c8d6',
    '5d94f2a13c12ef641517c8d7',
    // '5d7d93b769c00e72a5188e34',
    '61ae329e3d0515bfa88a791a'
  ],
  treatment: [
    '5cec5dfdc80dd40a6f61aea0',
    '5d1b60b4daeedf74ca4f4b51',
    '5d247420daeedf1d3af3330e',
    '5d25202adaeedf1d3af33311',
    '5d2523a9daeedf1d3af33312',
    '5d252f01daeedf1d3af33314',
    '5d25e34fdaeedf1d3af33315',
    // '5d25e35edaeedf1d3bf33307',
    // '5d25e3a8daeedf1d3bf33308',
    '5d25e37cdaeedf1d3af33316',
    // '5d25e614daeedf1d3af3331a'
  ]
}
const platform_config = {
  taxonomy: {
    'default': default_taxonomy,
    '6167009e06768adee26a276f': default_taxonomy,
    '616700b43552581d99259cbd': default_taxonomy,
    '616700c83d0515512662039e': default_taxonomy,
    '616700dfea88905b6d651ab4': default_taxonomy,
    '6167019d06768adee26a27b6': default_taxonomy,
    '616701b83d051551266203e3': default_taxonomy,
    '616701f0ea88905b6d651b03': default_taxonomy,
    '616701fd06768adee26a27d3': default_taxonomy,
    '6167020b3d05155126620408': default_taxonomy,
    '6167021bea88905b6d651b12': default_taxonomy,
    '6167022606768adee26a27e8': default_taxonomy,
    '616702393d05155126620415': default_taxonomy,
    '61670245ea88905b6d651b27': default_taxonomy,
  },
  sdg3_goals: [
    {
      name: 'Maternal Health',
      category_id: '6167009e06768adee26a276f',
      main: true,
      color: '#DB012F'
    },
    {
      name: 'Infant Health',
      category_id: '616700b43552581d99259cbd',
      main: true,
      color: '#D4972D'
    },
    {
      name: 'Communicable Diseases',
      category_id: '616700c83d0515512662039e',
      main: true,
      color: '#4B9B44'
    },
    {
      name: 'Non-Communicable Diseases',
      category_id: '616700dfea88905b6d651ab4',
      main: true,
      color: '#EC5323'
    },
    {
      name: 'Consumption of Psychoactive Substances',
      category_id: '6167019d06768adee26a27b6',
      main: true,
      color: '#1D83CA'
    },
    {
      name: 'Sexual and Reproductive Healthcare',
      category_id: '616701b83d051551266203e3',
      main: true,
      color:'#F9BA12'
    },
    {
      name: 'Universal Health',
      category_id: '616701f0ea88905b6d651b03',
      main: true,
      color: '#DD4F9B'
    },
    {
      name: 'Air, Water and Soil Pollution',
      category_id: '616701fd06768adee26a27d3',
      main: true,
      color:'#26B1E0'
    },
    {
      name: 'Health Workforce',
      category_id: '6167020b3d05155126620408',
      main: true,
      color:'#F06E46'
    },
    {
      name: 'National and Global Health Risks',
      category_id: '6167021bea88905b6d651b12',
      main: true,
      color: '#900A34'
    },
    {
      name: 'Road Traffic Accidents',
      category_id: '6167022606768adee26a27e8',
      main: true,
      color: '#13558D'
    },
    {
      name: 'Tobacco Control',
      category_id: '616702393d05155126620415',
      main: true,
      color: '#B90B24'
    },
    {
      name: 'Vaccine and Medicine Development',
      category_id: '61670245ea88905b6d651b27',
      main: true,
      color: '#1D6017'
    },
    {"type":"treatment","name":"Breastfeeding","category_id":"6232d8b806768a45cafb964b"},
    {"type":"treatment","name":"Smoking cessation","category_id":"5d25e658daeedf1d3af3331b"},
    {"type":"treatment","name":"Tobacco control policies","category_id":"623302a1355258bf19d48919"},
    {"type":"treatment","name":"WASH interventions","category_id":"623d860d355258962733d076"},
    {"type":"treatment","name":"Delivery arrangements","category_id":"5d31a969daeedf1d3af3333f"},
    {"type":"treatment","name":"Safe abortion services","category_id":"61e093773d0515f69ae5f25f"},
    {"type":"treatment","name":"Financial arrangements","category_id":"5dd7a70469c00e7ae9856041"},
    {"type":"treatment","name":"Governance arrangements","category_id":"5dd7eb313c12ef61034d00f3"},
    {"type":"treatment","name":"Anesthetic techniques","category_id":"5d4c5d0769c00e0d5edaea4b"},
    {"type":"treatment","name":"Antenatal care","category_id":"61969b2aea88903ea3a9cb6b"},
    {"type":"treatment","name":"Family planning","category_id":"619d5dc2307372254d2fb70c"},
    {"type":"population","name":"Cannabis","category_id":"622630fb355258e04d57f2b5"},
    {"type":"population","name":"Alcohol","category_id":"62263163ea8890a65d455607"},
    {"type":"population","name":"Opioids","category_id":"622633c5307372a476759d26"},
    {"type":"population","name":"Sedative hypnotics","category_id":"62263906355258e04d57f50e"},
    {"type":"population","name":"Cocaine","category_id":"62263c23ea8890b83445534b"},
    {"type":"population","name":"Amphetamines","category_id":"62263cdb06768a27badd846a"},
    {"type":"population","name":"Hallucinogens","category_id":"6226424eea8890cac54552db"},
    {"type":"population","name":"Inhalants","category_id":"62264853307372b78b75a001"},
    {"type":"population","name":"Occupational or environmental exposure","category_id":"6232f74d3552589cf3d489df"},
    {"type":"population","name":"Children's exposure to substances","category_id":"62af88f506768ad40b76ca75"},
    {"type":"population","name":"Snakebite","category_id":"61e02fc03552586c64987855"},
    {"type":"population","name":"Road traffic injuries","category_id":"61e0927e06768a05b24df5ea"},
    {"type":"population","name":"Pedestrians","category_id":"6230e95469c00e8b53a62277"},
    {"type":"population","name":"Motor vehicle drivers","category_id":"6230ea4d69c00e8b53a622be"},
    {"type":"population","name":"Cyclists","category_id":"6230f589355258f491a91beb"},
    {"type":"population","name":"Road crash victims","category_id":"6230f744ea889066d8cf39a5"},
    {"type":"population","name":"Headaches","category_id":"5e18816a19a11a14c75ed9a1"},
    {"type":"population","name":"Migraine","category_id":"5e18816a19a11a14c75ed9a2"},
    {"type":"population","name":"Miscarriage, stillbirth and abortion","category_id":"5d7e803069c00e72a5188e4f"},
    {"type":"population","name":"Abnormal progress of labor","category_id":"61ae22493d05159df98a787c"},
    {"type":"population","name":"Uterine rupture","category_id":"61ae228e06768afaf4086a69"},
    {"type":"population","name":"Obstetrical bleeding","category_id":"61ae329e3d0515bfa88a791a"},
    {"type":"population","name":"Prelabor rupture of membranes","category_id":"61ae3b98ea88906f40808bde"},
    {"type":"population","name":"Long labor","category_id":"61df380b307372c1b76fddfa"},
    {"type":"population","name":"Instrumental delivery","category_id":"61df3c89307372c1b76fdf53"},
    {"type":"population","name":"Caesarean section","category_id":"61df3dc73d0515f181e5f312"},
    {"type":"population","name":"Induction of labor","category_id":"61df40563552585a4198768a"},
    {"type":"population","name":"Perineal trauma","category_id":"61df4193307372c1b76fe0b4"},
    {"type":"population","name":"Obstetric trauma","category_id":"61df4312307372d5566fdd1a"},
    {"type":"population","name":"Hypertensive disorders of pregnancy","category_id":"61df4fab3552589b2c98759a"},
    {"type":"population","name":"Maternal infections","category_id":"61ae37433552583a066132f2"},
    {"type":"population","name":"Large for gestational age","category_id":"61ae9e133d0515be1f8a7b58"},
    {"type":"population","name":"Obstetric thromboembolism","category_id":"61df49fb3d05152368e5f105"},
    {"type":"population","name":"Ectopic pregnancy","category_id":"61ae05fd355258b08e6134d0"},
    {"type":"population","name":"Pregnant woman","category_id":"5d062d5fc80dd41e58ba8459"},
    {"type":"population","name":"Pregnant adolescents","category_id":"60eefcdd3d0515c51d3f02ae"},
    {"type":"population","name":"Diabetes and pregnancy","category_id":"6269b62a3552584246eb4bb5"},
    {"type":"population","name":"Viral hepatitis","category_id":"5cf20ea0c80dd41e58ba8259"},
    {"type":"population","name":"Cholera","category_id":"5d0a8a04c80dd41e57ba89a0"},
    {"type":"population","name":"Typhoid and paratyphoid","category_id":"5d0a8a85dbe4d25bcfbee78f"},
    {"type":"population","name":"Infectious diarrhea","category_id":"61e005a2307372bffc6fdf9b"},
    {"type":"population","name":"Tuberculosis","category_id":"5d0a9c60dbe4d25bcfbee797"},
    {"type":"population","name":"Sepsis","category_id":"5d0b446adbe4d25bcfbee7fd"},
    {"type":"population","name":"Meningitis","category_id":"5d4a7cd869c00e69c91e5ff8"},
    {"type":"population","name":"Dengue","category_id":"5d0b4ec7dbe4d25bcfbee800"},
    {"type":"population","name":"HIV","category_id":"5d52927869c00e3de733d820"},
    {"type":"population","name":"Pneumonia","category_id":"5d52d6bf69c00e3de733d835"},
    {"type":"population","name":"Malaria","category_id":"5d6bcb6369c00e39658ba0a2"},
    {"type":"population","name":"Chagas disease","category_id":"5d7f530269c00e72a5188e5e"},
    {"type":"population","name":"Brucellosis","category_id":"5da2f85069c00e78ea7056d5"},
    {"type":"population","name":"Zika virus disease","category_id":"5da2fbf63c12ef5722a1d717"},
    {"type":"population","name":"Chikungunya","category_id":"61e01ad006768a739d4df424"},
    {"type":"population","name":"Taenia solium infection","category_id":"63c30f7dc96e6d00081e1b35"},
    {"type":"population","name":"COVID-19","category_id":"5e7fce7e3d05156b5f5e032a"},
    {"type":"population","name":"Infectious PH emergencies","category_id":"618524ca307372a53541f22e"},
    {"type":"population","name":"Monkeypox","category_id":"628667843552584bba67f67a"},
    {"type":"population","name":"Crimean–Congo hemorrhagic fever","category_id":"61967a183d051589e00a0104"},
    {"type":"population","name":"Plague","category_id":"619697e869c00e50e228c6c1"},
    {"type":"population","name":"Anthrax","category_id":"61df52ea69c00e31667ed778"},
    {"type":"population","name":"Ascariasis","category_id":"61df53973552589d7298765a"},
    {"type":"population","name":"Epidemic typhus","category_id":"61e00914ea8890a3bcfe632e"},
    {"type":"population","name":"Scrub typhus","category_id":"61e075323552580bfd9877ea"},
    {"type":"population","name":"Gnathostomiasis","category_id":"61e00ae869c00ecf047ed7a1"},
    {"type":"population","name":"Pediculosis capitis","category_id":"61e00c83307372d43a6fdda8"},
    {"type":"population","name":"Histoplasmosis","category_id":"61e0147f3552582e469878a6"},
    {"type":"population","name":"Japanese encephalitis","category_id":"61e01531307372d43a6fdfe9"},
    {"type":"population","name":"Leishmaniasis","category_id":"61e016663552584ac49875ba"},
    {"type":"population","name":"Measles","category_id":"61e0174dea8890b596fe633c"},
    {"type":"population","name":"Melioidosis","category_id":"61e01812307372e5dc6fdd04"},
    {"type":"population","name":"Neglected tropical diseases","category_id":"61e0191d3d0515e90ae5f1bf"},
    {"type":"population","name":"Strongyloidiasis","category_id":"61e0773706768ae17b4df63b"},
    {"type":"population","name":"Scabies","category_id":"61e02d0169c00e0b937ed7e6"},
    {"type":"population","name":"Paracoccidioidomycosis","category_id":"61e073d2ea88903585fe6172"},
    {"type":"population","name":"Smallpox","category_id":"61e076a3ea88903585fe6231"},
    {"type":"population","name":"Tick-borne encephalitis","category_id":"61e077fa06768ae17b4df66a"},
    {"type":"population","name":"Trichomoniasis","category_id":"61e0788e30737252756fe032"},
    {"type":"population","name":"Cancer","category_id":"5cfa863adbe4d25bcfbee2c7"},
    {"type":"population","name":"Pediatric cancer","category_id":"62a904023d0515e3d541fcc3"},
    {"type":"population","name":"COPD","category_id":"5d0b5bdedbe4d25bcfbee803"},
    {"type":"population","name":"Asthma","category_id":"5d7520f369c00e39658ba0fa"},
    {"type":"population","name":"Pediatric respiratory diseases","category_id":"62a91523ea88903c2895b27b"},
    {"type":"population","name":"Connective tissue diseases","category_id":"5d14114adaeedf730426b7b8"},
    {"type":"population","name":"Dental disorders","category_id":"5d234900daeedf1d3af33306"},
    {"type":"population","name":"Hematological conditions","category_id":"5d27332adaeedf1d3bf3330a"},
    {"type":"population","name":"Sickle cell disease","category_id":"5da2ea543c12ef5722a1d710"},
    {"type":"population","name":"Thalassemia","category_id":"61e08aee307372777b6fdd98"},
    {"type":"population","name":"Ischemic heart diseases","category_id":"5d2756d7daeedf1d3af3332a"},
    {"type":"population","name":"Venous thromboembolism","category_id":"5d3f38f669c00e04ee74f5e7"},
    {"type":"population","name":"Cerebrovascular diseases","category_id":"5d3f397e69c00e04ee74f5e9"},
    {"type":"population","name":"Hypertension","category_id":"5d3f3aec69c00e04ef74f5c8"},
    {"type":"population","name":"Epilepsy","category_id":"5d7e2ac769c00e72a5188e41"},
    {"type":"population","name":"Multiple sclerosis","category_id":"5d7e2be069c00e72a5188e42"},
    {"type":"population","name":"Parkinson disease","category_id":"5d99f1f03c12ef11577a0053"},
    {"type":"population","name":"Pediatric neurological conditions","category_id":"62a90e9eea88903c2895b0d2"},
    {"type":"population","name":"Mental health","category_id":"5d4322c869c00e198a009df3"},
    {"type":"population","name":"Dementia","category_id":"5d4322f769c00e198b009e1b"},
    {"type":"population","name":"Substance use disorder","category_id":"5d43245e69c00e198b009e1e"},
    {"type":"population","name":"Domestic violence","category_id":"5da097e33c12ef4de3dce666"},
    {"type":"population","name":"Sexual abuse","category_id":"5da097e33c12ef4de3dce667"},
    {"type":"population","name":"Child abuse","category_id":"5da097e33c12ef4de3dce668"},
    {"type":"population","name":"Pediatric mental health","category_id":"62a90f8e06768aeab576cc5d"},
    {"type":"population","name":"Gastrointestinal disorders","category_id":"5d44e01769c00e198b009e39"},
    {"type":"population","name":"Liver cirrhosis","category_id":"5d52974169c00e3de733d823"},
    {"type":"population","name":"Eye disorders","category_id":"5d5832c369c00e490b8f5553"},
    {"type":"population","name":"Chronic kidney disease","category_id":"5d5e8e3669c00e507e1c8883"},
    {"type":"population","name":"Infertility","category_id":"5d9680db69c00e73b4b22508"},
    {"type":"population","name":"Ear, nose, and throat disorders","category_id":"5d7e231569c00e72a5188e3c"},
    {"type":"population","name":"Congenital disorders","category_id":"5d7f88af69c00e72a3188e15"},
    {"type":"population","name":"Endocrine disorders","category_id":"5d94f1d13c12ef641517c8d6"},
    {"type":"population","name":"Diabetes","category_id":"5d52919a69c00e3de733d81f"},
    {"type":"population","name":"Nutrition and metabolic disorders","category_id":"5d94f2a13c12ef641517c8d7"},
    {"type":"population","name":"Overweight and obesity","category_id":"5cf00956dbe4d25bcfbedf17"},
    {"type":"population","name":"Pediatric obesity","category_id":"62a902c6307372b0385ca5db"},
    {"type":"population","name":"Childhood diseases","category_id":"6231aeb73073724b9bd88b7d"},
    {"type":"population","name":"Newborn health","category_id":"6231af3d06768abaa9e445b2"},
    {"type":"population","name":"Preterm/low birth weight","category_id":"6232ebdbea8890f8d8bbde6d"},
    {"type":"population","name":"Perinatal asphyxia","category_id":"6232f051307372ecd7349e7c"},
    {"type":"diagnosis","name":"Labour monitoring","category_id":"61df363006768a5c4c4df45a"},
    {"type":"population","name":"Antimicrobial resistance","category_id":"60c8b64006768aab069c540d"},
    {"type":"population","name":"Sexually transmitted infections","category_id":"60ef101206768ac0e77738d8"},
    {"type": "population", "name": "Syphilis", "category_id":"5d0b46cec80dd41e57ba89c1"},
    {"type":"population","name":"Trauma, poisoning and injuries","category_id":"5d7e31f669c00e72a5188e45"},
    {"type":"population","name":"Accidental poisoning","category_id":"6232f489307372009d349b86"},
    {"type":"population","name":"Sedentary lifestyle","category_id":"61e0866106768af3424df682"},
    {"type":"population","name":"Salt consumption","category_id":"61e08a2569c00ef2ea7ed7d2"},
    {"type":"intervention_variable","name":"Water pollution","category_id":"6208400dea88909b3f0a3c97"},
    {"type":"intervention_variable","name":"Air quality","category_id":"620840bb355258cf6dfd0138"},
    {"type":"intervention_variable","name":"Soil pollution","category_id":"620840e306768aef5a232488"},
    {"type":"intervention_variable","name":"Health professionals","category_id":"623370453d0515a24b10fd28"},
    {"type":"intervention_variable","name":"Health associate professionals","category_id":"623382d0307372b09a349e1e"},
    {"type":"intervention_variable","name":"Personal care workers in health services","category_id":"6233909a69c00ed278da2098"},
    {"type":"intervention_variable","name":"Health management and support personnel","category_id":"6233924406768a3c75fb97a3"},
    {"type":"intervention_variable","name":"Weather-related disasters","category_id":"619a453469c00e98db28c887"},
    {"type":"intervention_variable","name":"Man-made disasters","category_id":"623450bb307372f556349d92"},
    {"type":"intervention_variable","name":"Earth movement disasters","category_id":"62345349ea8890143ebbdcab"},
    {"type":"population","name":"Pain during labor","category_id":"630fdb8dc7585e000861eb74"},
    {"type":"population","name":"Asymptomatic bacteriuria in pregnancy","category_id":"630fd98ac7585e000861eaec"},
    {"type":"population","name":"Labor and delivery","category_id":"5d6848cb69c00e131b5a4cc0"},
    {"type":"population","name":"Postpartum period","category_id":"630fd43db25ac300085beafb"},
    {"type":"population","name":"Maternal malnutrition","category_id":"630fd787b25ac300085beba6"},
    {"type":"treatment","name":"Obstetric procedures","category_id":"630fddefb128010008f0ed9f"}


  ],
  sdg3_root_categories: [
    '6167009e06768adee26a276f',
    '616700b43552581d99259cbd',
    '616700c83d0515512662039e',
    '616700dfea88905b6d651ab4',
    '6167019d06768adee26a27b6',
    '616701b83d051551266203e3',
    '616701f0ea88905b6d651b03',
    '616701fd06768adee26a27d3',
    '6167020b3d05155126620408',
    '6167021bea88905b6d651b12',
    '6167022606768adee26a27e8',
    '616702393d05155126620415',
    '61670245ea88905b6d651b27'
  ],
  filters: {
    year: [
      parseInt(new Date().getFullYear()),
      // 'last_year',
      'last_two',
      'last_five',
    ],
    institution: [
      {value: 'who', text: 'who'},
      {value: 'paho', text: 'paho'},
    ],
    age: [
      // {value: null, text: 'select_age'},
      // {value: 'neonate', text: 'neonate'},
      // {value: 'infant', text: 'infant'},
      // {value: 'child', text: 'child'},
      // {value: 'adolescent', text: 'adolescent'},
      // {value: 'adult', text: 'adult'}
      'children',
      'adolescents',
      'adults'
    ]
  }
}

export default platform_config


