<template>
  <div v-if="this.strength.show">
    <span class="label">
      {{$t(`general.rec_type_labels.${this.strength.label}`)}}
    <div class="icon">
      <img v-if="this.strength.icon == 'red_full'" src="../../assets/images/red_full.svg" alt="ops-logo">
      <img v-if="this.strength.icon == 'red_empty'" src="../../assets/images/red_empty.svg" alt="ops-logo">
      <img v-if="this.strength.icon == 'green_full'" src="../../assets/images/green_full.svg" alt="ops-logo">
      <img v-if="this.strength.icon == 'green_empty'" src="../../assets/images/green_empty.svg" alt="ops-logo" class="border">
      <img v-if="this.strength.icon == 'dash_grey'" src="../../assets/images/dash_grey.svg" alt="ops-logo">
      <img v-if="this.strength.icon == 'times_grey'" src="../../assets/images/times_grey.svg" alt="ops-logo">
      <img v-if="this.strength.icon == 'ticket_grey'" src="../../assets/images/ticket_grey.svg" alt="ops-logo">
    </div></span>
  </div>
</template>

<script>
export default {
  name: 'Strength',
  data: function () {
    return {}
  },
  props: [
    'type',
    'direction'
  ],
  methods: {},
  computed: {
    f_type: function () {
      return (this.type || '').trim().toLowerCase().replace('\n', ' ')
    },
    f_direction: function () {
      return (this.direction || '').trim().toLowerCase()
    },
    strength: function () {
      let ret_strength = {show: false}
      if (this.f_type == 'strong' && this.f_direction == 'for the intervention') {
        ret_strength = {
          show: true,
          label: 'strong',
          icon: 'green_full'
        }
      }
      else if (this.f_type == 'conditional' && this.f_direction == 'for the intervention') {
        ret_strength = {
          show: true,
          label: 'conditional',
          icon: 'green_empty'
        }
      }
      else if (this.f_type == 'strong' && this.f_direction == 'against the intervention') {
        ret_strength = {
          show: true,
          label: 'strong',
          icon: 'red_full'
        }
      }
      else if (this.f_type == 'conditional' && this.f_direction == 'against the intervention') {
        ret_strength = {
          show: true,
          label: 'conditional',
          icon: 'red_empty'
        }
      }
      else if (this.f_type == 'recommended' && this.f_direction == 'for the intervention') {
        ret_strength = {
          show: true,
          label: 'recommended',
          icon: 'ticket_grey'
        }
      }
      else if (this.f_type == 'not recommended' && this.f_direction == 'against the intervention') {
        ret_strength = {
          show: true,
          label: 'not_recommended',
          icon: 'times_grey'
        }
      }
      else if (this.f_type == 'research-context recommendation') {
        ret_strength = {
          show: true,
          label: 'research_context',
          icon: 'dash_grey'
        }
      }
      else if (this.f_type == 'context-specific recommendation') {
        ret_strength = {
          show: true,
          label: 'context_specific',
          icon: 'dash_grey'
        }
      }
      else if (this.f_type == 'recommended in the context of targeted monitoring and evaluation') {
        ret_strength = {
          show: true,
          label: 'targeted_monitoring_context',
          icon: 'dash_grey'
        }
      }
      else if (this.f_type == 'mec category 1' && this.f_direction == 'for the intervention') {
        ret_strength = {
          show: true,
          label: 'mec_1',
          icon: 'ticket_grey'
        }
      }
      else if (this.f_type == 'mec category 2' && this.f_direction == 'for the intervention') {
        ret_strength = {
          show: true,
          label: 'mec_2',
          icon: 'ticket_grey'
        }
      }
      else if (this.f_type == 'mec category 3' && this.f_direction == 'against the intervention') {
        ret_strength = {
          show: true,
          label: 'mec_3',
          icon: 'times_grey'
        }
      }
      else if (this.f_type == 'mec category 4' && this.f_direction == 'against the intervention') {
        ret_strength = {
          show: true,
          label: 'mec_4',
          icon: 'times_grey'
        }
      }
      return ret_strength
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../../assets/styles/variables.scss";
img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  &.border {
    border-radius: 50%;
    border: 1px solid #b6e8fb;
  }
}
.strategy-group {
  border: 1px solid $headercolor;
}
.label {
  // text-transform: capitalize;
  display: flex;
  vertical-align: middle;
  .icon {
    display: inline-flex;
    margin-left: auto;
  }
}
</style>
