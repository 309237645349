<template>
  <b-popover
    target="sdg3-popover"
    triggers="hover"
    placement="bottomleft"
    container="body"
    class="sdg3-popover"
  >
    <template #title>
      <img
        class="sdg3-logo"
        :src="require(`@/assets/images/UN_SDG3_${$i18n.locale}.png`)"
        alt="sdg3-logo"
      />
    </template>
    <span class="static-content">
      <h2>Objetivos de Desenvolvimento Sustentável (ODS)</h2>
      <p>
        Os
        <a
          href="https://www.un.org/sustainabledevelopment/sustainable-development-goals/"
          target="_blank"
          >Objetivos de Desenvolvimento Sustentável
          <font-awesome-icon icon="external-link-alt" class="ml-1"
        /></a>
        são um apelo universal à ação para acabar com a pobreza, proteger o planeta e
        melhorar a vida e as perspectivas de todos, em todos os lugares. Os 17 Objetivos
        foram adotados por todos os Estados Membros da ONU em 2015, como parte da
        <a
          href="https://www.un.org/sustainabledevelopment/development-agenda/"
          target="_blank"
          >Agenda 2030 para o Desenvolvimento Sustentável
          <font-awesome-icon icon="external-link-alt" class="ml-1"
        /></a>
        que estabeleceu um plano de 15 anos para alcançar os Objetivos.
      </p>

      <h2>Terceiro Objetivo de Desenvolvimento Sustentável (ODS-3)</h2>
      <p>
        O Terceiro Objetivo de Desenvolvimento Sustentável é garantir uma vida saudável e
        promover o bem-estar para todos, em todas as idades. Leia mais (<a
          href="https://www.un.org/sustainabledevelopment/health/"
          target="_blank"
          >https://www.un.org/sustainabledevelopment/health/
          <font-awesome-icon icon="external-link-alt" class="ml-1" /></a
        >)
      </p>
    </span></b-popover
  >
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.popover {
  h2 {
    margin-top: initial;
    font-weight: bold;
    font-size: 90%;
  }
  p {
    font-size: 90%;
    line-height: initial;
  }
}
</style>
