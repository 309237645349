<template>
  <div>
    <p>
      O portal de recomendações GRADE da OPAS/OMS para o ODS-3 tem como objetivo coletar
      todas as recomendações para a clínica, saúde pública e política de saúde emitidas
      pelas diretrizes da OMS e da OPAS que seguem a abordagem
      <a href="https://www.gradeworkinggroup.org/" target="_target"
        >GRADE <font-awesome-icon icon="external-link-alt" class="ml-1" /></a
      >. É um repositório de acesso livre na Biblioteca Virtual em Saúde (<a
        href="https://bvsalud.org/en/"
        target="_blank"
        >VHL<font-awesome-icon icon="external-link-alt" class="ml-1" /></a
      >) que permite que os tomadores de decisão identifiquem recomendações relevantes
      para sua pergunta de interesse. Este banco de dados fornece aos usuários um meio
      eficiente para pesquisar e encontrar orientações com base em suas perguntas de
      interesse, com funções de pesquisa, filtro e cruzamento de dados integradas,
      incluindo a organização pelos terceiros objetivos de desenvolvimento sustentável
      (ODS-3). Usamos o quadro amplamente aceito de população, intervenções, comparadores
      e resultados (PICO) para mapear e catalogar recomendações. Um de seus principais
      objetivos é permitir a tomada de decisões contextualizadas a nível local,
      provincial, regional e jurisdicional para os trabalhadores da saúde e tomadores de
      decisão por meio da adoção ou adaptação dessas recomendações.
    </p>
    <p>
      Essa plataforma dependerá de contribuições contínuas dos desenvolvedores de
      diretrizes, que incentivamos, bem como dos usuários, e, portanto, sua funcionalidade
      continuará melhorando e será atualizada regularmente para manter sua relevância.
    </p>
    <p>
      Este é um produto da colaboração entre a
      <a href="https://www.paho.org/en" target="_blank"
        >Organização Pan-Americana da Saúde<font-awesome-icon
          icon="external-link-alt"
          class="ml-1"
      /></a>
      e a
      <a href="https://foundation.epistemonikos.org/" target="_blank"
        >Fundação Epistemonikos<font-awesome-icon icon="external-link-alt" class="ml-1"
      /></a>
      disponível na Biblioteca Virtual em Saúde (<a
        href="https://bvsalud.org/en/"
        target="_blank"
        >BVS<font-awesome-icon icon="external-link-alt" class="ml-1" /></a
      >).
    </p>

    <h2>Links relacionados:</h2>
    <ul>
      <li>
        Pan-American Health Organization
        <br />
        <a href="https://www.paho.org/en" target="_blank"
          >https://www.paho.org/en<font-awesome-icon
            icon="external-link-alt"
            class="ml-1"
        /></a>
      </li>
      <li>
        Virtual Health Library
        <br />
        <a href="https://bvsalud.org/en" target="_blank"
          >https://bvsalud.org/en<font-awesome-icon icon="external-link-alt" class="ml-1"
        /></a>
      </li>
      <li>
        BIGG – International Database of GRADE Guidelines
        <br />
        <a href="https://sites.bvsalud.org/bigg/en/biblio/" target="_blank"
          >https://sites.bvsalud.org/bigg/en/biblio/<font-awesome-icon
            icon="external-link-alt"
            class="ml-1"
        /></a>
      </li>
      <li>
        GRADE Working Group
        <br />
        <a href="https://www.gradeworkinggroup.org" target="_blank"
          >https://www.gradeworkinggroup.org<font-awesome-icon
            icon="external-link-alt"
            class="ml-1"
        /></a>
      </li>
      <li>
        Epistemonikos Foundation
        <br />
        <a href="https://foundation.epistemonikos.org" target="_blank"
          >https://foundation.epistemonikos.org<font-awesome-icon
            icon="external-link-alt"
            class="ml-1"
        /></a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
