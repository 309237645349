<template>
<div>
    <template v-if="$i18n.locale == 'en'">
        <div class="container static-content">
            <h1>Not Found</h1>
            <p class="lead">
            The page you are looking for does not exist. Try going <router-link to="/">{{$t('general.home')}}</router-link>
            </p>
        </div>
    </template>
    <template v-else>
        <div class="container static-content">
            <h1>Página no encontrada</h1>
            <p class="lead">
            La página que estás buscando no se encuentra. Prueba volviendo a <router-link to="/">{{$t('general.home')}}</router-link>
            </p>
        </div>
    </template>
</div>

</template>

<script>
    export default {
        name: 'NotFound',
    }
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables.scss";
@import "../assets/styles/typography.scss";
</style>
