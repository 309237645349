<template>
  <div>
    <div class="portal-title">
      <welcome-jumbotron />
    </div>
    <div class="my-4 container">
      <div class="row">
        <div class="col-md-12 my-5">
          <div id="search-box">
            <b-input-group>
              <b-form-input
                class="form-control love-focus big-form"
                :placeholder="$t('general.search_by_keyword')"
                aria-label="Search by keywords"
                aria-describedby="basic-addon2"
                v-on:keyup.enter="filterSearch(true, false)"
                size="lg"
                v-model.trim="searchTopic"
              >
              </b-form-input>
              <button class="clear-search" v-if="showClearSearch" @click="clearSearch">
                <font-awesome-icon icon="times" />
              </button>
              <b-input-group-append>
                <button
                  class="btn btn-episte search-button"
                  type="button"
                  @click="filterSearch(true, false)"
                >
                  <font-awesome-icon icon="search" />
                </button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <!--<div class="row my-4">
            <div class="container col-12">
              <h2 class="container text-center">
                Choose how to browse the recommendations
                <span v-if="$mq != 'mobile'">
                  <span class="select-list-type ml-4 mr-1" :class="{'selected': (searchByKeywords)}" @click="changeListType('loves')">By category</span>  <span class="select-list-type" :class="{'selected': !searchByKeywords}" @click="changeListType('topics')">By topics</span>
                </span>
              </h2>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div
      class="sdg3-goals pb-4"
      :class="{ 'container-fluid': $mq != 'mobile', container: $mq == 'mobile' }"
    >
      <div class="container">
        <div class="row">
          <div class="col-12 mt-5" ref="lovesTop">
            <h1>
              <span class="mr-2">{{ $t("general.sdg3_header_1") }}</span
              ><img
                style="height: 35px"
                :src="require('../assets/images/SDG3_' + $i18n.locale + '.png')"
              />
              <span class="ml-2">{{ $t("general.sdg3_header_2") }}</span>
            </h1>
          </div>
          <div class="col-12">
            <div class="row">
              <div
                class="col-md-4 col-lg-3 grid"
                v-for="(topic, id) in listOfTopics"
                :key="id"
              >
                <topics-list-item
                  :topic="topic"
                  :mode="mode"
                  :selectTopic="selectTopic"
                />
              </div>
              <div v-if="loading" class="col-12">
                <div class="la-ball-atom mx-auto">
                  <div v-for="n in 4" :key="n"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Api from '../utils/Api'
// import LovesListItem from './LovesListItem'
import TopicsListItem from "./TopicsListItem";
import VueScrollTo from "vue-scrollto";
// import CategorySelector from './CategorySelector.vue'
// import Swatches from 'vue-swatches'
import WelcomeJumbotron from "./WelcomeJumbotron";
import "vue-swatches/dist/vue-swatches.min.css";
import platform_config from "../platform";

// function escapeRegExp (string) {
//   return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
// }
export default {
  name: "TopicsList",
  components: {
    // 'loves-list-item': LovesListItem,
    "topics-list-item": TopicsListItem,
    // 'category-selector': CategorySelector,
    // Swatches,
    "welcome-jumbotron": WelcomeJumbotron,
  },
  data() {
    return {
      taxonomy: [],
      listOfTopics: [],
      numberOfTopics: 0,
      currentPage: 1,
      newTopicName: "",
      newTopicColor: "",
      searchTopic: "",
      topicsPerPage: 60,
      recsPerPage: 12,
      selectedList: "loves",
      mode: "grid",
      selectedTopic: null,
      topicColors: [
        "#FBE6FC",
        "#B6D7FF",
        "#CDBDFF",
        "#FFF5AE",
        "#FFD18D",
        "#FFA9A9",
        "#D9FFDD",
        "#C5FFFF",
      ],
      /* 'currentPageLove': 1, */
      setupStatus: "with_category_id",
      numberOfRecommendations: 0,
      newLoveName: "",
      loadingTaxonomy: true,
      loadingLovesNames: true,
      selectedCategory: null,
      selectedCategoryType: "",
      lovesNames: "",
      selectedLove: "",
      loading: false,
    };
  },
  created: function () {
    // this.getLovesData()

    this.listOfTopics = platform_config.sdg3_goals.filter((s) => s.main);
  },
  methods: {
    scrollToTop: function () {
      let element = this.$refs.lovesTop;
      var options = {
        container: "body",
        easing: "ease-in",
        offset: -60,
        x: false,
        y: true,
      };
      VueScrollTo.scrollTo(element, 500, options);
    },
    changeListType: function (listType) {
      this.selectedList = listType;
      this.selectedTopic = null;
      this.currentPage = 1;
      if (this.selectedList === "loves") {
        // this.getLovesData()
        this.mode = "list";
      } else {
        this.getTopicsData();
        this.mode = "grid";
      }
    },
    filterSearch: function () {
      // filterSearch: function (resetPage = false, doScroll = false) {
      this.searchRecommendations();
      // this.getRecommendations(resetPage, doScroll)
      // this.getLovesData(resetPage, doScroll)
    },
    selectTopic: function (topic) {
      // this.selectedTopic = topic
      // this.getLovesData()
      // this.mode = 'list'
      this.$router.push({
        name: "RecommendationsMain",
        params: { lang: this.$i18n.locale, id: topic.category_id },
        query: { intervention_variable: topic.category_id },
      });
      // this.$router.push(`/recommendations?intervention_variable=${topic.category_id}`)
    },
    closeSelectedTopic: function () {
      this.selectedTopic = null;
      this.numberOfRecommendations = 0;
      this.mode = "grid";
    },
    searchRecommendations() {
      this.$router.push({
        name: "RecommendationsMain",
        params: { lang: this.$i18n.locale },
        query: { q: this.searchTopic.trim() },
      });
    },
    // getRecommendations (resetPage = false, doScroll = false) {
    //   this.loading = true
    //   if (resetPage) {
    //     this.currentPage = 1
    //   }
    //   let params = {
    //     'page_size': this.recsPerPage,
    //     'page': this.currentPage,
    //     // 'setup_status': this.setupStatus
    //   }
    //   if ((this.searchTopic.trim()).length) {
    //     let escapedSearch = escapeRegExp(this.searchTopic.trim())
    //     params['query'] = escapedSearch
    //   }
    //   // debugger; // eslint-disable-line no-debugger
    //   let url = '/recommendations_search'
    //   if (this.selectedTopic) {
    //     url = `/topics/${this.selectedTopic.id}/loves`
    //   }
    //   Api.get(url, params).then(response => {
    //     this.numberOfRecommendations = response.data.total
    //     this.loading = false
    //     if (doScroll) {
    //       this.scrollToTop()
    //     }
    //   }).catch(function (error) {
    //     self.error = error
    //   })
    // },
    // getLovesData (resetPage = false, doScroll = false) {
    //   this.loading = true
    //   if (resetPage) {
    //     this.currentPage = 1
    //   }
    //   let params = {
    //     'size': this.recsPerPage,
    //     'page': this.currentPage,
    //     'setup_status': this.setupStatus
    //   }
    //   if ((this.searchTopic.trim()).length) {
    //     let escapedSearch = escapeRegExp(this.searchTopic.trim())
    //     params['love_name'] = escapedSearch
    //   }
    //   // debugger; // eslint-disable-line no-debugger
    //   let url = '/loves'
    //   if (this.selectedTopic) {
    //     url = `/topics/${this.selectedTopic.id}/loves`
    //   }
    //   Api.get(url, params).then(response => {
    //     this.numberOfRecommendations = response.data.total
    //     this.loading = false
    //     if (doScroll) {
    //       this.scrollToTop()
    //     }
    //   }).catch(function (error) {
    //     self.error = error
    //   })
    // },
    // getTopicsData (resetPage = false, doScroll = false) {
    //   this.loading = true
    //   this.listOfTopics = []
    //   if (resetPage) {
    //     this.currentPage = 1
    //   }
    //   let params = {
    //     'size': this.topicsPerPage,
    //     'page': this.currentPage
    //   }
    //   if ((this.searchTopic.trim()).length) {
    //     let escapedSearch = escapeRegExp(this.searchTopic.trim())
    //     params['topic_name'] = escapedSearch
    //   }
    //   Api.get('/topics', params).then(response => {
    //     this.listOfTopics = response.data.items
    //     this.numberOfTopics = response.data.total
    //     this.loading = false
    //     if (doScroll) {
    //       this.scrollToTop()
    //     }
    //   }).catch(function (error) {
    //     self.error = error
    //   })
    // },
    clearSearch() {
      this.searchTopic = "";
      // if (this.searchByKeywords) {

      //   // this.getLovesData()
      // } else {
      //   this.getTopicsData()
      // }
    },
    openNewTopicModal() {
      this.newTopicColor = this.topicColors[
        Math.floor(Math.random() * this.topicColors.length)
      ];
      this.$refs.newTopic.show();
    },
    closeNewTopicModal() {
      this.$refs.newTopic.hide();
    },
    // saveNewTopic () {
    //   if (this.validNewTopicForm) {
    //     let data = {
    //       'name': this.newTopicName,
    //       'color': this.newTopicColor
    //     }
    //     Api.post('/topics', data)
    //     this.getTopicsData()
    //     this.$refs.newTopic.hide()
    //   }
    // },
    // getTaxonomy: function () {
    //   if (this.loadingTaxonomy) {
    //     Api.get(`/taxonomy/all`).then((response) => {
    //       this.loadingTaxonomy = false
    //       this.taxonomy = response.data
    //     })
    //   }
    // },
    selectCategory: function (event) {
      this.$set(this, "selectedCategory", event);
    },
    selectLove: function (event) {
      this.$set(this, "selectedLove", event.id);
    },
  },
  computed: {
    // isAdmin: function () {
    //   return ((this.$store.state.account || {}).api_roles || []).includes('admin')
    // },
    showClearSearch: function () {
      return this.searchTopic.trim().length > 0;
    },
    validNewTopicForm: function () {
      return (this.newTopicName || "").trim().length > 0;
    },
    validNewLoveForm: function () {
      return this.newLoveName && this.selectedCategory;
    },
    searchByKeywords: function () {
      if (this.selectedTopic) {
        return true;
      }
      if (this.selectedList === "loves") {
        return true;
      }
      return false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/loading.scss";
@import "../assets/styles/variables.scss";
.la-ball-atom {
  color: $green-font;
}
.la-ball-circus {
  color: $silver;
}
.portal-title {
  margin-top: 85px;
}
h3 {
  margin-top: 20px;
}
.topics-title {
  color: $green-font;
}
.search-button {
  width: 70px;
}
.sdg3-goals {
  background-color: $ops_portal_sdg3;
  padding-top: 40px;
  h1 {
    color: white;
    margin-bottom: 1em;
  }
}
.clear-search {
  z-index: 1000;
  position: absolute;
  border: none;
  width: 20px;
  height: 95%;
  top: 0;
  bottom: 0;
  right: 75px;
  margin: auto;
  background: white;
  line-height: 0px;
  cursor: pointer;
  transition: 0.1s;
  color: #1a4c80;
}
.clear-search:focus {
  outline: none;
  color: $headercolor;
}
.result-item {
  padding: 10px 5px;
  border: 1px solid #ddd;
}
.result-item > a {
  color: #333;
}
.select-list-type {
  font-size: 1.2rem;
  background-color: $grey;
  text-align: center;
  margin: auto;
  padding: 5px;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 3px;
  cursor: pointer;
}
.select-list-type.selected {
  background-color: $green-font;
  color: white;
}
.selected-topic {
  padding: 5px 10px;
  border: 1px solid $headercolor;
  border-radius: 8px;
  margin-top: 5px;
  color: $headercolor;
  background-color: $breadcrumb;
  .close-selected-topic {
    position: absolute;
    right: 23px;
    top: 14px;
  }
}
.add-new-container {
  text-align: right;
}
.mode-selector {
  font-size: 24px;
  color: $green-font;
  i {
    vertical-align: middle;
  }
}
.big-focus:focus {
  .btn {
    background-color: #333;
  }
}
</style>
