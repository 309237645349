<template>
  <div>
    <h1>POLÍTICA DE PRIVACIDADE DOS WEBSITES E SISTEMAS DA BIREME/OPAS</h1>

    <p>
      A utilização dos produtos, serviços e/ou ferramentas que o Centro Latino-Americano e
      do Caribe de Informação em Ciências da Saúde (BIREME), Centro Especializado da
      Organização Pan-Americana da Saúde, Escritório Regional para as Américas da
      Organização Mundial da Saúde, doravante BIREME/OPAS, coloca à disposição dos
      usuários, constitui aceitação da seguinte Política de Privacidade. Em caso de
      discrepância entre o texto em espanhol e qualquer outro idioma desta Política de
      Privacidade, prevalecerá o documento em espanhol.
    </p>

    <p>
      Esta Política de Privacidade é complementada pela
      <a
        href="https://www.paho.org/hq/index.php?option=com_content&view=article&id=3201:paho-website-privacy-policy&Itemid=2410&lang=en"
        target="_blank"
        >Política Geral de Privacidade da OPAS.
        <font-awesome-icon icon="external-link-alt" class="ml-1" /></a
      >.
    </p>

    <h1>PRIVACIDADE</h1>
    <p>
      Sua privacidade é de suma importância para a BIREME/OPAS. Nesta política de
      privacidade descrevem-se as práticas da BIREME/OPAS no tocante à compilação e troca
      de informação dos usuários do presente site da BIREME/OPAS.
    </p>

    <h2>Que tipo de informação é compilada pela BIREME/OPAS?</h2>
    <h3>Uso dos sites</h3>

    <p>
      Em geral você pode consultar os sites da BIREME/OPAS sem dizer quem é. A informação
      compilada durante uma consulta geral provém dos registros ordinários do servidor e
      inclui seu endereço IP (protocolo da internet), o nome do domínio, o tipo de
      navegador, o sistema operacional e informação sobre como chegou a nós, os arquivos
      que baixou, os sites que consultou e a data e hora dessas consultas. Dados
      demográficos também podem ser coletados durante uma consulta, caso você esteja
      autenticado em um serviço de terceiros com permissão de compartilhar sua informação
      de idade, gênero e categorias de interesse.
    </p>

    <h3>Compilação de informação que permite identificar a pessoa</h3>

    <p>
      Se você se registrar para acessar algum site, produto ou serviço da BIREME/OPAS,
      como os Serviços Personalizados Mi BVS e FI-Admin, deverá oferecer informação
      pessoal como seu nome, email, instituição em que trabalha, país de origem, nível
      acadêmico, área de atuação profissional, sexo, data de nascimento e dados das suas
      redes sociais. Esta informação só será compilada com seu conhecimento e permissão e
      será mantida nas bases de dados da BIREME/OPAS, bem como nas listas de endereços.
    </p>

    <p>
      Para certos sites da BIREME/OPAS, o registro ou certas informações pessoais fazem
      com que seja instalado um cookie* em seu computador, que permitirá que a BIREME/OPAS
      lembre suas informações da próxima vez que consultar o site, para que não seja
      preciso colocar novamente seus dados, ajudando-nos a lhe oferecer um serviço melhor.
    </p>

    <p>
      Se você se incorporar a um grupo de discussão eletrônica, outros participantes do
      grupo (incluídas pessoas externas à OPAS) poderão ver suas informações pessoais. No
      caso dos grupos de debate abertos, esta informação será pública.
    </p>

    <h2>O que a BIREME/OPAS faz com a informação que compila?</h2>

    <p>
      A informação compilada durante uma consulta geral é usada para analisar tendências e
      o uso dos sites da BIREME/OPAS, bem como para melhorar a utilidade desses sites.
    </p>

    <h3>Informação que permite identificar as pessoas</h3>

    <p>A BIREME/OPAS pode usar a informação pessoal proporcionada, entre outros para:</p>

    <ul>
      <li>
        entrar em contato com você a fim de responder uma consulta ou sugestão, ou para
        lhe enviar boletins informativos, documentos, publicações etc.;
      </li>
      <li>confirmar sua informação de registro no site;</li>
      <li>"lembrar" seu perfil e preferências on-line;</li>
      <li>
        ajudá-lo a encontrar rapidamente informação útil conforme seus interesses e
        ajudarnos a criar um conteúdo que lhe seja mais proveitoso;
      </li>
      <li>realizar uma análise estatística.</li>
    </ul>

    <h2>Que acontece se não quiser oferecer informação pessoal?</h2>

    <p>
      É voluntário fornecer informação pessoal nos sites da BIREME/OPAS. Se optar por não
      fornecer esse tipo de informação, de qualquer forma pode consultar e usar os sites
      da BIREME/OPAS, porém não poderá utilizar certas opções, como acessar os Serviços
      Personalizados Mi BVS ou gerir fontes de informação da BVS através do sistema web
      FI-Admin.
    </p>

    <h3>Opção de modificar a informação ou solicitar que ela seja eliminada</h3>

    <p>
      A qualquer momento você pode modificar sua informação ou solicitar que seja
      eliminada; para isso deve entrar novamente no site onde inseriu a informação pela
      primeira vez e comunicar-se com o ponto focal desse site. Se não forem
      proporcionados dados de contato no site, pode contatar a bir.online@paho.org.
    </p>

    <h1>SEGURANÇA</h1>
    <p>
      Toda a informação fornecida à BIREME/OPAS pelos usuários dos sites da BIREME/OPAS é
      guardada com grande cuidado e segurança, e não se usará de maneira diferente das
      estabelecidas por essa política de privacidade, em qualquer política própria do
      site, ou das que você autorizou explicitamente.
    </p>

    <p>
      Todos os nossos funcionários que têm acesso aos dados pessoais e estão ligados ao
      seu processamento são obrigados a respeitar o sigilo dos assuntos oficiais,
      inclusive dos dados pessoais.
    </p>

    <p>
      Os sites da BIREME/OPAS contêm links para sites externos. A BIREME/OPAS não é
      responsável pelas práticas de privacidade nem pelo conteúdo de tais sites.
    </p>

    <h2>Notificação de mudanças</h2>

    <p>
      A BIREME/OPAS poderá modificar esta Política de Privacidade e publicará estas
      modificações no presente site.
    </p>

    <h2>Contato</h2>
    <p>
      Para perguntas ou consultas no tocante a essa política de privacidade, comunique-se
      conosco no bir.online@paho.org.
    </p>

    <p>* Cookie</p>

    <p>
      Um cookie é um pequeno conjunto de dados enviado de um servidor da web para seu
      navegador. É usado normalmente para atribuir uma identificação única ao seu
      computador e armazenar de forma segura informações como o ID do usuário, senhas,
      preferências e perfis eletrônicos. É armazenado no disco rígido de seu computador.
      Você tem a opção de não permitir a instalação de cookies da OPAS/OMS se isso mudar a
      configuração de seu navegador. Diversos sites podem enviar seus próprios cookies a
      seu computador. Para proteger sua privacidade, o navegador permite apenas que um
      site obtenha acesso a seus próprios cookies e não permite o acesso aos instalados
      por outros sites.
    </p>

    <strong
      >SE NÃO ESTIVER CONFORME COM ALGUM DOS TERMOS E CONDIÇÕES DESTA POLÍTICA DE
      PRIVACIDADE DEVERÁ SE ABSTER DE UTILIZAR O SITE E QUALQUER PRODUTO, SERVIÇO E/OU
      FERRAMENTA NELE CONTIDO.</strong
    >
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/variables.scss";
@import "../../../assets/styles/typography.scss";
// h2 {
//   margin: auto;
//   text-align: center;
//   vertical-align: middle;
//   padding: 5px;
// }
// p {
//   margin: auto;
//   text-align: center;
//   vertical-align: middle;
// }
// img {
//   background-color: $white;
// }
</style>
