<template>
  <div class="mb-5">
    <div class="main" v-if="recommendation" :class="{'mobile': $mq == 'mobile'}">

      <template v-if="$mq != 'mobile'">
        <div class="row first-row">
          <div class="col-12 top-metadata">
            <div class="rec-metadata" v-show="getType" id="tooltip-type">
              <span class="meta-label">{{$t('general.rec_strength')}}:</span>
              <strength :type="recommendation.rec_type" :direction="recommendation.rec_direction"></strength>
              <b-tooltip target="tooltip-type" placement="bottom">{{$t('general.rec_type_tooltips.' + getType)}}</b-tooltip>
            </div>

            <div class="rec-metadata" v-if="showCoE" id="tooltip-coe">
              <div class="meta-label">{{$t('general.coe')}}</div>
              <coe :coe="recommendationLocale.rec_coe"></coe>
              <b-tooltip target="tooltip-coe" placement="bottom">{{$t('general.coe_tooltip')}}</b-tooltip>
            </div>

            <div class="rec-metadata warning"
                 v-if="isSuperseded"
                 v-on:click="ui.showHistory = !ui.showHistory"
                 v-b-tooltip.hover.bottom="$t('recommendation.superseded_tooltip')"
            >
              <div class="meta-label">{{$t('recommendation.history_message')}}</div>
              <div><strong>{{$t('recommendation.superseded')}}</strong></div>
            </div>


            <div class="float-right">
              <b-dropdown dropleft variant="link" toggle-class="text-decoration-none" no-caret>
                  <template #button-content>
                    <font-awesome-icon icon="ellipsis-h"/>
                  </template>
                  <b-dropdown-item @click="copyId(recommendationLocale.id)" id="guideline_id" class="">Copy reference ID <b-icon-files style="width: 12px"></b-icon-files></b-dropdown-item>
                  <b-dropdown-item target="_blank" :href="'https://epistemonikos.org/documents/' + recommendationLocale.id">{{$t('general.see_episte')}}</b-dropdown-item>
                  <b-dropdown-item target="_blank" :href="'https://app.iloveevidence.com/references/' + recommendationLocale.id + '/info'">{{$t('general.see_love')}}</b-dropdown-item>
                  <b-dropdown-item v-if="false" target="_blank" v-on:click="ui.showAdditionalInfo = !ui.showAdditionalInfo">dev info</b-dropdown-item>
                </b-dropdown>
            </div>
          </div>
        </div>

        <div :class="{'superseded': isSuperseded}" class="row ">
          <div class="col">
            <div class="arec_fulltext rec_fulltext">{{recommendationLocale.abstract}}</div>
          </div>
          <div class="col" style="max-width: 50px;" v-if="recommendationLocale.translation_status != 'blocked'">
            <span class="mx-2">
              <img src="../assets/images/translate_black.svg"
                   alt="pending-translation"
                   v-b-tooltip.hover.left="$t('general.pending_translation')"
               >
            </span>
          </div>
        </div>

        <div :class="{'superseded': isSuperseded}" class="row rec-row">
          <div class="col-3 rec-label">
            <div>{{$t('general.year')}}</div>
          </div>
          <div class="col-9 rec-value">
            <div>{{recommendationLocale.year}}</div>
          </div>
        </div>

        <div :class="{'superseded': isSuperseded}" class="row rec-row">
          <div class="col-3 rec-label">
            <div>{{$t('guideline.institution')}}</div>
          </div>
          <div class="col-9 rec-value">
            <div>{{translatedInstitution}}</div>
          </div>
        </div>

        <div :class="{'superseded': isSuperseded}" class="row rec-row" v-if="recommendationLocale.categories.internal">
          <div class="col-3 rec-label">
            <div>{{$tc('general.sdg3_goals', recommendationLocale.categories.internal.length)}}</div>
          </div>
          <div class="col-9 rec-value">
            <div>{{getSDG3Goals(recommendationLocale)}}</div>
          </div>
        </div>

        <div :class="{'superseded': isSuperseded}" class="row rec-row" v-if="(recommendationLocale.guidelines || []).length">
          <div class="col-3 rec-label">
            <div>{{$tc('general.guideline', 1)}}</div>
          </div>

          <div class="col-9 rec-value">
          <div>
            <div class="guideline-title">
              {{recommendationLocale.guidelines[0].title}}
            </div>
            <div class="see-more-container">
              <router-link :to="{ name: 'Guideline', params: { lang: $i18n.locale, id: recommendationLocale.guidelines[0].id }}">
                <b-button variant="primary" size="xs" class="see-more">{{$t('general.show_more')}}</b-button>
              </router-link>
            </div>
          </div>
          </div>
        </div>

        <div :class="{'superseded': isSuperseded}" class="row rec-row" v-if="(recommendationLocale.guidelines || []).length">
          <div class="col-3 rec-label">
            <div>{{$t('general.fulltext')}}</div>
          </div>

          <div class="col-9 rec-value">
            <div>
              <div class="d-inline-block"><a :href="fulltextLink" target="blank">{{fulltextLink}}<font-awesome-icon icon="external-link-alt" class="ml-1"/></a></div>

            </div>

          </div>
        </div>

        <div class="row mt-5" v-if="recommendation.history.latest_version && recommendation.history.thread && recommendation.history.thread.length > 0">
          <div class="col-12">
            <h3>{{$t('recommendation.thread_message')}}</h3>
          </div>
          <div class="col-12">
            <guideline-box :guideline="rec.guidelines[0]" v-for="rec in recommendation.history.thread" :key="rec.id"></guideline-box>
          </div>
        </div>

        <div class="row rec-row mt-5" v-if="(recommendation.history.newer_versions || []).length > 0 || (recommendation.history.older_versions || []).length > 0">
          <div class="col-12 history-box" :class="{'warning': isSuperseded}">

            <div class="row">
              <div class="col history-header" v-on:click="ui.showHistory = !ui.showHistory">
                <strong>{{$t('recommendation.history_message')}}</strong><template v-if="isSuperseded">: {{$t('recommendation.superseded')}}</template>
                <span class="float-right">
                  <font-awesome-icon v-if="!ui.showHistory" icon="plus" />
                  <font-awesome-icon v-else icon="minus"/>
                </span>
              </div>
            </div>

            <div class="row mt-3" v-if="ui.showHistory && (recommendation.history.newer_versions || []).length > 0">
              <div class="col-12">
                <strong class="">{{$t('recommendation.newer_versions')}}</strong>
              </div>
              <div class="col-12">
                <recommendation-box
                    v-for="rec in recommendation.history.newer_versions"
                    :key="rec.id"
                    :recommendation="rec"
                    :showYear="true"
                    :showInstitution="true"
                    :showGuideline="true"
                ></recommendation-box>
              </div>
            </div>

            <div class="row mt-3" v-if="ui.showHistory && (recommendation.history.older_versions || []).length > 0">
              <div class="col-12">
                <strong class="">{{$t('recommendation.older_versions')}}</strong>
              </div>
              <div class="col-12">
                <recommendation-box
                    v-for="rec in recommendation.history.older_versions"
                    :key="rec.id"
                    :recommendation="rec"
                    :showYear="true"
                    :showInstitution="true"
                    :showGuideline="true"
                ></recommendation-box>
              </div>
            </div>


          </div>
        </div>


        <template v-if="ui.showAdditionalInfo">

          <div class="row">
            <div class="col-12">
              <strong>{{$t('general.history')}}</strong>
            </div>
            <div class="col-12">
              {{recommendationLocale.history}}
            </div>
          </div>


          <hr>
          <div class="row rec-row">
            <div class="col-3 rec-label">
              <div>{{$tc('general.population', 1)}}</div>
            </div>
            <div class="col-9 rec-value">
              <div>{{recommendationLocale.population}}</div>
            </div>
          </div>

          <div class="row rec-row">
            <div class="col-3 rec-label">
              <div>{{$tc('general.intervention', 1)}}</div>
            </div>
            <div class="col-9 rec-value">
              <div>{{recommendationLocale.intervention}}</div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-12">
              <strong>{{$tc('general.population', 2)}} [LOVE]</strong>
            </div>
            <div class="col-12">
              <category v-for="category of hierarchyView['population']" :category="category" :key="category.path" :showIds="false"/>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <strong>{{$tc('general.intervention', 2)}} [LOVE]</strong>
            </div>
            <div class="col-12">
              <category v-for="category of hierarchyView['treatment']" :category="category" :key="category.path" :showIds="false"/>
            </div>
          </div>

        </template>
      </template>

      <template v-if="$mq == 'mobile'">
        <div class="row first-row">
          <div class="col-12 top-metadata">

            <div class="rec-metadata" v-show="getType" id="tooltip-type">
              <span class="meta-label">{{$t('general.rec_strength')}}:</span>
              <strength :type="recommendation.rec_type" :direction="recommendation.rec_direction"></strength>
              <b-tooltip target="tooltip-type" placement="bottom">{{$t('general.rec_type_tooltips.' + getType)}}</b-tooltip>
            </div>

            <div class="rec-metadata" v-if="showCoE" id="tooltip-coe">
              <div class="meta-label">{{$t('general.coe')}}</div>
              <coe :coe="recommendationLocale.rec_coe"></coe>
              <b-tooltip target="tooltip-coe" placement="bottom">{{$t('general.coe_tooltip')}}</b-tooltip>
            </div>

            <div class="rec-metadata warning"
                 v-if="isSuperseded"
                 v-on:click="ui.showHistory = !ui.showHistory"
                 v-b-tooltip.hover.bottom="$t('recommendation.superseded_tooltip')"
            >
              <div class="meta-label">{{$t('recommendation.history_message')}}</div>
              <div><strong>{{$t('recommendation.superseded')}}</strong></div>
            </div>

          </div>
        </div>

        <div :class="{'superseded': isSuperseded}" class="row rec_fulltext">
          <div class="col-12 text-right">
            <img src="../assets/images/translate_black.svg"
                 alt="pending-translation"
                 v-if="recommendationLocale.translation_status != 'blocked'"
                 v-b-tooltip.hover.left="$t('general.pending_translation')"
             >
         </div>
          <div class="col-12">
            <div class="arec_fulltext">{{recommendationLocale.abstract}}</div>
          </div>
        </div>

        <div :class="{'superseded': isSuperseded}" class="row rec-row">
          <div class="col-12 rec-label">
            <div>{{$t('general.year')}}</div>
          </div>
          <div class="col-12 rec-value">
            <div>{{recommendationLocale.year}}</div>
          </div>
        </div>

        <div :class="{'superseded': isSuperseded}" class="row rec-row">
          <div class="col-12 rec-label">
            <div>{{$t('guideline.institution')}}</div>
          </div>
          <div class="col-12 rec-value">
            <div>{{translatedInstitution}}</div>
          </div>
        </div>

        <div :class="{'superseded': isSuperseded}" class="row rec-row" v-if="recommendationLocale.categories.internal">
          <div class="col-12 rec-label">
            <div>{{$tc('general.sdg3_goals', recommendationLocale.categories.internal.length)}}</div>
          </div>
          <div class="col-12 rec-value">
            <div>{{getSDG3Goals(recommendationLocale)}}</div>
          </div>
        </div>

        <div :class="{'superseded': isSuperseded}" class="row rec-row" v-if="(recommendationLocale.guidelines || []).length">
          <div class="col-12 rec-label">
            <div>{{$tc('general.guideline', 1)}}</div>
          </div>

          <div class="col-12 rec-value">
          <div>
            <div class="">
              {{recommendationLocale.guidelines[0].title}}
            </div>
            <div class="see-more-container">
              <router-link :to="{ name: 'Guideline', params: { lang: $i18n.locale, id: recommendationLocale.guidelines[0].id }}">
                <b-button variant="primary" size="xs" class="see-more">{{$t('general.show_more')}}</b-button>
              </router-link>
            </div>
          </div>
          </div>
        </div>

        <div :class="{'superseded': isSuperseded}" class="row rec-row" v-if="(recommendationLocale.guidelines || []).length">
          <div class="col-12 rec-label">
            <div>{{$t('general.fulltext')}}</div>
          </div>

          <div class="col-12 rec-value">
            <div>
              <div class="d-inline-block"><a :href="fulltextLink" target="blank">{{fulltextLink}}<font-awesome-icon icon="external-link-alt" class="ml-1"/></a></div>

            </div>

          </div>
        </div>

        <div class="row mt-5" v-if="recommendation.history.latest_version && recommendation.history.thread && recommendation.history.thread.length > 0">
          <div class="col-12">
            <h3>{{$t('recommendation.thread_message')}}</h3>
          </div>
          <div class="col-12">
            <guideline-box :guideline="rec.guidelines[0]" v-for="rec in recommendation.history.thread" :key="rec.id"></guideline-box>
          </div>
        </div>

        <div class="row rec-row mt-5" v-if="(recommendation.history.newer_versions || []).length > 0 || (recommendation.history.older_versions || []).length > 0">
          <div class="col-12 history-box" :class="{'warning': isSuperseded}">

            <div class="row">
              <div class="col history-header" v-on:click="ui.showHistory = !ui.showHistory">
                <strong>{{$t('recommendation.history_message')}}</strong><template v-if="isSuperseded">: {{$t('recommendation.superseded')}}</template>
                <span class="float-right">
                  <font-awesome-icon v-if="!ui.showHistory" icon="plus" />
                  <font-awesome-icon v-else icon="minus"/>
                </span>
              </div>
            </div>

            <div class="row mt-3" v-if="ui.showHistory && (recommendation.history.newer_versions || []).length > 0">
              <div class="col-12">
                <strong class="">{{$t('recommendation.newer_versions')}}</strong>
              </div>
              <div class="col-12">
                <recommendation-box
                  v-for="rec in recommendation.history.newer_versions"
                  :key="rec.id"
                  :recommendation="rec"
                  :showYear="true"
                  :showInstitution="true"
                  :showGuideline="true"
                ></recommendation-box>
              </div>
            </div>

            <div class="row mt-3" v-if="ui.showHistory && (recommendation.history.older_versions || []).length > 0">
              <div class="col-12">
                <strong class="">{{$t('recommendation.older_versions')}}</strong>
              </div>
              <div class="col-12">
                <recommendation-box
                  v-for="rec in recommendation.history.older_versions"
                  :key="rec.id"
                  :recommendation="rec"
                  :showYear="true"
                  :showInstitution="true"
                  :showGuideline="true"
                ></recommendation-box>
              </div>
            </div>


          </div>
        </div>


        <template v-if="ui.showAdditionalInfo">

          <div class="row">
            <div class="col-12">
              <strong>{{$t('general.history')}}</strong>
            </div>
            <div class="col-12">
              {{recommendationLocale.history}}
            </div>
          </div>


          <hr>
          <div class="row rec-row">
            <div class="col-3 rec-label">
              <div>{{$tc('general.population', 1)}}</div>
            </div>
            <div class="col-9 rec-value">
              <div>{{recommendationLocale.population}}</div>
            </div>
          </div>

          <div class="row rec-row">
            <div class="col-3 rec-label">
              <div>{{$tc('general.intervention', 1)}}</div>
            </div>
            <div class="col-9 rec-value">
              <div>{{recommendationLocale.intervention}}</div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-12">
              <strong>{{$tc('general.population', 2)}} [LOVE]</strong>
            </div>
            <div class="col-12">
              <category v-for="category of hierarchyView['population']" :category="category" :key="category.path" :showIds="false"/>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <strong>{{$tc('general.intervention', 2)}} [LOVE]</strong>
            </div>
            <div class="col-12">
              <category v-for="category of hierarchyView['treatment']" :category="category" :key="category.path" :showIds="false"/>
            </div>
          </div>

        </template>
      </template>
    </div>
    <div v-else>
      <div class="la-ball-atom la-2x mx-auto"><div v-for="n in 4" :key="n"></div></div>
    </div>
  </div>
</template>

<script>

// import VirtualList from 'vue-virtual-scroll-list'

import Api from '../utils/Api'
import References from '../utils/References'
import Taxonomy from '../utils/Taxonomy'
import Strength from './utils/Strength'
import CoE from './utils/CoE'
import RecommendationBox from './RecommendationBox'
import GuidelineBox from './GuidelineBox'
import Category from './Category'

export default {
  name: 'Recommendations',
  created: function () {
    this.loadRecommendation()
  },
  data () {
    return {
      recommendation: null,
      ui: {
        showNotes: false,
        showHistory: false,
        showAdditionalInfo: false
      }
    }
  },
  methods: {
    loadRecommendation: function () {
      Api.get('/recommendations/' + this.$route.params.id).then(response => {
        this.recommendation = response.data.recommendation
        this.ui.showHistory = this.isSuperseded
        // this.recommendation.guidelines = this.recommendation.guidelines.map(guid => References.getLocale(guid, this.$i18n.locale))
        // console.log(this.recommendation)
      })

    },
    toggleNotes: function () {
      this.ui.showNotes = !this.ui.showNotes
    },
    getSDG3Goals: function (recommendation) {
      let tmp = (recommendation.categories.internal || [])
      .filter( s => Object.keys(this.$t('general.sdg3_targets')).includes(s.id))  // category SDG3
      .map(s => this.$t('general.sdg3_targets.' + s.id))
      .join(', ')
      return tmp
    },
    copyId: function (id) {
      let $tempInput = document.createElement('INPUT')
      let $body = document.getElementsByTagName('body')[0]
      $body.appendChild($tempInput)
      $tempInput.setAttribute('value', id)
      $tempInput.select()
      document.execCommand('copy')
      $body.removeChild($tempInput)
      this.copied = true
     },
  },
  computed: {
    isSuperseded: function () {
      return this.recommendation.history.latest_version == false
    },
    showCoE: function () {
      let validCoE = ['very low', 'low', 'moderate', 'high']
      return validCoE.includes((this.recommendation.rec_coe || '').toLowerCase().trim())
    },
    fulltextLink: function () {
      try {
        return this.recommendationLocale.guidelines[0].ext_links[0].link
      } catch(e) {
        console.log('ER:RC:NGL')
        return null
      }
    },
    getType: function () {
      let rtype = (this.recommendation.rec_type || '').toLowerCase().trim().replaceAll('\n', ' ').replaceAll(' ', '_').replaceAll('-', '_');
      let validTypes = [
        'strong',
        'conditional',
        'recommended',
        'not_recommended',
        'research_context_recommendation',
        'context_specific_recommendation',
        'recommended_in_the_context_of_targeted_monitoring_and_evaluation',
        'mec_category_1',
        'mec_category_2',
        'mec_category_3',
        'mec_category_4',
      ];
      if (validTypes.includes(rtype)){
        return rtype
      }
      return false
    },
    hierarchyView: function () {
      return {
        population: Taxonomy.hierarchyView((this.recommendation.categories || {}).population || []),
        treatment: Taxonomy.hierarchyView((this.recommendation.categories || {}).treatment || [])
      }
    },
    recommendationLocale: function () {
      return References.getLocale(this.recommendation, this.$i18n.locale)
    },
    translatedInstitution: function () {
      let institution_key = 'general.institutions.' + this.getInstitution
      let institution = this.$t(institution_key)
      if (institution_key == institution) {
        return this.getInstitution
      }
      return institution
    },
    getInstitution: function () {
      let possible_patterns = {
        WHO: [
          /WHO/i,
          /OMS/i,
          /^(?=.*\bworld\b)(?=.*\bhealth\b)(?=.*\borganization\b)/i,
          /^(?=.*\borganizaci[ó|o]n\b)(?=.*\bmundial\b)(?=.*\bsalud\b)/i
        ],
        PAHO: [
          /PAHO/i,
          /OPS/i,
          /^(?=.*\bpan[\s]american\b)(?=.*\bhealth\b)(?=.*\borganization\b)/i,
          /^(?=.*\borganizaci[ó|o]n\b)(?=.*\bpan[\s]americana\b)(?=.*\bsalud\b)/i
        ]
      };
      for (var institution of Object.keys(possible_patterns)) {
        for (var possible_pattern of possible_patterns[institution]) {
          try {
            var result = this.recommendation.guidelines[0].author[0].match(possible_pattern);
            if (result) {
              return institution;
            }
          } catch(e) {
            "pass"
          }
        }
      }
      return this.recommendation.guidelines[0].author[0] || "unset"
    }
  },
  watch: {
    '$route': function(){
        this.loadRecommendation();
    }
  },
  components: {
    // 'virtual-list': VirtualList,
    'guideline-box': GuidelineBox,
    'recommendation-box': RecommendationBox,
    'category': Category,
    'strength': Strength,
    'coe': CoE
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  // .tooltip-inner{
  //   min-width: 200px !important;
  // }
// .mobile {
//   .tooltip .tooltip-inner{
//     max-width: 500px !important;
//     width: 400px !important;
//   }
// }
</style>
<style lang="scss" scoped>
@import "../assets/styles/variables.scss";
@import "../assets/styles/loading.scss";
.loading-container {
  text-align: center;
  .la-ball-circus {
    display: inline-block;
    height: 7px;
    width: 14px;
    margin-left: 15px;
    margin-top: 7px;
  }
}
.sticky {
  position: sticky;
  top: 10px;
  z-index: 100;
}
.la-ball-atom  {
  color: $green-font;
  margin: 100px;
}
.uppercase {
  text-transform: uppercase;
}

.main {
  color: $dark-grey-font;
  .superseded {
    color: $txt-grey;
  }
  .first-row {
    margin-top: 5em;
  }

  &.mobile {
    .first-row {
      margin-top: 2em;
    }
    .rec-metadata {
      margin-bottom: 1em;
    }
    .top-metadata {
      margin-bottom: 1rem;
    }
    .rec-row {
      margin-bottom: 1em;
    }
    .see-more-container {
      display: block;
      margin: 0.7em 0;
    }
    .rec-value {
      word-break: break-word;
    }
  }

  .top-metadata {
    margin-bottom: 3rem;
    .rec-metadata {
      float: left;
      margin-right: 20px;
      padding: 7px 15px;
      background-color: $badge-light-blue;
      &.warning {
        background-color: $warning;
        cursor: pointer;
      }
      border-radius: 15px;

      .meta-label {
      }
      .meta-value {
        margin-left: 5px;
        padding: 12px;
        background-color: $badge-light-blue;
      }
    }
  }
  .rec-row {
    margin-bottom: 5px;
    .rec-label, .rec-value {
      > div{
        border-radius: 5px;
        padding: 5px 20px;
      }
    }
    .rec-label > div{
      // margin-right: 3px;
      font-weight: bolder;
      background-color: $badge-blue;
    }
    .rec-value > div{
      background-color: $badge-light-blue;
      .guideline-title {
        display: inline-block;
        width: calc(100% - 120px);
        vertical-align: middle;
      }
      .see-more-container {
        display: inline-block;
        width: 110px;
        .see-more {
          background-color: $ops-portal;
          height: 25px;
          padding: 2px 14px;
          color: $white;
          font-size: 85%;
          border-radius: 10px;
          // min-width: 100px;
        }
      }
    }
    .history-box {
      .history-header {
        &:hover {
          // background-color: #D5EEFF;
        }
        cursor: pointer;
      }
      .history-card {
      }

        padding: 7px 10px;
        margin-bottom: 1.5em;
      border-radius: 10px;
      &.warning {
        background-color: $warning;
      }
      background-color: $badge-light-blue;
    }
  }


  .title {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: white;
    height: 75px;
    padding-top: 1%;
    padding-bottom: 6%;
    margin-bottom: 2%;
  }
  .rec_fulltext {
    font-size: 1.2rem;
    margin-bottom: 30px !important;
    padding: 1rem !important;
    border: 2px solid $ops_portal;
    border-radius: 15px;
  }
  .notes {
    cursor: pointer;
  }
}

</style>
