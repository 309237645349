<template>
  <div>
    <p>
      El portal de recomendaciones GRADE de la OPS/OMS para el ODS-3 tiene como objetivo
      recopilar todas las recomendaciones clínicas, de salud pública y de políticas de
      salud emitidas en las guías de la OMS y la OPS que siguen el enfoque
      <a href="https://www.gradeworkinggroup.org/" target="_target"
        >GRADE<font-awesome-icon icon="external-link-alt" class="ml-1" /></a
      >. Es un repositorio de acceso libre en la Biblioteca Virtual en Salud (<a
        href="https://bvsalud.org/es/"
        target="_blank"
        >BVS<font-awesome-icon icon="external-link-alt" class="ml-1" /></a
      >) que permite a los tomadores de decisiones identificar recomendaciones relevantes
      para su pregunta de interés. Esta base de datos proporciona a los usuarios un medio
      eficiente para buscar y encontrar orientación en función de sus preguntas de
      interés, con funciones integradas de búsqueda, filtrado y tabulación cruzada,
      incluida la organización según las metas del tercer objetivo de desarrollo
      sostenible (ODS-3). Utilizamos el formato “población, intervenciones, los
      comparadores y desenlaces” ampliamente aceptado, para mapear y catalogar las
      recomendaciones. Uno de sus principales objetivos es permitir la toma de decisiones
      de los trabajadores de la salud y los tomadores de decisiones, contextualizada a
      nivel local, provincial, regional y jurisdiccional mediante la adopción o adaptación
      de estas recomendaciones.
    </p>
    <p>
      Esta plataforma se alimentará de los aportes continuos de los desarrolladores de
      guías que alentamos y de los usuarios y, por lo tanto, sus funciones seguirán
      mejorando y se actualizarán periódicamente para mantener la relevancia.
    </p>
    <p>
      Es un producto de la colaboración entre la
      <a href="https://www.paho.org/es" target="_blank"
        >Organización Panamericana de la Salud<font-awesome-icon
          icon="external-link-alt"
          class="ml-1"
      /></a>
      and
      <a href="https://es.epistemonikos.cl/" target="_blank"
        >Fundación Epistemonikos<font-awesome-icon icon="external-link-alt" class="ml-1"
      /></a>
      disponible en la Biblioteca Virtual en Salud (<a
        href="https://bvsalud.org/es/"
        target="_blank"
        >BVS<font-awesome-icon icon="external-link-alt" class="ml-1" /></a
      >).
    </p>

    <h2>Enlaces relacionados:</h2>
    <ul>
      <li>
        Organización Panamericana de la Salud
        <br />
        <a href="https://www.paho.org/es" target="_blank"
          >https://www.paho.org/es<font-awesome-icon
            icon="external-link-alt"
            class="ml-1"
        /></a>
      </li>
      <li>
        Biblioteca Virtual en Salud
        <br />
        <a href="https://bvsalud.org/es" target="_blank"
          >https://bvsalud.org/es<font-awesome-icon icon="external-link-alt" class="ml-1"
        /></a>
      </li>
      <li>
        BIGG – International Database of GRADE Guidelines
        <br />
        <a href="https://sites.bvsalud.org/bigg/biblio" target="_blank"
          >https://sites.bvsalud.org/bigg/biblio/<font-awesome-icon
            icon="external-link-alt"
            class="ml-1"
        /></a>
      </li>
      <li>
        GRADE Working Group
        <br />
        <a href="https://www.gradeworkinggroup.org" target="_blank"
          >https://www.gradeworkinggroup.org<font-awesome-icon
            icon="external-link-alt"
            class="ml-1"
        /></a>
      </li>
      <li>
        Fundación Epistemonikos
        <br />
        <a href="https://es.epistemonikos.cl/" target="_blank"
          >https://es.epistemonikos.cl/<font-awesome-icon
            icon="external-link-alt"
            class="ml-1"
        /></a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
