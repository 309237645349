<template>
  <EsSDGPopover v-if="$i18n.locale == 'es'" />
  <PtSDGPopover v-else-if="$i18n.locale == 'pt'" />
  <FrSDGPopover v-else-if="$i18n.locale == 'fr'" />
  <EnSDGPopover v-else />
</template>

<script>
import EnSDGPopover from "../translations/sdg-popover/enSDGPopover.vue";
import EsSDGPopover from "../translations/sdg-popover/esSDGPopover.vue";
import FrSDGPopover from "../translations/sdg-popover/frSDGPopover.vue";
import PtSDGPopover from "../translations/sdg-popover/ptSDGPopover.vue";

export default {
  components: { EsSDGPopover, PtSDGPopover, FrSDGPopover, EnSDGPopover },

  name: "SDG3Popover",
  props: ["placement"],
  computed: {},
};
</script>
