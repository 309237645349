<template>
  <div class="hierarchy-el"
      :class="{'highlight': category.highlight}"
      :style="{'padding-left': category.indent * 25 + 'px'}">
    <div class="metadatum-name"
          >
        <strong v-if="showIds">[ID: {{category.id}}]</strong>
        <span class="name">{{ category.name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Category',
  components: {
  },
  data () {
    return {
    }
  },
  props: [
    'category',
    'showIds'
  ],
  methods: {
    printCategory (category) {
      console.log(JSON.parse(JSON.stringify(category)))
    }
  },
  computed: {
    is_admin: function () {
      return ((this.$store.state.account || {}).api_roles || []).includes('admin')
    }
  }
}
</script>

<style lang="scss">
.badge {
  cursor: pointer;
}
</style>
<style lang="scss" scoped>
  @import "../assets/styles/variables.scss";

  .hierarchy-el {
    min-height: 30px;
    line-height: 30px;
    color: $txt;
    &.highlight .name {
      background-color: $highlight;
    }
    .metadatum-name {
      .metadata-buttons {
        display: none;
        cursor: pointer;
        // opacity: 0;
        .badge {
          margin-left: 4px;
        }
      }
      &.selected {
        color: $headercolor;
        margin-bottom: -2px;
        border-bottom: 2px solid $headercolor;
      }
      &:hover {
        .metadata-buttons {
          // opacity: 1;
          display: inline-block;
        }
        color: #2D8885
      }
      &.no-references {
        color: #868686;
      }
      &.highlighted {
        background-color: #FFFF7F;
      }
    }
  }

</style>
