<template>
  <b-popover
    target="sdg3-popover"
    triggers="hover"
    placement="bottomleft"
    container="body"
    class="sdg3-popover"
  >
    <template #title>
      <img
        class="sdg3-logo"
        :src="require(`@/assets/images/UN_SDG3_${$i18n.locale}.png`)"
        alt="sdg3-logo"
      />
    </template>
    <span class="static-content">
      <h2>Objectifs de développement durable (ODD)</h2>
      <p>
        Les
        <a
          href="https://www.un.org/sustainabledevelopment/sustainable-development-goals/"
          target="_blank"
          >objectifs de développement durable
          <font-awesome-icon icon="external-link-alt" class="ml-1"
        /></a>
        sont un appel universel à l'action pour mettre fin à la pauvreté, protéger la
        planète et améliorer la vie et les perspectives de chacun, partout. Les 17
        objectifs ont été adoptés par tous les États membres de l'ONU en 2015, dans le
        cadre du
        <a
          href="https://www.un.org/sustainabledevelopment/development-agenda/"
          target="_blank"
          >programme de développement durable à l'horizon 2030
          <font-awesome-icon icon="external-link-alt" class="ml-1"
        /></a>
        qui établit un plan sur 15 ans pour atteindre les objectifs.
      </p>

      <h2>Troisième objectif de développement durable (ODD-3)</h2>
      <p>
        Le troisième objectif de développement durable est de permettre à tous de vivre en
        bonne santé et de promouvoir le bien-être de tous à tout âge. En savoir plus (<a
          href="https://www.un.org/sustainabledevelopment/health/"
          target="_blank"
          >https://www.un.org/sustainabledevelopment/health/
          <font-awesome-icon icon="external-link-alt" class="ml-1" /></a
        >)
      </p>
    </span></b-popover
  >
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.popover {
  h2 {
    margin-top: initial;
    font-weight: bold;
    font-size: 90%;
  }
  p {
    font-size: 90%;
    line-height: initial;
  }
}
</style>
