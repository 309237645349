<template>
  <div class="row">
    <div class="col-12">
      <div class="grey-box">
        <div class="row title-row">
          <div class="col-12">
            <router-link :to="{ name: 'Guideline', params: { lang: $i18n.locale, id: guidelineLocale.id }}">
              <span>
                {{guidelineLocale.title}}
              </span>
            </router-link>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-2 col-3">
            <strong>{{$t('general.year')}}:</strong>
          </div>
          <div class="col-lg-10 col-9">
             {{guidelineLocale.year}}
          </div>
        </div>

        <div class="row" v-if="guidelineLocale.author.length > 0">
          <div class="col-lg-2 col-3">
            <strong>{{$t('guideline.institution')}}:</strong>
          </div>
          <div class="col-lg-10 col-9">
            {{guidelineLocale.author[0]}}
          </div>
        </div>


        <div class="row">
          <div class="col-lg-2 col-3">
            <strong> {{$t("guideline.ext_links")}} </strong>
          </div>
          <div class="col-lg-10 col-9">
            <a v-for="link in getLinks"
                 :key="link.name"
                 :href="link.link"
                 target="_blank">
                 {{link.name || link.link}}
                 <font-awesome-icon icon="external-link-alt" class="mr-1"/>
               </a>
          </div>
        </div>

        <div class="row justify-content-end">
          <div class="col-auto">
            <router-link :to="{ name: 'Guideline', params: { lang: $i18n.locale, id: guideline.id }}">
              <b-button variant="primary" size="xs" class="see-more">{{$t('general.view_guideline')}}</b-button>
            </router-link>

          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import References from '../utils/References'

export default {
  name: 'GuidelineBox',
  components: {
  },
  props: ['guideline'],
  data () {
    return {
    }
  },
  computed: {
    guidelineLocale: function () {
      return References.getLocale(this.guideline, this.$i18n.locale)
    },
    getLinks: function () {
      if (!this.guideline.ext_links) {
        return this.guideline.ext_links.concat(this.guideline.links)
      }
      let uniqBy = function(a, key) {
          var seen = {};
          return a.filter(function(item) {
              var k = key(item);
              return Object.prototype.hasOwnProperty.call(seen, k) ? false : (seen[k] = true);
          })
      }
      return uniqBy(this.guideline.ext_links, el => el.link)
    },

  }
}
</script>

<style lang="scss">
@import "../assets/styles/variables.scss";
</style>
<style lang="scss" scoped>
@import "../assets/styles/variables.scss";
.grey-box {
  color: black !important;
  font-size: 0.8em;
  a {
    color: inherit;
  }
  .title-row {
    font-size: 1.1rem;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    font-weight: bold;
    color: $box-font-color;
  }
   .bottom-row {
    font-size: 0.9rem;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
  }
  .see-more {
    background-color: $ops-portal;
    height: 30px;
    padding: 2px 14px;
    color: $white;
    font-size: 90%;
    border-radius: 10px;
    // min-width: 100px;
    &:hover {
      background-color: $ops-portal-hover;
    }
  }

}
</style>
