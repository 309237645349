<template>
  <div class="row">
    <div class="col-12">
      <div class="rec-box" :class="{'mobile': $mq == 'mobile'}">

        <template v-if="$mq != 'mobile'">

          <div class="row">
            <div class="col-12 col-lg-1 text-right order-lg-2" v-if="recommendationLocale.translation_status != 'blocked'">
              <span style="width: 20px;" >
                <img src="../assets/images/translate_black.svg"
                     alt="pending-translation"
                     v-b-tooltip.hover.left="$t('general.pending_translation')"
                 >
              </span>
            </div>
            <div class="col-12 col-lg-11 order-lg-1">
              <span>
                {{recommendationLocale.abstract}}
              </span>
            </div>
          </div>

          <div class="row metadata-row">
            <div class="col">
              <div class="row" v-if="showYear">
                <div class="col-auto meta-label">
                  <div>{{$t('general.year')}}:</div>
                </div>
                <div class="col meta-value">
                  <div>{{recommendationLocale.year}}</div>
                </div>
              </div>

              <div class="row" v-if="guidelineLocale && showGuideline">
                <div class="col-auto meta-label">
                  <div>{{$tc('general.guideline', 1)}}:</div>
                </div>
                <div class="col meta-value">
                  <div>
                    {{guidelineLocale.title || '-'}}
                    <span v-if="guidelineLocale.title" class="d-block">
                      <router-link :to="{ name: 'Guideline', params: { lang: $i18n.locale, id: guidelineLocale.id }}">
                        <b-button variant="primary" size="xs" class="see-more">{{$t('general.view_guideline')}}</b-button>
                      </router-link>
                    </span>
                  </div>
                </div>
              </div>

              <div class="row" v-if="showInstitution">
                <div class="col-auto meta-label">
                  <div>{{$t('guideline.institution')}}:</div>
                </div>
                <div class="col meta-value">
                  <div v-if="recommendationLocale.guidelines.length > 0">
                    {{translatedInstitution}}
                  </div>
                  <div v-else>-</div>
                </div>
              </div>

            </div>
          </div>


          <div class="row metadata-row justify-content-end">
            <div class="col-auto align-self-end" >
              <span class="meta-value strength-box" v-if="getType">
                <div v-b-tooltip.hover.left="$t('general.rec_type_tooltips.' + getType)">

                  <strength :type="recommendation.rec_type" :direction="recommendation.rec_direction"></strength>
                </div>
              </span>
            </div>

            <div class="col-auto align-self-end">
              <span class="">
                <div>

                <router-link :to="{ name: 'Recommendations', params: { lang:$i18n.locale, id: recommendationLocale.id }}">
                  <b-button variant="primary" size="xs" class="see-more">{{$t('general.show_more')}}</b-button>
                </router-link>
                </div>

              </span>
            </div>
          </div>
        </template>

        <template v-if="$mq == 'mobile'">

          <div class="row">
            <div class="col-12 text-right" v-if="recommendationLocale.translation_status != 'blocked'">
              <img src="../assets/images/translate_black.svg"
                   alt="pending-translation"
                   v-b-tooltip.hover.bottom="$t('general.pending_translation')"
               >
            </div>
            <div class="col-12">
              <span>
                {{recommendationLocale.abstract}}
              </span>
            </div>
          </div>

          <div class="row metadata-row">
            <div class="col">
              <div class="row" v-if="showYear">
                <div class="col-12 meta-label">
                  <div>{{$t('general.year')}}:</div>
                </div>
                <div class="col-12 meta-value">
                  <div>{{recommendationLocale.year}}</div>
                </div>
              </div>

              <div class="row" v-if="guidelineLocale && showGuideline">
                <div class="col-12 meta-label">
                  <div>{{$tc('general.guideline', 1)}}:</div>
                </div>
                <div class="col-12 meta-value">
                  <div>
                    {{guidelineLocale.title || '-'}}
                    <span v-if="guidelineLocale.title" class="d-block">
                      <router-link :to="{ name: 'Guideline', params: { lang: $i18n.locale, id: guidelineLocale.id }}">
                        <b-button variant="primary" size="xs" class="see-more">{{$t('general.view_guideline')}}</b-button>
                      </router-link>
                    </span>
                  </div>
                </div>
              </div>

              <div class="row" v-if="showInstitution">
                <div class="col-12 meta-label">
                  <div>{{$t('guideline.institution')}}:</div>
                </div>
                <div class="col-12 meta-value">
                  <div v-if="recommendationLocale.guidelines.length > 0">
                    {{translatedInstitution}}
                  </div>
                  <div v-else>-</div>
                </div>
              </div>

            </div>
          </div>

          <div class="row metadata-row">
            <div class="col-auto align-self-end mb-2">
              <span class="meta-value strength-box" v-if="getType">
                <div v-b-tooltip.hover.top="$t('general.rec_type_tooltips.' + getType)">

                  <strength :type="recommendation.rec_type" :direction="recommendation.rec_direction"></strength>
                </div>
              </span>
            </div>
          </div>

          <div class="row metadata-row justify-content-end">
            <div class="col-auto align-self-end">
              <span class="">
                <div>
                  <router-link :to="{ name: 'Recommendations', params: { lang: $i18n.locale, id: recommendationLocale.id }}">
                    <b-button variant="primary" size="xs" class="see-more">{{$t('general.show_more')}}</b-button>
                  </router-link>
                </div>

              </span>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>

</template>

<script>
import References from '../utils/References'
import Strength from './utils/Strength'

export default {
  name: 'RecommendationBox',
  components: {
    'strength': Strength,
  },
  props: ['recommendation', 'showGuideline', 'showInstitution', 'showYear'],
  data () {
    return {
    }
  },
  computed: {
    translatedInstitution: function () {
      let institution_key = 'general.institutions.' + this.getInstitution
      let institution = this.$t(institution_key)
      if (institution_key == institution) {
        return this.getInstitution
      }
      return institution
    },
    guidelineLocale: function () {
      try {
        return References.getLocale(this.recommendation.guidelines[0], this.$i18n.locale)
      } catch(e) {
        // statements
        return {}
      }
    },
    recommendationLocale: function () {
      return References.getLocale(this.recommendation, this.$i18n.locale)
    },
    getType: function () {
      let rtype = (this.recommendation.rec_type || "").toLowerCase().trim().replace('\n', ' ').replace(' ', '_').replace('-', '_');
      let validTypes = [
        'strong',
        'conditional',
        'recommended',
        'not_recommended',
        'research_context_recommendation',
        'context_specific_recommendation',
        'recommended_in_the_context_of_targeted_monitoring_and_evaluation'
      ];
      if (validTypes.includes(rtype)){
        return rtype
      }
      return null
    },
    getInstitution: function () {
      let possible_patterns = {
        WHO: [
          /WHO/i,
          /OMS/i,
          /^(?=.*\bworld\b)(?=.*\bhealth\b)(?=.*\borganization\b)/i,
          /^(?=.*\borganizaci[ó|o]n\b)(?=.*\bmundial\b)(?=.*\bsalud\b)/i
        ],
        PAHO: [
          /PAHO/i,
          /OPS/i,
          /^(?=.*\bpan[\s]american\b)(?=.*\bhealth\b)(?=.*\borganization\b)/i,
          /^(?=.*\borganizaci[ó|o]n\b)(?=.*\bpan[\s]americana\b)(?=.*\bsalud\b)/i
        ]
      };
      for (var institution of Object.keys(possible_patterns)) {
        for (var possible_pattern of possible_patterns[institution]) {
          try {
            var result = this.recommendation.guidelines[0].author[0].match(possible_pattern);
            if (result) {
              return institution;
            }
          } catch(e) {
            'pass'
          }
        }
      }
      return this.recommendation.guidelines[0].author[0] || "-"
    }
  }
}
</script>

<style lang="scss">
@import "../assets/styles/variables.scss";
.strength-box {
  .label {
    max-width: 200px;
  }
}


</style>
<style lang="scss" scoped>
@import "../assets/styles/variables.scss";
// .tooltip{
//   .tooltip-inner{
//     min-width: inherit !important;
//   }
// }
.rec-box {
  background-color: white;
  a {
    color: inherit;
  }
  border: 2px solid $rec-box-border;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 10px;
  color: $black;
}
.metadata-row {
  margin-top: 1em;
  font-size: 0.9rem;
  .meta-label {
    min-width: 30px;
  }
  .meta-label div{
    padding: 2px 8px;
    width: 100%;
    min-width: 100px;
    border-radius: 10px;
    font-weight: bold;
  }
  .meta-value div{
    padding: 2px 8px;
    width: 100%;
    border-radius: 10px;
  }
  .strength-box div{
    min-width: 165px;
    background-color: $badge-light-blue;
  }
  .see-more {
    background-color: $ops-portal;
    height: 30px;
    padding: 2px 14px;
    color: $white;
    font-size: 90%;
    border-radius: 10px;
    // min-width: 100px;
    &:hover {
      background-color: $ops-portal-hover;
    }
  }
}
</style>
