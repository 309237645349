<template>
  <div class="container static-content">
    <es-privacy-policy v-if="$i18n.locale == 'es'" />
    <pt-privacy-policy v-else-if="$i18n.locale == 'pt'" />
    <fr-privacy-policy v-else-if="$i18n.locale == 'fr'" />
    <en-privacy-policy v-else />
  </div>
</template>

<script>
import EnPrivacyPolicy from "./translations/privacyPolicy/enPrivacyPolicy.vue";
import EsPrivacyPolicy from "./translations/privacyPolicy/esPrivacyPolicy.vue";
import FrPrivacyPolicy from "./translations/privacyPolicy/frPrivacyPolicy.vue";
import PtPrivacyPolicy from "./translations/privacyPolicy/ptPrivacyPolicy.vue";
export default {
  name: "References",
  components: { EnPrivacyPolicy, EsPrivacyPolicy, PtPrivacyPolicy, FrPrivacyPolicy },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables.scss";
@import "../assets/styles/typography.scss";
// h2 {
//   margin: auto;
//   text-align: center;
//   vertical-align: middle;
//   padding: 5px;
// }
// p {
//   margin: auto;
//   text-align: center;
//   vertical-align: middle;
// }
// img {
//   background-color: $white;
// }
</style>
