<template>
  <div>
    <h1>POLÍTICA DE PRIVACIDAD DE LOS SITIOS Y SISTEMAS WEB DE BIREME/OPS</h1>
    <p>
      La utilización de los productos, servicios y/o herramientas que pone a disposición
      El Centro Latinoamericano y del Caribe de Información en Ciencias de la Salud
      (BIREME), Centro Especializado de la Organización Panamericana de la Salud, Oficina
      Regional para las Américas de la Organización Mundial de la Salud, en adelante
      BIREME/OPS, a disposición de los usuarios constituye aceptación de la siguiente
      Política de Privacidad. En caso de discrepancia entre el texto en español y
      cualquier otro idioma de esta Política de Privacidad, prevalecerá el documento en
      español.
    </p>

    <p>
      Esta Política de Privacidad es complementada por la
      <a
        href="https://www.paho.org/hq/index.php?option=com_content&view=article&id=3201:paho-website-privacy-policy&Itemid=2410&lang=es"
        target="_blank"
        >Política General de Privacidad de la OPS
        <font-awesome-icon icon="external-link-alt" class="ml-1" /></a
      >.
    </p>

    <h1>PRIVACIDAD</h1>
    <p>
      Su privacidad es de suma importancia para BIREME/OPS. En esta política de privacidad
      se describen las prácticas de BIREME/OPS en cuanto a la recopilación y el
      intercambio de información de los usuarios del presente sitio de BIREME/OPS.
    </p>

    <h2>¿Qué tipo de información recopila BIREME/OPS?</h2>
    <h3>Uso de sitios web</h3>

    <p>
      En general, puede consultar los sitios web de BIREME / OPS sin decir quién es. La
      información recopilada durante una consulta general proviene de los registros
      ordinarios del servidor e incluye su dirección IP (protocolo de Internet), nombre de
      dominio, tipo de navegador, sistema operativo e información sobre cómo nos llegó,
      los archivos que descargó, los sitios web que ha consultado y la fecha y hora de
      esas consultas. Los datos demográficos también se pueden recopilar durante una
      consulta si está autenticado en un servicio de terceros con permiso para compartir
      su edad, sexo y categoría de interés.
    </p>

    <h3>Recopilación de información que permite identificar a la persona</h3>

    <p>
      Si usted se registra para acceder a algún sitio, producto o servicio de BIREME/OPS,
      como los Servicios Personalizados Mi BVS y el FI-Admin, se le pedirá que suministre
      información personal como su nombre, dirección de correo electrónico, institución a
      la cual pertenece, país de origen, nivel académico, área de actuación profesional,
      sexo, fecha de nacimiento e datos de sus redes sociales. Esta información se
      recopila solo con su conocimiento y permiso, y se mantiene en las bases de datos de
      BIREME/OPS y listas de direcciones.
    </p>

    <p>
      Para ciertos sitios de BIREME/OPS, registrarse o proporcionar cierta información
      personal hace que se instale una cookie* en su computadora, que permitirá que
      BIREME/OPS recuerde su información la próxima vez que usted consulte el sitio, de
      manera que no tenga que volver a ingresar sus datos, lo que nos ayuda a brindarle un
      servicio mejor.
    </p>

    <p>
      Si se incorpora a un grupo de debate electrónico puede que otros participantes del
      grupo (incluidas personas externas a la OPS) tengan acceso ala información personal
      que usted haya proporcionado. En el caso de los grupos de debate abiertos, esta
      información será pública.
    </p>

    <h2>¿Qué hace BIREME/OPS con la información que recopila?</h2>
    <p>
      La información recopilada durante una consulta general se utiliza para analizar las
      tendencias y el uso de los sitios web de BIREME/OPS, así como para mejorar la
      utilidad de esos sitios web.
    </p>

    <h3>Información que permite identificar a la persona</h3>

    <h3>
      BIREME/OPS puede usar la información personal que usted proporciona, entre otros,
      para:
    </h3>

    <ul>
      <li>
        ponerse en contacto con usted: ya sea para responder a una consulta o sugerencia,
        o para enviarle boletines informativos, documentos, publicaciones, etc.;
      </li>
      <li>confirmar su información de registro en el sitio;</li>
      <li>"recordar" su perfil y preferencias en línea;</li>
      <li>
        ayudarlo a encontrar rápidamente información útil según sus intereses y ayudarnos
        a crear un contenido que le resulte más provechoso;
      </li>
      <li>realizar un análisis estadístico.</li>
    </ul>

    <h2>¿Qué sucede si no quiero suministrar información personal?</h2>

    <p>
      Suministrar información personal en los sitios de BIREME/OPS es voluntario. Si
      decide no suministrar información personal, puede de todas formas consultar y usar
      los sitios de BIREME/OPS, pero no podrá utilizar ciertas opciones como acceder a los
      Servicios Personalizados Mi BVS o hacer gestión de las fuentes de información de la
      BVS a través del sistema web FI-Admin.
    </p>

    <h3>Opción de modificar la información o solicitar que sea eliminada</h3>

    <p>
      En cualquier momento, usted puede modificar su información o solicitar que sea
      eliminada: para ello debe ingresar nuevamente al sitio donde ingresó la información
      por primera vez y comunicarse con el punto focal de ese sitio. Si no se proporcionan
      datos de contacto en el sitio, puede contactar a bir.online@paho.org.
    </p>

    <h1>SEGURIDAD</h1>
    <p>
      Toda la información proporcionada a BIREME / OPS por los usuarios de los sitios web
      de BIREME/OPS es mantenida con sumo cuidado y seguridad, y no será utilizada de
      manera diferente a las establecidas por esta política de privacidad, en ninguna de
      las políticas propias del sitio web, o en las que usted autorizó explícitamente.
    </p>

    <p>
      Todos nuestros empleados que tienen acceso a los datos personales y están asociados
      con su procesamiento están obligados a respetar la confidencialidad de los asuntos
      oficiales, incluidos los datos personales.
    </p>

    <p>
      Los sitios de BIREME/OPS contienen enlaces a sitios externos. BIREME/OPS no es
      responsable de las prácticas de privacidad ni del contenido de tales sitios.
    </p>

    <h2>Notificación de cambios</h2>
    <p>
      BIREME/OPS podrá modificar esta Política de Privacidad a su entera discreción y
      publicará estas modificaciones en el presente sitio.
    </p>

    <h1>Contacto</h1>
    <p>
      Para preguntas o consultas con respecto a esta política de privacidad, sírvase
      comunicarse con nosotros en bir.online@paho.org
    </p>

    <p>* Cookie</p>

    <p>
      Una cookie es un pequeño conjunto de datos que se envía de un servidor de la web a
      su navegador. Se usa normalmente para asignar una identificación única a su
      computadora y almacenar de manera segura información como el ID del usuario,
      contraseñas, preferencias y perfiles electrónicos. Se almacena en el disco duro de
      su computadora. Usted tiene la opción de no permitir que se instalen cookies de la
      OPS/OMS si cambia la configuración de su navegador. Distintos sitios web pueden
      enviar sus propias cookies a su computadora. Para proteger su privacidad, el
      navegador solo permite que un sitio web obtenga acceso a sus propias cookies y no
      permite el acceso a las instaladas por otros sitios.
    </p>

    <strong
      >SI NO ESTUVIERA CONFORME CON ALGUNO DE LOS TÉRMINOS Y CONDICIONES DE ESTA POLÍTICA
      DE PRIVACIDAD DEBERÁ ABSTENERSE DE UTILIZAR EL SITIO Y CUALQUIER PRODUCTO, SERVICIO
      Y/O HERRAMIENTA AQUÍ CONTENIDO.</strong
    >
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/variables.scss";
@import "../../../assets/styles/typography.scss";
// h2 {
//   margin: auto;
//   text-align: center;
//   vertical-align: middle;
//   padding: 5px;
// }
// p {
//   margin: auto;
//   text-align: center;
//   vertical-align: middle;
// }
// img {
//   background-color: $white;
// }
</style>
