<template>
  <div class="col-md-12 mb-4">
    <button
      size="sm"
      :class="{'collapsed':!ui.showSelector, 'disabled-menu': disabled, 'selected': criteria.selectedMetadata.length}"
      :aria-expanded="ui.showSelector ? 'true' : 'false'"
      aria-controls="collapse-4"
      class="collapsable-button"
      block
      :id="'criteria-builder-menu-' + listName"
    >
      <template v-if="!disabled">
        <span v-if="criteria.selectedMetadata.length">
          {{getCategoryName(criteria.selectedMetadata[0].id)}}
        </span>
        <span v-else class="font-weight-normal">
          {{defaultTitle}}
        </span>
      </template>
      <template v-else>Select intervention/variable</template>

      <span class="banner-button right mr-2" @click="toggleSelector()">
        <font-awesome-icon icon="chevron-up" v-if="ui.showSelector"/>
        <font-awesome-icon icon="chevron-down" v-else/>
      </span>

      <span class="banner-button right mr-2" :class="{'selected': ui.showFilter}" @click.stop.prevent="ui.showFilter = !ui.showFilter" v-show="ui.showSelector">
        <font-awesome-icon icon="search"/>
      </span>
    </button>
    <b-popover id="disabled-criteria-builder-menu" :disabled="!disabled" :delay="{show: 0, hide: 0}" :target="'criteria-builder-menu-' + listName" triggers="hover" placement="top">
      First select the type of question in the menu above
    </b-popover>
    <b-collapse id="collapse-4" class="filter-box" :visible="ui.showSelector">
      <b-card class="filter-card">
        <div class="relative" :class="{'hide-overflow': !ui.showingMore}">
          <!-- LIST -->
          <category-list v-if="showCategoryList"
                        :hideFilter="!ui.showFilter"
                        :collapseTaxonomyList="collapseTaxonomyList"
                        :criteria="criteria"
                        :filterCategoriesAndShowMore="filterCategoriesAndShowMore"
                        :forceShow="forceShow"
                        :listName="listName"
                        :removeFilter="removeFilter"
                        :selectCategory="selectCategory"
                        :showHiddenCategories="$store.getters.isAdmin"
                        :taxonomy="taxonomy"
                        :toggleElement="toggleElement"></category-list>
          <div v-if="!ui.showingMore && (shownCategories > 7 || forceShow)" class="show-more pointer" @click="showMore()">
            {{$t('pico_builder.show_more')}}
            <div><i class="fas fa-caret-down"></i></div>
          </div>
        </div>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import CategoryList from './CategoryList'
export default {
  name: 'CriteriaBuilder',
  props: [
    'applyFilter',
    'criteriaType',
    'collapseTaxonomyList',
    'criteria',
    'defaultTitle',
    'listName',
    'removeFilter',
    'selectCategory',
    'showCategoryList',
    'taxonomy',
    'toggleElement'
  ],
  data () {
    return {
      'ui': {
        'show': true,
        'showingMore': false,
        'showSelector': false,
        'showFilter': false
      }
    }
  },
  created: function () {
    this.ui.showingMore = true
    if (this.criteriaType !== 'any') {
      this.ui.showSelector = true
    }
  },
  methods: {
    toggleSelector: function () {
      if (!this.disabled) {
        this.ui.showSelector = !this.ui.showSelector
      }
    },
    showMore: function () {
      this.ui.showingMore = true
    },
    shownCategoriesFromBranch: function (path, element) {
      let count = 0
      if (element.visible_paths.includes(path) && element.show) {
        count = 1
        if (!element.collapsed) {
          for (const child of element.children) {
            count = count + this.shownCategoriesFromBranch(`${path}.${child.id}`, child)
          }
        }
      }
      return count
    },
    filterCategoriesAndShowMore: function (text, taxonomy) {
      this.applyFilter(text, taxonomy, this.criteria)
      this.ui.showingMore = true
    },
    getCategoryName: function (category_id) {
      let categories = {
        ...this.$t('general.sdg3_targets'), 
        ...this.$t('general.sdg3_populations'), 
        ...this.$t('general.sdg3_interventions')
      }
      return categories[category_id]
    } 
  },
  computed: {
    shownCategories: function () {
      let count = 0
      for (const child of this.taxonomy) {
        count = count + this.shownCategoriesFromBranch(child.id, child)
      }
      return count
    },
    forceShow: function () {
      for (const element of this.taxonomy) {
        if (element.show) {
          return false
        }
      }
      return true
    },
    disabled: function () {
      return this.criteriaType === 'any'
    }
  },
  components: {
    'category-list': CategoryList
  },
  watch: {
    'criteriaType': function () {
      if (this.criteriaType === 'any') {
        this.ui.showSelector = false
      } else if (this.criteriaType !== 'population') {
        this.ui.showSelector = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/styles/variables.scss";
  .filter-card {
    border-radius: 0px 0px 15px 15px;
    font-size: $psize;
    width: 100%;
    background-color: $badge-light-blue;
  }
  .collapsable-button {
    border-radius: 3px 3px 0px 0px;
    background: $headercolor;
    border: 1px solid $headercolor;
    color: #fff;
    font-weight: bold;
    width: 100%;
    height: 45px;
    margin-bottom: 0px;
    text-align: left;
    &.selected {
      background-color: $sdg3-main;
      border-color: $sdg3-main;
    }
    &:focus {
      outline: none;
    }
    &.collapsed {
      border-radius: 3px 3px 3px 3px;
    }
    &.disabled-menu {
      cursor: not-allowed;
      color: $txt;
      background-color: $line-box;
      border-color: $line-box;
    }
    .banner-button {
      border-radius: 6px;
      padding: 2px;
      text-align: center;
      height: 24px;
      width: 24px;
      &:hover, &.selected {
        background-color: $white;
        color: $sdg3-main;
      }
    }
  }
  .external-link {
    color: $green-font;
    font-weight: bold;
    &:hover {
      color: $txt;
    }
  }
  .show-more {
    position: absolute;
    width: 100%;
    height: 60px;
    background-color: #ECF9FFA0;
    bottom: 0px;
    text-align: center;
    padding-top: 20px;
    color: $green-font;
    line-height: 10px;
  }
  .hide-overflow {
    max-height: 200px;
    overflow: hidden;
  }
</style>
<style lang="scss">
  @import "../../assets/styles/variables.scss";
  #disabled-criteria-builder-menu {
    border-color: $headercolor;
    border-width: 2px;
    border-radius: 10px;
    .arrow {
      bottom: calc((1rem + 1px) * -1);
      width: 1rem;
      height: 1rem;
      margin: 0 -1rem;
      &:before {
        bottom: 0;
        border-width: 1rem 1rem 0;
        border-top-color: $headercolor;
      }
      &:after {
        bottom: 3px;
        border-width: 1rem 1rem 0;
      }
    }
  }
</style>
