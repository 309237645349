<template>
  <div>
    <p>
      La utilización de los productos, servicios y/o herramientas que pone a disposición
      El Centro Latinoamericano y del Caribe de Información en Ciencias de la Salud
      (BIREME), Centro Especializado de la Organización Panamericana de la Salud, Oficina
      Regional para las Américas de la Organización Mundial de la Salud, en adelante
      BIREME/OPS, a disposición de los usuarios constituye aceptación de los siguientes
      Términos y Condiciones. En caso de discrepancia entre el texto en español y
      cualquier otro idioma de estos Términos y Condiciones, prevalecerá el documento en
      español.
    </p>
    <p>
      BIREME/OPS se reserva el derecho a accionar legalmente para frenar cualquier
      infracción o incumplimiento de los presentes Términos y Condiciones. BIREME/OPS
      podrá modificar estos Términos y Condiciones a su entera discreción y publicará
      estas modificaciones en el presente sitio. Usar este sitio, los productos, servicios
      y/o herramientas luego de efectuados éstos cambios constituye aceptación por parte
      del usuario de los nuevos Términos y Condiciones. La OPS se reserva el derecho
      exclusivo de cancelar el acceso de cualquier usuario por infracción de derechos o
      incumplimiento de los presentes Términos y Condiciones.
    </p>
    <h2>Uso del nombre de BIREME y/o la OPS</h2>
    <p>
      El nombre, acrónimo y/o logo de BIREME y la OPS, están protegidos por el derecho
      internacional. Excepto para propósitos de atribución autoral éstos no pueden ser
      utilizados sin autorización previa expresa y por escrito de BIREME/OPS. El nombre,
      acrónimo, logo BIREME/ OPS tampoco pueden ser utilizados con fines promocionales o
      en ninguna forma que indique, sugiera o pueda interpretarse como asociación,
      afiliación, patrocinio o respaldo de BIREME/OPS.
    </p>
    <h2>Limitación de Responsabilidad</h2>
    <p>
      Los productos, servicios y/o herramientas que BIREME/OPS pone a disposición de los
      usuarios se proporcionan sin ningún tipo de garantía, explícita o implícita.
      BIREME/OPS no ofrece garantías ni responderá por la exactitud o veracidad de la
      información, productos, servicios y/o herramientas proporcionados. BIREME/ OPS podrá
      modificar éstos periódicamente sin previo aviso. Bajo ninguna circunstancia
      BIREME/OPS será responsable de las pérdidas, daños y/o perjuicios que puedan
      presuntamente derivarse de su utilización o su no disponibilidad. Los usuarios deben
      utilizar estos productos, servicios y/o herramientas por su propia cuenta y riesgo.
      En ningún caso BIREME/OPS será responsable por daños y/o perjuicios aún cuando éstos
      pudieran haber sido advertidos y/o prevenidos.
    </p>
    <p>
      BIREME/OPS se reserva el derecho de modificar o descontinuar cualquier información,
      productos, servicios y/o herramientas que ponga a disposición de sus miembros y/o
      usuarios a través de este sitio, con o sin previo aviso. La utilización de
      designaciones de países o territorios no deberán considerarse como indicativo de la
      posición por parte de BIREME/OPS con relación a la situación jurídica de ningún país
      o territorio, de sus autoridades e instituciones ni el reconocimiento de ninguna
      frontera.
    </p>
    <p>
      Las designaciones empleadas y la presentación del material en las publicaciones
      listadas en las bases de datos no implica la expresión de ninguna opinión por parte
      de BIREME/OPS sobre el estado legal de ningún país, territorio, ciudad o área o de
      sus autoridades, o referente a la delimitación de sus fronteras o límites. Las
      líneas punteadas y de trazos en los mapas representan líneas fronterizas aproximadas
      para las cuales aún no existe un acuerdo total.
    </p>
    <p>
      La mención de compañías específicas o de los productos de ciertos fabricantes en las
      publicaciones listadas en las bases de datos no implica que sean respaldados o
      recomendados por BIREME/OPS con preferencia a otros de naturaleza similar que no se
      mencionan. Salvo errores u omisiones, los nombres de los productos patentados se
      distinguen con letras mayúsculas iniciales.
    </p>
    <p>
      BIREME/OPS no será responsable de ningún daño o pérdida que pueda derivarse o
      relacionarse con el uso o falla del sitio, información, productos, servicios y/o
      herramientas aquí contenidos, incluso en los casos derivados de uso inapropiado,
      impropio o fraudulento. Tampoco será responsable de la precisión, disponibilidad o
      veracidad de la información aquí contenida.
    </p>
    <p>
      BIREME/OPS no ofrece garantías de ningún tipo respecto de los materiales,
      información, productos, servicios y/o herramientas disponibles en el sitio y no será
      responsable por cualquier infección con virus informático o cualquier otro programa
      que pueda afectar el funcionamiento de computadoras o sistemas.
    </p>
    <p>
      Algunos materiales, información, productos, servicios y/o herramientas a los cuales
      los usuarios podrán acceder a través de este sitio podrían ser de titularidad de
      terceros, la BIREME/OPS no garantiza la titularidad de éstos y no será responsable
      ante los usuarios o terceros por cualquier reclamo derivado de su uso. Es posible
      que en el sitio, materiales, información, productos, servicios y/o herramientas se
      incluyan enlaces a sitios externos no manejados por la BIREME/OPS. Estos enlaces se
      incluyen a modo de referencia y su incorporación no implica aprobación o endoso por
      parte de BIREME/OPS. BIREME/OPS no asume responsabilidad respecto de la información
      contenida en estos sitios.
    </p>
    <p>
      La utilización de este sitio por los usuarios implica la aceptación de la obligación
      de defender, mantener a salvo e indemnizar a BIREME/OPS y a sus afiliados por
      cualquier acción, reclamo daño, pérdida y/o gasto (incluidos los honorarios de
      abogados) que se deriven de dicha utilización.
    </p>
    <p>
      BIREME/OPS se reserva el derecho de utilizar o divulgar información sobre sus
      usuarios. Asimismo se reserva el derecho de editar, no publicar o remover cualquier
      producto, servicios, herramienta o información o material en parte o en su totalidad
      a su entera discreción.
    </p>
    <h2>Usos no permitidos</h2>
    <p>
      Los usuarios garantizan que el uso de del sitio, información, productos, servicios
      y/o herramientas aquí contenidos, no serán utilizados con propósitos que sean
      contrarios a las leyes o que contravengan los presentes términos y condiciones. Los
      usuarios no utilizaran el sitio, información, productos, servicios y/o herramientas
      de manera que pueda perjudicar, inhabilitar, sobrecargar o deteriorar el sitio,
      información, productos, servicios y/o herramientas o su uso por parte de terceros.
      Los usuarios no intentarán obtener ninguna información, productos, servicios y/o
      herramientas por medios no intencionalmente disponibles o habilitados en este sitio.
    </p>
    <p>
      Asimismo los usuarios se comprometen a respetar los derechos de terceros así como
      las leyes de propiedad intelectual durante cualquier uso de del sitio, información,
      productos, servicios y/o herramientas aquí contenidos.
    </p>
    <h2>Privilegios e Inmunidades</h2>
    <p>
      Nada de lo aquí expuesto constituirá o podrá ser interpretado como renuncia, expresa
      o tácita, de los privilegios, inmunidades, y exoneraciones de que goza, la OPS, de
      conformidad con el Derecho Internacional, tratados o convenios internacionales, o la
      legislación de sus Países Miembros.
    </p>
    <h2>Espacios de diálogo</h2>
    <p>
      BIREME/OPS podrá ofrecer espacios de diálogo u otros instrumentos de comunicación.
      Los usuarios al hacer uso de estos instrumentos se comprometen a abstenerse de:
    </p>
    <ol type="a">
      <li>
        Difamar, injuriar, acosar, amenazar o asumir cualquier otro tipo de conducta que
        vulnere los derechos de otros;
      </li>
      <li>
        Difundir, publicar, distribuir o divulgar informaciones o materiales difamatorios,
        obscenos, indecentes, ilícitos o inmorales;
      </li>
      <li>
        Cargar en el sitio o enviar como anexos de un mensaje archivos que contengan
        programas informáticos u otros materiales amparados por las leyes de propiedad
        intelectual (o por el derecho a la intimidad y la publicidad), sin contar con la
        autorización correspondiente;
      </li>
      <li>
        No incluir la referencia del autor o notificaciones legales, mentir acerca del
        origen o presentar como propio programas informáticos u otros materiales
        contenidos en un archivo que se cargue en el sitio;
      </li>
      <li>
        Anunciar o poner a la venta bienes o servicios, o realizar o promover encuestas,
        concursos o cadenas de cartas, o descargar de un foro un archivo enviado por otro
        usuario que el usuario sepa, o razonablemente deba saber, que no se puede
        distribuir lícitamente de esa manera.
      </li>
    </ol>
    <p>
      Los usuarios reconocen que todos los espacios de diálogo constituyen un medio de
      comunicación pública y no privada. Asimismo, reconocen que los diálogos y el
      material publicado en el sitio no cuentan con el respaldo de BIREME/OPS y no se
      considerarán como revisados, examinados ni aprobados por BIREME/OPS.
    </p>
    <h2>Reclamaciones por posibles infracciones</h2>
    <p>
      BIREME/OPS se esforzará por impedir en su sitio actividades ilícitas y contrarias a
      los Términos y Condiciones aquí contenidos. No obstante, si usted creyera que alguna
      información contenida en el sitio o sus materiales pudiera estar infringiendo sus
      derechos o de terceros, incluyendo, sin limitación, derechos de propiedad
      intelectual, deberá ponerse en contacto con la BIREME/OPS enviando una descripción
      detallada de la violación alegada a la siguiente dirección: bir.online@paho.org
    </p>
    <h2>Resolución de controversias</h2>
    <p>
      Cualquier disputa, controversia o reclamo que surja o se derive del uso del sitio o
      su información, productos, servicios y/o herramientas será resuelta en forma
      amistosa entre BIREME/OPS y usuario. A menos que las disputas o controversias se
      solucionen en forma amistosa dentro de los sesenta (60) días posteriores a la
      recepción por una de las partes de la solicitud de la otra de intentar una
      resolución amistosa, dicha disputa, controversia, o reclamo será remitida por
      cualquiera de las partes a arbitraje de conformidad con las Reglas de Arbitraje de
      la Comisión de las Naciones Unidas para el Derecho Mercantil Internacional (CNUDMI)
      vigentes. El tribunal arbitral no estará autorizado a imponer daños punitivos.
      Cualquier laudo arbitral emitido en virtud del arbitraje referido se considerará
      como la adjudicación definitiva a la disputa, controversia o reclamo y tendrá
      carácter vinculante para las partes.
    </p>
    <p>
      <strong
        >SI NO ESTUVIERA CONFORME CON ALGUNO DE LOS TÉRMINOS Y CONDICIONES AQUÍ CONTENIDOS
        DEBERÁ ABSTENERSE DE UTILIZAR EL SITIO Y CUALQUIER MATERIAL AQUÍ
        CONTENIDO.</strong
      >
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
