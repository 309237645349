<template>
  <div>
    <p>
      e portail de recommandations GRADE de l'OPS/OMS pour l'ODD-3 vise à recueillir
      toutes les recommandations pour la clinique, la santé publique et la politique de
      santé émises par les directives de l'OMS et de l'OPS qui suivent l'approche
      <a href="https://www.gradeworkinggroup.org/" target="_target"
        >GRADE <font-awesome-icon icon="external-link-alt" class="ml-1" /></a
      >. C'est un référentiel librement accessible dans la Bibliothèque virtuelle de la
      santé (<a href="https://bvsalud.org/en/" target="_blank"
        >VHL<font-awesome-icon icon="external-link-alt" class="ml-1" /></a
      >) qui permet aux décideurs d'identifier les recommandations pertinentes pour leur
      question d'intérêt. Cette base de données offre aux utilisateurs un moyen efficace
      de rechercher et de trouver des orientations en fonction de leurs questions
      d'intérêt, avec des fonctions de recherche, de filtrage et de croisement de données
      intégrées, y compris l'organisation par les troisièmes objectifs de développement
      durable (ODD-3). Nous utilisons le cadre PICO (Population, Intervention, Comparaison
      et Objectifs) largement accepté pour cartographier et cataloguer les
      recommandations. L'un de ses objectifs principaux est de permettre une prise de
      décision contextualisée au niveau local, provincial, régional et juridictionnel pour
      les travailleurs de la santé et les décideurs en adoptant ou en adaptant ces
      recommandations.
    </p>
    <p>
      Cette plateforme dépendra de contributions continues des développeurs de directives,
      que nous encourageons, ainsi que des utilisateurs, et donc ses fonctionnalités
      continueront de s'améliorer et elle sera régulièrement mise à jour pour rester
      pertinente.
    </p>
    <p>
      C'est un produit de la collaboration entre
      <a href="https://www.paho.org/en" target="_blank"
        >l'Organisation panaméricaine de la santé<font-awesome-icon
          icon="external-link-alt"
          class="ml-1"
      /></a>
      et la
      <a href="https://foundation.epistemonikos.org/" target="_blank"
        >Fondation Epistemonikos<font-awesome-icon icon="external-link-alt" class="ml-1"
      /></a>
      disponible à la Bibliothèque virtuelle de la santé (<a
        href="https://bvsalud.org/en/"
        target="_blank"
        >BVS<font-awesome-icon icon="external-link-alt" class="ml-1" /></a
      >).
    </p>

    <h2>Liens connexes:</h2>
    <ul>
      <li>
        Pan-American Health Organization
        <br />
        <a href="https://www.paho.org/en" target="_blank"
          >https://www.paho.org/en<font-awesome-icon
            icon="external-link-alt"
            class="ml-1"
        /></a>
      </li>
      <li>
        Virtual Health Library
        <br />
        <a href="https://bvsalud.org/en" target="_blank"
          >https://bvsalud.org/en<font-awesome-icon icon="external-link-alt" class="ml-1"
        /></a>
      </li>
      <li>
        BIGG – International Database of GRADE Guidelines
        <br />
        <a href="https://sites.bvsalud.org/bigg/en/biblio/" target="_blank"
          >https://sites.bvsalud.org/bigg/en/biblio/<font-awesome-icon
            icon="external-link-alt"
            class="ml-1"
        /></a>
      </li>
      <li>
        GRADE Working Group
        <br />
        <a href="https://www.gradeworkinggroup.org" target="_blank"
          >https://www.gradeworkinggroup.org<font-awesome-icon
            icon="external-link-alt"
            class="ml-1"
        /></a>
      </li>
      <li>
        Epistemonikos Foundation
        <br />
        <a href="https://foundation.epistemonikos.org" target="_blank"
          >https://foundation.epistemonikos.org<font-awesome-icon
            icon="external-link-alt"
            class="ml-1"
        /></a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
