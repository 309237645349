import axios from 'axios'
// import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import Vue from 'vue'

import Api from '../utils/Api'
import VueScrollTo from 'vue-scrollto'
import PicoBrowse from './question_builder/PicoBrowse'
// import Method from './methods/GenericMethods'
import RecommendationBox from './RecommendationBox'
import platform_config from '../platform'
import VueToggles from 'vue-toggles';
import { mapMutations } from 'vuex'

// let idByType = {
//   'treatment': '5b1dcd8ae611de7ae84e8f14',
//   'diagnosis': '5b1dccf3e611de7ae84e8f12',
//   'predictive_factors': '5b1dcd0ee611de7ae84e8f13',
//   'utility_of_initial_evaluation': '5b1dcc6ce611de7ae84e8f11',
//   'prognosis': '5b1dcdbde611de7ae84e8f15'
// }

// let typesOfQuestion = [
//   'treatment',
//   'diagnosis',
//   'predictive_factors',
//   'utility_of_initial_evaluation',
//   'prognosis'
// ]



export default {
  data () {
    return {
      actualQuestion: {},
      ui: {
        activeTabIndex: 0,
        authorizedUser: false,
        backToTopVisible: false,
        hierarchyFilter: '',
        leftPanelExpanded: false,
        loadingPrisma: false,
        loadingReferences: false,
        searchMode: 'pico',
        showGuidelines: false,
        showFilters: false,
        showLeftPanel: true,
        showMobileQuestionBuilder: false,
        showTypeOfQuestionInfo: false
      },
      error: {
        account_search: null,
        delete_saved_question: null,
        get_love: null,
        last_visit: null,
        love_permissions: null,
        love_taxonomy: null,
        matrix: null,
        notifications: null,
        post_saved_questions: null,
        // prisma: null,
        recommendations: null,
        saved_questions: null,
        search_one: null,
        spreadsheet: null
      },
      lastVisit: null,
      recommendations: [],
      referencesNumber: 0,
      eventBus: new Vue(),
      currentPageReferences: 1,
      criteriaSelected: {},
      questionDomainSelected: {},
      taxonomy: [],
      tabIndex: 1,
      tabIndexSecond: 0,
      questionInfo: {},
      favorite: false,
      savedQuestion: null,
      studify: false,
      taxonomyDict: {},
      question_id: '',
      cancelSource: null,
      showTypeOfQuestionSelector: true,
      reloadNumbers: true,
      treeLoaded: false,
      criteria: [
        { value: 'population', name: 'Population', selectedMetadata: [] },
        { value: 'intervention', name: 'Treatment', selectedMetadata: [] },
        // { value: 'outcome', name: 'Outcome', selectedMetadata: [], disabled: true },
        { value: 'article_classification', name: 'Article classification', selectedMetadata: [], disabled: true },
        // { value: 'contextual_factors', name: 'Contextual factors', selectedMetadata: [], disabled: true },
        { value: 'intervention_variable', name: 'Intervention variable', selectedMetadata: [] },
        // { value: 'population_geography', name: 'Population geography', selectedMetadata: [], disabled: true }
        /*
        ,
        { value: 'comparison', name: 'Comparison', selectedMetadata: [] },
        */
      ],
      filtersOptions: {
        year: platform_config.filters.year,
        institution: platform_config.filters.institution,
        age: platform_config.filters.age,
      },
      filters: {
        year: null,
        institution: null,
        age: null,
        // automaticClassification: '',
        // classification: 'systematic-review',
        // hasAbstract: '',
        // reportedData: '',
        // sampleSizeOption: '',
        // sampleSizeOver: 0,
        query: ''
        // studyDesign: '',
        // others: ''
      },
      questionDomainOptions: [
        {value: 'any', name: 'All type of questions', meta_id: '', id: ''},
        {value: 'treatment', name: 'Prevention or treatment', meta_id: '5b1dcd8ae611de7ae84e8f14', id: '5b1dcd8ae611de7ae84e8f14'},
        {value: 'diagnosis', name: 'Diagnostic', meta_id: '5b1dccf3e611de7ae84e8f12', id: '5b1dccf3e611de7ae84e8f12'},
        {value: 'predictive_factors', name: 'Etiology', meta_id: '5b1dcd0ee611de7ae84e8f13', id: '5b1dcd0ee611de7ae84e8f13'},
        {value: 'utility_of_initial_evaluation', name: 'Epidemiology', meta_id: '5b1dcc6ce611de7ae84e8f11', id: '5b1dcc6ce611de7ae84e8f11'},
        {value: 'prognosis', name: 'Prognosis', meta_id: '5b1dcdbde611de7ae84e8f15', id: '5b1dcdbde611de7ae84e8f15'}
      ],
      typesOfQuestionActive: false,
      displayRecommendations: true
    }
  },
  created: function () {
    this.criteriaSelected = this.criteria[0]
    // this.drawTree()
    const promise = this.drawTree()
    promise.then(() => {
    //   // lovePromise.then(() => {
    //   //   // if (this.love.premium) {
    //   //   //   this.filters.classification = 'all'
    //   //   // }
    //   //   // if (!this.$route.query['question_domain'] && !this.love.premium) {
    //   //   //   this.questionDomainSelected = this.questionDomainOptions[1]
    //   //   //   this.changeHash()
    //   //   // }
    //   // })
      this.treeLoaded = true
      this.processRoute()
    })
    // this.processRoute()
    window.addEventListener('scroll', this.backToTopVisibility)
  },
  destroyed () {
    window.removeEventListener('scroll', this.backToTopVisibility)
  },
  mounted () {
    // TODO: Check if this is stil being used
    this.eventBus.$on('tree-item-click', this.treeItemClick)
    
  },
  methods: {
    mobileFilterClosed(){
      this.displayRecommendations = true
    },
    mobileFilterOpened(){
      window.scroll(0,0)
      this.displayRecommendations = false
    },
    /* Add selected categories and set default categories */
    processRoute: function () {
      if (this.$route.query['q']) {
        this.filters.query = this.$route.query['q']
      }
      if (this.$route.query['age']) {
        this.filters.age = this.$route.query['age']
      }
      if (this.$route.query['year']) {
        this.filters.year = this.$route.query['year']
      }
      if (this.$route.query['institution']) {
        this.filters.institution = this.$route.query['institution']
      }
      // if (this.$route.query['section'] === 'methods') {
      //   this.ui.activeTabIndex = 1
      // }
      // if (this.$route.query['question_domain']) {
      //   for (const index in this.questionDomainOptions) {
      //     if (this.questionDomainOptions[index].id === this.$route.query['question_domain']) {
      //       this.questionDomainSelected = this.questionDomainOptions[index]
      //     }
      //   }
      // } else {
      //   if (this.love.premium) {
      //     this.questionDomainSelected = this.questionDomainOptions[0]
      //   }
      //   if (!this.love.premium) {
      //     this.questionDomainSelected = this.questionDomainOptions[1]
      //   }
      // }
      for (const criteria of this.criteria) {
        for (const element of criteria.selectedMetadata) {
          element.selected = false
        }
        criteria.selectedMetadata = []
      }
      for (const criteria of this.criteria) {
        if (this.$route.query[criteria.value]) {
          for (const populationId of this.$route.query[criteria.value].split(',')) {
            let category = this.taxonomyDict[populationId]
            criteria.selectedMetadata.push(category)
            category.selected = true
            this.openBranch(category)
          }
        }
      }
      // if (this.$route.query['classification']) {
      //   this.filters.classification = this.$route.query['classification']
      // }
      // if (this.$route.query['has-abstract']) {
      //   this.filters.hasAbstract = this.$route.query['has-abstract']
      // }
      // if (this.$route.query['reported-data']) {
      //   this.filters.reportedData = this.$route.query['reported-data']
      // }
      // if (this.$route.query['sample-size-option']) {
      //   this.filters.sampleSizeOption = this.$route.query['sample-size-option']
      // }
      // if (this.$route.query['sample-size-over']) {
      //   this.filters.sampleSizeOver = this.$route.query['sample-size-over']
      // }
      // if (this.$route.query['search']) {
      //   this.filters.search = this.$route.query['search']
      // }
      // if (this.$route.query['study-design']) {
      //   this.filters.studyDesign = this.$route.query['study-design']
      // }
      // if (this.$route.query['others']) {
      //   this.filters.others = this.$route.query['others']
      // }
      // if (this.$route.query['studify'] && this.$route.query['studify'] === 'true' && this.checkStudify()) {
      //   this.studify = true
      // }
      // this.selectDefaultCategory()
      this.getRecommendations()
    },
    handleError: function (key, error) {
      if (error && error.toString() === 'Cancel') {
        this.error[key] = null
      } else if (error && error.response && error.response.status === 404) {
        this.error[key] = null
      } else if (error && error.response && error.response.data.message) {
        this.error[key] = error.response.data.message
      } else {
        this.error[key] = error
      }
    },
    clearSearch () {
      this.filters.query = ''
      this.changeHash()
      // if (this.searchByKeywords) {

      //   // this.getLovesData()
      // } else {
      //   this.getTopicsData()
      // }
    },
    clearFilters: function () {
      for (let criteria of this.criteria) {
        this.$set(criteria, 'selectedMetadata', [])
      }
      this.filters.query = ''
      this.filters.year = null
      this.filters.age = null
      this.filters.institution = null
      this.$root.$emit('clear-filters')
      this.reloadReferences()
    },
    changeHash: function () {
      let query = ''
      // if (this.questionDomainSelected.value !== 'any') {
      //   query = `question_domain=${this.questionDomainSelected.meta_id}&`
      // }
      for (const criterion of this.criteria) {
        if (criterion.selectedMetadata.length) {
          let stringIds = criterion.selectedMetadata.map((metadatum) => {
            return metadatum.id
          })
          query = `${query}${criterion.value}=${stringIds}&`
        }
      }
      // if (this.ui.activeTabIndex === 1) {
      //   query = `${query}section=methods&`
      // }
      // if (this.filters.automaticClassification) {
      //   query = `${query}automatic-classification=${this.filters.automaticClassification}&`
      // }
      // if (this.filters.classification) {
      //   query = `${query}classification=${this.filters.classification}&`
      // }
      // if (this.filters.hasAbstract) {
      //   query = `${query}has-abstract=${this.filters.hasAbstract}&`
      // }
      // if (this.filters.reportedData) {
      //   query = `${query}reported-data=${this.filters.reportedData}&`
      // }
      // if (this.filters.sampleSizeOption) {
      //   query = `${query}sample-size-option=${this.filters.sampleSizeOption}&`
      // }
      // if (this.filters.sampleSizeOver) {
      //   query = `${query}sample-size-over=${this.filters.sampleSizeOver}&`
      // }
      if (this.filters.query) {
        query = `${query}q=${this.filters.query}&`
      }
      if (this.filters.year) {
        query = `${query}year=${this.filters.year}&`
      }
      if (this.filters.age) {
        query = `${query}age=${this.filters.age}&`
      }
      if (this.filters.institution) {
        query = `${query}institution=${this.filters.institution}&`
      }
      // if (this.filters.studyDesign) {
      //   query = `${query}study-design=${this.filters.studyDesign}&`
      // }
      // if (this.filters.others) {
      //   query = `${query}others=${this.filters.others}&`
      // }
      // if (this.studify) {
      //   query = `${query}studify=true&`
      // }
      if (query.slice(-1) === '&') {
        query = query.slice(0, -1)
      }
      var tmp_query = this.$route.path
      if (query) {
        tmp_query = `${this.$route.path}?${query}`
      }
      if (this.$route.fullPath !== tmp_query) {
        this.$router.push({ path: tmp_query }).catch(error => { console.log(error) })
      }
    },
    /* Getting taxonomy from backend */
    drawTree () {
      let promise = new Promise(
        // () => {
        (resolve) => {
          let categories = [
            // ...this.currentTaxonomy.population,
            ...this.currentTaxonomy.treatment,
            ...platform_config.sdg3_goals.map(g => g.category_id)
          ]

          Api.post('/taxonomy/get-from-ids', {categories: categories}).then(response => {
            this.handleError('love_taxonomy', null)
            let items = response.data
            /* Taxonomy has all the elements of the taxonomy that are part of this LOVE */
            for (const element of items) {
              this.taxonomyDict[element.id] = element
              element.collapsed = true
              element.children = []
              element.parents = []
              element.selected = false
            }
            /* Calculating children */
            for (const element of items) {
              // element.parents_ids = []
              element.show = true
              for (const path of element.visible_paths) {
                let splittedPath = path.split('.')
                // if (splittedPath.length > 2) {
                //   console.log('REMOVE', element.id, element.name)
                // }
                if (splittedPath.length > 1) {
                  let parentId = splittedPath[splittedPath.length - 2]
                  if (this.taxonomyDict[parentId] && !this.taxonomyDict[parentId].children.includes(element)) {
                    this.taxonomyDict[parentId].children.push(element)
                    element.parents.push(this.taxonomyDict[parentId])
                    element.secondary = true
                  }
                }
              }
            }
            this.taxonomy = [...new Set(items)]
            this.taxonomy.map( t => t.children = t.children.sort(this.sortByName))
          }).catch(
            error => { this.handleError('love_taxonomy', error) }
          ).then(() => {
            resolve()
          })
        }
      )
      return promise
    },
    toggleElement: function (categoryId) {
      this.$set(this.taxonomyDict[categoryId], 'collapsed', !this.taxonomyDict[categoryId].collapsed)
    },
    selectCategory: function (event) {
      this.$set(this, 'selectedCategory', event)
    },
    sortByName: function (category1, category2) {
      try {
        // statements
        if (this.getCategoryTranslatedName(category1) < this.getCategoryTranslatedName(category2)) {
          return -1
        } else {
          return 1
        }
      } catch(e) {
        // statements
        return 1
      }
    },
    getCategoryTranslatedName: function (category) {
      let categories = {
        ...this.$t('general.sdg3_targets'),
        ...this.$t('general.sdg3_populations'),
        ...this.$t('general.sdg3_interventions')
      }
      return categories[category.id]
    },
    findMetadata: function (metadataId, metadataList) {
      for (let elem of metadataList) {
        if (elem.id === metadataId) {
          return elem
        }
        if (elem.children_path && elem.children_path.length) {
          let found = this.findMetadata(metadataId, elem.children_path)
          if (found) {
            return found
          }
        }
      }
      return false
    },
    filterSearch: function () {
      this.changeHash()
    },
    treeItemClick: function (id, criteria) {
      let category = this.taxonomyDict[id]

      // criteria = criteria.filter(c => c.value == criteria.type)[0]
      if (criteria === this.criteria[0]) {
        this.collapseTaxonomyList(this.shownPopulations)
      }
      if (criteria === this.criteria[1]) {
        this.collapseTaxonomyList(this.shownInterventions)
      }
      let selected = criteria.selectedMetadata || []
      if (selected.indexOf(category) === -1) {
        if (this.ui.allowMultipleSelection) {
          selected.push(category)
        } else {
          for (const element of selected) {
            this.$set(this.taxonomyDict[element.id], 'selected', false)
          }
          selected = [category]
        }
        category.selected = true
      } else {
        this.removeFromCriteria(criteria, category)
      }
      for (const element of selected) {
        this.openBranch(element)
      }
      this.$set(criteria, 'selectedMetadata', selected)
      this.changeHash()
      // this.reloadReferences()
      // Ese reloadReferences no es necesario dado un efecto de cadena que pasa, donde changeHash
      // cambia la ruta, lo que provoca que el watcher llame a processRoute y ahi se hace el llamado
      // a reloadReferences
      this.$set(this.taxonomyDict[id], 'collapsed', false)
    },
    openBranch: function (category) {
      for (const path of category.visible_paths) {
        for (const element of path.split('.')) {
          this.taxonomyDict[element].collapsed = false
        }
      }
    },
    changeCriteria: function (tabIndex = null) {
      if (tabIndex != null) {
        this.tabIndex = tabIndex
      }
      this.criteriaSelected = this.criteria[this.tabIndex]
      this.ui.hierarchyFilter = ''
    },
    changeSelection: function (source, target) {
      let sourceObject = this.findMetadata(source, this.taxonomy)
      let targetObject = this.findMetadata(target, this.taxonomy)
      const findCriteria = element => {
        for (const criteria of this.criteria) {
          let metadatumFound = criteria.selectedMetadata.find(metadatum => {
            return metadatum.id === element
          })
          if (metadatumFound) {
            return criteria
          }
        }
      }
      let criteria = findCriteria(source)
      let selected = criteria.selectedMetadata || []
      let index = selected.indexOf(sourceObject)
      selected.splice(index, 1)
      selected.push(targetObject)
      sourceObject.selected = false
      targetObject.selected = true
      this.$set(criteria, 'selectedMetadata', selected)
      this.changeHash()
      this.getRecommendations()
    },
    backToTop: function () {
      let top = this.$refs.top
      var options = {
        container: 'body',
        easing: 'ease-in',
        offset: -60,
        x: false,
        y: true
      }
      VueScrollTo.scrollTo(top, 500, options)
    },
    applyFilter: function (text, shownTaxonomy, criteria) {
      if (!text) {
        for (let element of shownTaxonomy) {
          element.highlighted = false
          this.closeBranch(element)
          this.showBranch(element)
        }
        for (const category of criteria.selectedMetadata) {
          this.openBranch(category)
        }
      } else {
        /* Hide all */
        for (const element of shownTaxonomy) {
          this.hideBranch(element)
        }
        /* Show matching elements and parents */
        for (const element of shownTaxonomy) {
          this.isIncluded(text, element)
        }
        /* Show siblings and children */
        for (const element of shownTaxonomy) {
          this.showHighlightedBranches(element)
          if (element.highlighted) {
            this.showBranch(element)
          }
        }
      }
    },
    isIncluded: function (text, element) {
      /* Check if element matches */
      let translated_name = this.getCategoryTranslatedName(element)
      // console.log(translated_name)
      if (translated_name && (translated_name.search(new RegExp(text, 'i')) !== -1 || element.long_name.search(new RegExp(text, 'i')) !== -1)) {
      // if (element.name && (element.name.search(new RegExp(text, 'i')) !== -1 || element.long_name.search(new RegExp(text, 'i')) !== -1)) {
        this.$set(element, 'show', true)
        this.$set(element, 'highlighted', true)
        this.$set(element, 'collapsed', !element.show)
      }
      for (const child of element.children) {
        let isIncluded = this.isIncluded(text, child)
        /* Check if any of children is shown */
        if (isIncluded) {
          this.$set(element, 'show', true)
          this.$set(element, 'collapsed', false)
        }
      }
      return element.show
    },
    hideBranch: function (branch) {
      this.$set(branch, 'show', false)
      this.$set(branch, 'collapsed', true)
      this.$set(branch, 'highlighted', false)
      for (const element of branch.children) {
        this.hideBranch(element)
      }
    },
    closeBranch: function (branch) {
      this.$set(branch, 'collapsed', true)
      for (const element of branch.children) {
        this.closeBranch(element)
      }
    },
    showHighlightedBranches: function (branch) {
      let showSiblings = false
      for (const child of branch.children) {
        if (child.highlighted) {
          showSiblings = true
        }
      }
      if (showSiblings) {
        for (const child of branch.children) {
          this.showBranch(child)
        }
      }
    },
    showBranch: function (branch) {
      this.$set(branch, 'show', true)
      for (const child of branch.children) {
        this.showBranch(child)
      }
    },
    changeSelectedFilters: function (classification, studyDesign = '', reportedData = '', others = '') {
      if (classification) {
        this.filters.classification = classification
      }
      this.filters.studyDesign = studyDesign
      if (!this.checkStudify()) {
        this.studify = false
      }
      this.filters.reportedData = reportedData
      this.filters.others = others
      this.filters.sampleSizeOption = ''
      this.filters.sampleSizeOver = 0
      this.filters.hasAbstract = ''
      this.changeHash()
      this.reloadReferencesWithoutReloadingNumbers()
    },
    reloadReferences: function () {
      // if (!this.checkStudify()) {
      //   this.studify = false
      // }
      this.changeHash()
      this.currentPageReferences = 1
      this.getRecommendations(this.currentPageReferences)
    },
    reloadReferencesWithoutReloadingNumbers: function () {
      this.currentPageReferences = 1
      this.reloadNumbers = false
      this.getRecommendations(this.currentPageReferences)
    },
    getReferencesAndScrollToTop: function (page = 1) {
      var options = {
        container: 'body',
        easing: 'ease-in',
        offset: -60,
        x: false,
        y: true
      }
      VueScrollTo.scrollTo('#app', 100, options)
      this.getRecommendations(page)
    },
    getRecommendations: function (page = 1) {
      // Cancel previous requests
      if (this.cancelSource) {
        this.cancelSource.cancel()
      }
      const CancelToken = axios.CancelToken
      this.cancelSource = CancelToken.source()
      const cancelToken = this.cancelSource.token

      // Request recommendations
      this.ui.loadingReferences = true
      // if (this.lastVisit) {
      //   params['last_visit'] = this.lastVisit
      // }
      let referencesParams = this.preparedReferencesParams
      referencesParams['page'] = page
      referencesParams['sort'] = 'year'
      // referencesParams.show_summary = true
      // referencesParams.show_alternative_hits = true
      // referencesParams.show_screened_total = true

      Api.post('/recommendations_search', referencesParams, cancelToken).then(response => {
        this.handleError('recommendations', null)
        this.recommendations = response.data.items
        this.referencesNumber = response.data.total
      }).catch((error) => {
        this.handleError('recommendations', error)
      }).then(() => {
        this.ui.loadingReferences = false
        this.reloadNumbers = true
      })

      // Favorites
      // let questionDomain = this.questionDomainSelected.meta_id
      // if ((questionDomain || this.criteria[0].selectedMetadata.length || this.criteria[1].selectedMetadata.length) && this.$store.getters.isLoggedIn) {
      //   let categories = []
      //   if (questionDomain) {
      //     categories.push(questionDomain)
      //   }
      //   for (const element of this.criteria) {
      //     if (element.selectedMetadata && element.selectedMetadata.length) {
      //       for (const metadatum of element.selectedMetadata) {
      //         categories.push(metadatum.id)
      //       }
      //     }
      //   }
      //   // let _params = {'size': 1}
      //   // _params.categories = categories
      //   // _params.love_id = this.$route.params.id
      //   // this.favorite = false
      //   // Api.post(`/accounts/${this.$store.state.account._id}/saved_questions/search`, _params, cancelToken).then(response => {
      //   //   this.handleError('account_search', null)
      //   //   this.favorite = response.data.total > 0
      //   //   this.question_id = this.favorite ? response.data.items[0].id : ''
      //   //   this.savedQuestion = response.data.items[0]
      //   // }).catch((error) => {
      //   //   this.handleError('account_search', error)
      //   // })
      //   // if (this.$store.getters.isAdmin) {
      //   //   Api.post(`/saved_questions/search_one`, _params, cancelToken).then(response => {
      //   //     this.handleError('search_one', null)
      //   //     this.actualQuestion = response.data
      //   //   }).catch((error) => {
      //   //     this.actualQuestion = {}
      //   //     this.handleError('search_one', error)
      //   //   })
      //   // }
      // }
    },
    disabledCriteriaFilter: function (item) {
      return (item.value === 'comparison' && (!this.criteria[1].selectedMetadata || !this.criteria[1].selectedMetadata.length))
    },
    removeFilter: function (criteria, metadatum) {
      this.removeFromCriteria(criteria, metadatum)
      this.changeHash()
      this.getRecommendations()
    },
    collapseTaxonomyList: function (taxonomyList) {
      for (const element of taxonomyList) {
        this.taxonomyDict[element.id].collapsed = true
        this.collapseTaxonomyList(element.children)
      }
    },
    removeFromCriteria: function (criteria, metadatum) {
      let selected = criteria.selectedMetadata
      const index = selected.indexOf(metadatum)
      metadatum.selected = false
      if (index !== -1) {
        selected.splice(index, 1)
        // if (!selected.length && criteria.value === this.taxonomyDict[this.love.category_id].type) {
        //   selected.push(this.taxonomyDict[this.love.category_id])
        //   this.taxonomyDict[this.love.category_id].selected = true
        // }
      }
      this.$set(criteria, 'selectedMetadata', selected)
    },
    clearInterventionFilters: function () {
      this.$set(this.criteria[1], 'selectedMetadata', [])
      // this.selectDefaultCategory()
    },
    // selectDefaultCategory: function () {
    //   if (this.taxonomyDict[this.love.category_id].type === 'population') {
    //     if (!this.criteria[0].selectedMetadata.length) {
    //       this.criteria[0].selectedMetadata.push(this.taxonomyDict[this.love.category_id])
    //       this.taxonomyDict[this.love.category_id].selected = true
    //     }
    //   } else if (typesOfQuestion.includes(this.taxonomyDict[this.love.category_id].type)) {
    //     if (!this.criteria[1].selectedMetadata.length) {
    //       this.criteria[1].selectedMetadata.push(this.taxonomyDict[this.love.category_id])
    //       this.taxonomyDict[this.love.category_id].selected = true
    //     }
    //   } else if (this.taxonomyDict[this.love.category_id].type === 'outcome') {
    //     if (!this.criteria[2].selectedMetadata.length) {
    //       this.criteria[2].selectedMetadata.push(this.taxonomyDict[this.love.category_id])
    //       this.taxonomyDict[this.love.category_id].selected = true
    //     }
    //   }
    // },
    backToTopVisibility: debounce(function () {
      this.ui.backToTopVisible = window.scrollY > 200
    }, 100),
    getMetadataParams: function () {
      let questionDomain = ''
      let populations = []
      let interventions = []
      if (this.questionDomainSelected.value !== 'any') {
        questionDomain = this.questionDomainSelected.meta_id
        for (const element of this.criteria) {
          if (element.value === 'population') {
            if (element.selectedMetadata && element.selectedMetadata.length) {
              populations = element.selectedMetadata.map(metadatum => {
                return metadatum.id
              })
            }
          }
          if (element.value === 'intervention') {
            if (element.selectedMetadata && element.selectedMetadata.length) {
              interventions = element.selectedMetadata.map(metadatum => {
                return metadatum.id
              })
            }
          }
        }
      }
      let params = {}
      if (questionDomain) {
        params['type_of_question'] = questionDomain
      }
      if (populations.length) {
        params['populations'] = populations.join()
      }
      if (interventions.length) {
        params['interventions'] = interventions.join()
      }
      return params
    },
    // updateLastVisit: function () {
    //   /* This is only trigered when if the user is inside the references tab */
    //   let params = this.getMetadataParams()
    //   if (this.ui.activeTabIndex === 1) {
    //     Api.post(`/loves/${this.$route.params.id}/last_visit`, params)
    //       .catch((error) => { console.log(error) })
    //   }
    // },
    updateFilters: function (filters) {
      this.filters = filters
    },
    handleRecommendationPath(){
     const currentPath = this.$route.fullPath.split('/').slice(2,this.$route.fullPath.length).join('/')
     this.SET_CURRENT_RECOMMENDATION_PATH(currentPath)
    },
    ...mapMutations(['SET_CURRENT_RECOMMENDATION_PATH'])
  },
  watch: {
    'ui.activeTabIndex': function () {
      this.updateLastVisit()
      this.changeHash()
    },
    '$route.query': {
      deep: true,
      handler: function () {
        this.processRoute()
      }
    },
    filters: {
      handler: function () {
        this.updateFilters(this.filters)
        this.changeHash()
      },
      deep: true
    }
  },
  computed: {
    currentTaxonomy: function () {
      return platform_config.taxonomy.default
    },
    showClearSearch: function () {
      return this.filters.query.trim().length > 0
    },
    preparedReferencesParams: function () {
      let referencesParams = {}
      let metadataFilters = []
      // if (this.questionDomainSelected.value !== 'any') {
      //   metadataFilters.push(idByType[this.questionDomainSelected.value])
      // }
      for (const element of this.criteria) {
        if (element.selectedMetadata && element.selectedMetadata.length) {
          for (const metadatum of element.selectedMetadata) {
            metadataFilters.push(metadatum.id)
          }
        }
      }
      // if (!metadataFilters.includes(this.love.category_id)) {
      //   metadataFilters.push(this.love.category_id)
      // }
      if (metadataFilters.filter(x => x).length > 0) {
        referencesParams['categories'] = metadataFilters.toString()
      }
      if (this.filters.query) {
        referencesParams['query'] = this.filters.query
      }
      if (this.filters.year) {
        referencesParams['year'] = this.filters.year
      }
      if (this.filters.age) {
        referencesParams['age'] = this.filters.age
      }
      if (this.filters.institution) {
        referencesParams['institution'] = this.filters.institution
      }
      return referencesParams
    },
    activeFilters: function () {
      let activeCount = 0
      let metadataFilters = []
      // if (this.questionDomainSelected.value !== 'any') {
      //   metadataFilters.push(idByType[this.questionDomainSelected.value])
      // }
      for (const element of this.criteria) {
        if (element.selectedMetadata && element.selectedMetadata.length) {
          for (const metadatum of element.selectedMetadata) {
            metadataFilters.push(metadatum.id)
          }
        }
      }
      // if (!metadataFilters.includes(this.love.category_id)) {
      //   metadataFilters.push(this.love.category_id)
      // }
      activeCount += metadataFilters.filter(x => x).length
      if (this.filters.query) {
        activeCount += 1
      }
      if (this.filters.year) {
        activeCount += 1
      }
      if (this.filters.age) {
        activeCount += 1
      }
      if (this.filters.institution) {
        activeCount += 1
      }
      return activeCount
    },
    shownCategoriesByType: function () {
      let categoriesTypes = {
      }
      for (const category of this.taxonomy) {
        if (!categoriesTypes[category.type]) {
          categoriesTypes[category.type] = []
        }
      }
      for (const key of Object.keys(categoriesTypes)) {
        categoriesTypes[key] = this.taxonomy
          .filter(element => !element.secondary && element.type === key)
          .sort(this.sortByName)
      }
      return categoriesTypes
    },
    shownPopulations: function () {
      return this.taxonomy
        .filter(element => !element.secondary && element.type === 'population')
        .sort(this.sortByName)
    },
    shownInterventions: function () {
      let tmp = this.taxonomy.filter(category => this.currentTaxonomy.treatment.includes(category.id))
      // show only level 1
      tmp.map(category => category.children = [])
      return tmp
    },
    shownSDGs: function () {
      let sdg3_ids = platform_config.sdg3_goals.map(g => g.category_id)
      let tmp = this.taxonomy.filter(element => sdg3_ids.includes(element.id))
      tmp = tmp.filter(element => !element.secondary)
      // show only up to level 2
      tmp.map(category => category.children.map(cat_l2 => cat_l2.children = []))
      return tmp
    },
    isQuestionDomainSelected: function () {
      return this.questionDomainSelected.value !== 'any'
    },
    selectedCriteria: function () {
      let _selectedCriteria = {}
      for (let _criteria of this.criteria) {
        _selectedCriteria[_criteria.value] = _criteria.selectedMetadata.map((sel) => sel)
      }
      return _selectedCriteria
    },
    isRootSDG3selected: function () {
      try {
        return platform_config.sdg3_root_categories.includes(this.selectedCriteria.intervention_variable[0].id)
      } catch(e) {
        // statements
        return false
      }
    },
    // loveCategory: function () {
    //   for (const element of this.taxonomy) {
    //     if (this.love.category_id === element.id) {
    //       return element
    //     }
    //   }
    //   return null
    // },
    referencesNumberToShow: function () {
      // If showing threads, then the number should be the number of threads. Otherwise it should be the number of references
      if (this.studify) {
        return this.threadsNumber
      } else {
        return this.referencesNumber
      }
    },
    // selectedMetadataForDownload () {
    //   let filters = []
    //   for (const element of this.criteria) {
    //     if (element.selectedMetadata && element.selectedMetadata.length) {
    //       for (const metadatum of element.selectedMetadata) {
    //         filters.push(metadatum.id)
    //       }
    //     }
    //   }
    //   if (this.questionDomainSelected.value !== 'any') {
    //     filters.push(idByType[this.questionDomainSelected.value])
    //   }
    //   return filters
    // },
    noReferencesFoundMessage: function () {
      if (this.filters.others && this.filters.others !== 'all') {
        if (this.filters.others === 'excluded_only') {
          return 'There are no excluded articles in this question.'
        }
        if (this.filters.others === 'awaiting_assessment') {
          return 'There are no articles awaiting assessment in this question.'
        }
      }
      return 'We have not found relevant articles for this question'
    },
    // showLoveHome: function () {
    //   for (const _criteria of this.criteria) {
    //     for (const element of _criteria.selectedMetadata) {
    //       if (element.id !== this.love.category_id) {
    //         return false
    //       }
    //     }
    //   }
    //   return true
    // },
    showClear: function () {
      if (!this.questionDomainSelected.value) {
        return false
      }
      return true
      // if (this.love.premium) {
      //   return this.questionDomainSelected.value !== 'any' || !this.showLoveHome
      // } else {
      //   return (this.questionDomainSelected.value !== 'any' && this.questionDomainSelected.value !== 'treatment') || !this.showLoveHome
      // }
    },
    selectedClassification: function () {
      let filters = {
        'all': 'Total articles included',
        'broad-synthesis': 'Broad syntheses',
        'systematic-review': 'Systematic reviews',
        'primary-study': 'Primary studies',
        'without_classification': 'Other articles'
      }
      return filters[this.filters.classification]
    },
    selectedMetadata: function () {
      let metadata = []
      for (const element of this.criteria) {
        if (element.selectedMetadata && element.selectedMetadata.length) {
          for (const metadatum of element.selectedMetadata) {
            metadata.push(metadatum)
          }
        }
      }
      return metadata
    },
    notificationsText: function () {
      if (this.savedQuestion && this.savedQuestion.notification_preferences) {
        if (this.savedQuestion.notification_preferences.send_email && this.savedQuestion.notification_preferences.email_frequency === 'daily') {
          return 'Daily notifications'
        }
        if (this.savedQuestion.notification_preferences.send_email && this.savedQuestion.notification_preferences.email_frequency === 'weekly') {
          return 'Weekly notifications (every monday)'
        }
        if (this.savedQuestion.notification_preferences.send_email && this.savedQuestion.notification_preferences.email_frequency === 'monthly') {
          return 'Monthly notifications (every 1st day of the month)'
        }
        if (this.savedQuestion.notification_preferences.send_email && this.savedQuestion.notification_preferences.email_frequency === 'yearly') {
          return 'Yearly notifications (every 1st of January)'
        }
        if (!this.savedQuestion.notification_preferences.send_email) {
          return 'No notifications'
        }
      }
      return 'No notifications'
    }
  },
  components: {
    // 'advanced-search': AdvancedSearch,
    // 'advanced-filters': AdvancedFilters,
    // 'button-download-references': ButtonDownloadReferences,
    // 'question-classifier': QuestionClassifier,
    // 'question-comparison': QuestionComparison,
    // 'question-summary': QuestionSummary,
    'pico-browse': PicoBrowse,
    // 'references-numbers': ReferencesNumbers,
    // 'subscribe': Subscribe,
    // 'threads-list': ThreadsList,
    // 'question-spreadsheets': QuestionSpreadsheets
    'recommendation-box': RecommendationBox,
     'vue-toggles': VueToggles

  }
}
