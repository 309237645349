<template>
  <div>
    <p>
      A utilização dos produtos, serviços e/ou ferramentas disponibilizados aos usuários
      pelo Centro Latino-Americano e do Caribe de Informação em Ciências da Saúde
      (BIREME), Centro Especializado da Organização Pan-Americana da Saúde, Escritório
      Regional para as Américas da Organização Mundial da Saúde, doravante BIREME/OPAS,
      envolve a aceitação dos seguintes Termos e Condições. Em caso de discrepância destes
      Termos e Condições entre o texto em espanhol e qualquer outro idioma, prevalecerá o
      documento em espanhol.
    </p>
    <p>
      A BIREME/OPAS se reserva o direito de acionar legalmente para evitar qualquer
      infração ou descumprimento dos presentes Termos e Condições. A BIREME/OPAS poderá
      modificar estes Termos e Condições a seu total critério e publicará estas
      modificações no presente site. O uso deste site, dos produtos, serviços e/ou
      ferramentas após a realização destas mudanças constitui aceitação por parte do
      usuário dos novos Termos e Condições. A OPAS se reserva o direito exclusivo de
      cancelar o acesso de qualquer usuário por infração de direitos ou descumprimento dos
      presentes Termos e Condições.
    </p>
    <h2>Uso do nome BIREME e/ou OPAS</h2>
    <p>
      O nome, acrônimo e/ou logotipo BIREME e OPAS estão protegidos pelo direito
      internacional. Salvo para propósitos de atribuição autoral, eles não podem ser
      utilizados sem autorização prévia expressa e por escrito da BIREME/OPAS. O nome,
      acrônimo e/ou logo BIREME/ OPAS também não podem ser utilizados com fins
      promocionais ou em nenhuma forma que indique, sugira ou possa ser interpretada como
      associação, filiação, patrocínio ou respaldo da BIREME/OPAS.
    </p>
    <h2>Limitação de Responsabilidade</h2>
    <p>
      Os produtos, serviços e/ou ferramentas que BIREME/OPAS disponibiliza aos usuários
      não possuem nenhum tipo de garantia, explícita ou implícita. A BIREME/OPAS não
      oferece garantias nem responde pela exatidão ou veracidade da informação, produtos,
      serviços e/ou ferramentas proporcionados. A BIREME/ OPAS poderá modificar os mesmos
      periodicamente, sem aviso prévio. Sob nenhuma circunstância a BIREME/OPAS será
      responsável pelas perdas, danos e/ou prejuízos que supostamente possam derivar de
      sua utilização ou não disponibilidade. Os usuários devem utilizar esses produtos,
      serviços e/ou ferramentas por sua própria conta e risco. Em nenhum caso a
      BIREME/OPAS será responsável por perdas e/ou danos, mesmo se estes pudessem ter sido
      advertidos e/ou prevenidos.
    </p>
    <p>
      A BIREME/OPAS se reserva o direito de modificar ou descontinuar qualquer informação,
      produtos, serviços e/ou ferramentas disponibilizados aos seus membros e/ou usuários
      através deste site, com ou sem aviso prévio. O uso de designações de países ou
      territórios não deverá ser considerado indicativo da posição da BIREME/OPAS com
      relação à situação jurídica de nenhum país ou território, de suas autoridades e
      instituições nem ao reconhecimento de nenhuma fronteira.
    </p>
    <p>
      As designações empregadas e a apresentação do material nas publicações listadas nas
      bases de dados não implicam a expressão de qualquer opinião da BIREME/OPAS sobre o
      status legal de qualquer país, território, cidade ou área ou de suas autoridades, ou
      relativa à delimitação de suas fronteiras ou limites. Linhas pontilhadas e
      tracejadas nos mapas representam linhas de fronteira aproximadas para as quais pode
      ainda não haver total concordância.
    </p>
    <p>
      A menção de empresas específicas ou de produtos de determinados fabricantes nas
      publicações listadas nos bancos de dados não implica que eles sejam endossados ou
      recomendados pela BIREME/OPAS em preferência a outros de natureza semelhante que não
      são mencionados. Com exceção de erros e omissões, os nomes dos produtos
      proprietários são diferenciados por meio de letras iniciais maiúsculas.
    </p>
    <p>
      A BIREME/OPAS não será responsável por nenhum dano ou perda que possa derivar ou se
      relacionar com o uso ou falha do site, informação, produtos, serviços e/ou
      ferramentas presentes no mesmo, inclusive nos casos derivados de uso inapropriado,
      impróprio ou fraudulento. Também não será responsável pela precisão, disponibilidade
      ou veracidade da informação aqui contida.
    </p>
    <p>
      A BIREME/OPAS não oferece garantias de nenhum tipo com relação aos materiais,
      informação, produtos, serviços e/ou ferramentas disponíveis no site e não será
      responsável por qualquer infecção com vírus informático ou qualquer outro programa
      que possa afetar o funcionamento de computadores ou sistemas.
    </p>
    <p>
      Alguns materiais, informação, produtos, serviços e/ou ferramentas aos quais os
      usuários poderão ter acesso através deste site podem ser de titularidade de
      terceiros; a BIREME/OPAS não garante sua titularidade e não será responsável perante
      os usuários ou terceiros por qualquer reclamação derivada de seu uso. É possível que
      no site, materiais, informação, produtos, serviços e/ou ferramentas sejam incluídos
      links para sites externos não manejados pela BIREME/OPAS. Esses links são incluídos
      à guisa de referência e sua incorporação não implica em aprovação ou endosso por
      parte da BIREME/OPAS. A BIREME/OPAS não assume responsabilidade no tocante à
      informação contida nesses sites.
    </p>
    <p>
      A utilização deste site pelos usuários implica a aceitação da obrigação de defender,
      manter a salvo e indenizar a BIREME/OPAS e seus filiados por qualquer ação,
      reclamação ou dano, perda e/ou gasto (inclusive os honorários de advogados)
      derivados de tal uso.
    </p>
    <p>
      A BIREME/OPAS se reserva o direito de utilizar ou divulgar informação sobre seus
      usuários. Também se reserva o direito de editar, não publicar ou remover qualquer
      produto, serviços, ferramenta, informação ou material, em parte ou em sua
      totalidade, a seu total critério.
    </p>
    <h2>Usos não permitidos</h2>
    <p>
      Os usuários garantem que o site, informação, produtos, serviços e/ou ferramentas
      aqui contidos não serão utilizados com propósitos contrários às leis ou que
      infrinjam os presentes termos e condições. Os usuários não utilizarão o site,
      informação, produtos, serviços e/ou ferramentas de maneira que possa prejudicar,
      desabilitar, sobrecarregar ou deteriorar o site, a informação, produtos, serviços
      e/ou ferramentas ou seu uso por parte de terceiros. Os usuários não tentarão obter
      nenhuma informação, produtos, serviços e/ou ferramentas por meios não
      intencionalmente disponíveis ou habilitados neste site.
    </p>
    <p>
      Os usuários também se comprometem a respeitar os direitos de terceiros, bem como as
      leis de propriedade intelectual durante qualquer uso do site, informação, produtos,
      serviços e/ou ferramentas aqui contidos.
    </p>
    <h2>Privilégios e Imunidades</h2>
    <p>
      Nada do aqui exposto constituirá ou poderá ser interpretado como renúncia, expressa
      ou tácita, aos privilégios, imunidades e exonerações que a OPAS goza, de
      conformidade com o Direito Internacional, tratados ou acordos internacionais, ou a
      legislação de seus Países Membros.
    </p>
    <h2>Espaços de diálogo</h2>
    <p>
      A BIREME/OPAS poderá oferecer espaços de diálogo ou outros instrumentos de
      comunicação. Ao utilizarem esses instrumentos, os usuários se comprometem se abster
      de:
    </p>
    <ol type="a">
      <li>
        Difamar, injuriar, assediar, ameaçar ou assumir qualquer outro tipo de
        comportamento que vulnere os direitos dos outros;
      </li>
      <li>
        Difundir, publicar, distribuir ou divulgar informações ou materiais difamatórios,
        obscenos, indecentes, ilícitos ou imorais;
      </li>
      <li>
        Baixar no site ou enviar como anexos de uma mensagem arquivos que contenham
        programas informáticos ou outros materiais amparados pelas leis de propiedade
        intelectual (ou pelo direito à intimidade e à publicidade), sem contar com a
        autorização correspondente;
      </li>
      <li>
        Não incluir a referência do autor ou notificações legais, mentir sobre a origem ou
        apresentar como próprio programas informáticos ou outros materiais presentes em um
        arquivo baixado no site;
      </li>
      <li>
        Anunciar ou colocar à venda bens ou serviços, realizar ou promover pesquisas,
        concursos ou correntes, baixar de um fórum um arquivo enviado por outro usuário
        que o usuário saiba, ou deva saber, que não pode ser distribuído licitamente dessa
        maneira.
      </li>
    </ol>
    <p>
      Os usuários reconhecem que todos os espaços de diálogo constituem um meio de
      comunicação pública e não privada. Reconhecem também que os diálogos e o material
      publicado no site não contam com o respaldo da BIREME/OPAS e não serão considerados
      revisados, examinados nem aprovados pela BIREME/OPAS.
    </p>
    <h2>Reclamações por possíveis infrações</h2>
    <p>
      A BIREME/OPAS se esforçará para impedir em seu site atividades ilícitas e contrárias
      aos presentes Termos e Condições. Entretanto, se o usuário acreditar que alguma
      informação contida no site ou em seus materiais poderia estar infringindo seus
      direitos ou os de terceiros, incluindo, sem limitação, direitos de propriedade
      intelectual, deverá entrar em contato com a BIREME/OPAS enviando una descrição
      detalhada da suposta violação para o seguinte email: bir.online@paho.org
    </p>
    <h2>Resolução de controvérsias</h2>
    <p>
      Qualquer disputa, controvérsia ou reclamação surgida ou derivada do uso do site ou
      de sua informação, produtos, serviços e/ou ferramentas será resolvida de forma
      amistosa entre a BIREME/OPAS e o usuário. A menos que as disputas ou controvérsias
      se solucionem de forma amistosa nos sessenta (60) dias posteriores ao recebimento
      por uma das partes da solicitação da outra de tentar una resolução amistosa, tal
      disputa, controvérsia ou reclamação será remitida por qualquer uma das partes para
      arbitragem, de conformidade com as Regras de Arbitragem da Comissão das Nações
      Unidas para o Direito Mercantil Internacional (CNUDMI) vigentes. O tribunal arbitral
      não estará autorizado a impor danos punitivos. Qualquer laudo arbitral emitido em
      virtude da mencionada arbitragem será considerado como a adjudicação definitiva da
      disputa, controvérsia ou reclamação e terá caráter vinculante para as partes.
    </p>
    <p>
      <strong
        >SE NÃO ESTIVER CONFORME COM ALGUM DOS TERMOS E CONDIÇÕES PRESENTES DEVERÁ SE
        ABSTER DE UTILIZAR O SITE E QUALQUER MATERIAL CONTIDO NO MESMO.</strong
      >
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
