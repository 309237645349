<template>
  <div class="container static-content">
    <h1>{{ $t("top_menu.glossary") }}</h1>
    <ol>
      <es-glossary v-if="$i18n.locale == 'es'" />
      <pt-glossary v-else-if="$i18n.locale == 'pt'" />
      <fr-glossary v-else-if="$i18n.locale == 'fr'" />
      <en-glossary v-else />

      <li v-for="point in $t('top_menu.glossary_content')" :key="point.id">
        <div v-if="!['3', '4', '5', '8'].includes(point.id)">
          <h2>{{ point.title }}</h2>
          <p>{{ point.content }}</p>
        </div>
        <div v-if="point.id == 3">
          <h2>{{ point.title }}</h2>
          <ol type="A">
            <li v-for="sub_point in point.text" :key="sub_point.id">
              <h3>{{ sub_point.title }}</h3>
              <p>{{ sub_point.content }}</p>

              <div v-if="sub_point.has_table" style="overflow-x: auto">
                <b-table
                  striped
                  hover
                  :fields="$t('top_menu.glossary_tables[0].fields')"
                  :items="$t('top_menu.glossary_tables[0].content')"
                ></b-table>
              </div>
            </li>
          </ol>
        </div>
        <div v-if="point.id == 4">
          <h2>{{ point.title }}</h2>
          <p>{{ point.intro }}</p>
          <ol type="A">
            <li v-for="sub_point in point.text" :key="sub_point.id">
              <h3>{{ sub_point.title }}</h3>
              <p>{{ sub_point.content }}</p>
            </li>
          </ol>
        </div>
        <div v-if="point.id == 5">
          <h2>{{ point.title }}</h2>
          <p>{{ point.intro }}</p>
          <ul>
            <li v-for="sub_point in point.text" :key="sub_point.id">
              <p>{{ sub_point.content }}</p>
            </li>
          </ul>
        </div>
        <div v-if="point.id == 8">
          <h2>{{ point.title }}</h2>
          <p>
            <a :href="point.content" target="_target"
              >{{ point.content }}
              <font-awesome-icon icon="external-link-alt" class="ml-1"
            /></a>
          </p>
        </div>
      </li>
    </ol>
  </div>
</template>

<script>
import enGlossary from "./translations/glossary/enGlossary.vue";
import EsGlossary from "./translations/glossary/esGlossary.vue";
import PtGlossary from "./translations/glossary/ptGlossary.vue";
import FrGlossary from "./translations/glossary/frGlossary.vue";
export default {
  components: { enGlossary, EsGlossary, PtGlossary, FrGlossary },
  name: "References",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables.scss";
@import "../assets/styles/typography.scss";

// h2 {
//   margin: auto;
//   text-align: center;
//   vertical-align: middle;
//   padding: 5px;
// }
// p {
//   margin: auto;
//   text-align: center;
//   vertical-align: middle;
// }
// img {
//   background-color: $white;
// }
</style>
