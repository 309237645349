<template>
  <div>
    <div class="w-100 mt-2" v-if="!hideFilter">
      <input class="form-control form-control-sm" type="text" :placeholder="$t('pico_builder.search')" @input="filterCategories" v-on:keyup.enter="filterCategoriesAndShowMore(filterText,taxonomy)" v-model="filterText">
    </div>
    <div class="mt-2 no-results px-2" v-if="forceShow">
      {{$t('pico_builder.no_hits')}}
    </div>
    <!-- LOVE CATEGORIES -->
    <ul class="ul-panel-left mt-2" :class="{'category-list': $mq !== 'mobile'}">
      <category-list-item
        v-for="item in filteredTaxonomy"
        :forceShow="forceShow"
        :item="item"
        :path="item.id"
        :key="item.id"
        :toggleElement="toggleElement"
        :criteria="criteria"
        :taxonomy="taxonomy"
        :listName="listName"
        :selectCategory="selectCategory"
        :removeFilter="removeFilterAndCollapseTaxonomy"
        ></category-list-item>
    </ul>
  </div>
</template>

<script>

import CategoryListItem from './CategoryListItem'
import debounce from 'lodash/debounce'

export default {
  name: 'CategoryList',
  props: [
    'collapseTaxonomyList',
    'criteria',
    'filterCategoriesAndShowMore',
    'forceShow',
    'hideFilter',
    'listName',
    'loveCategoryId',
    'removeFilter',
    'selectCategory',
    'showHiddenCategories',
    'taxonomy',
    'toggleElement'
  ],
  data () {
    return {
      filterText: '',
      clear: null

    }
  },
  mounted: function () {
    this.clear = () => {
      this.clearFilters()
    }
    this.$root.$on('clear-filters', this.clear)
  },
  beforeDestroy: function () {
    this.$root.$off('clear-filters', this.clear)
  },
  methods: {
    clearFilters: function () {
      this.filterText = ''
      this.filterCategoriesAndShowMore(this.filterText, this.taxonomy)
    },
    filterCategories: debounce(function () {
      this.filterCategoriesAndShowMore(this.filterText, this.taxonomy)
    }, 500),
    removeFilterAndCollapseTaxonomy: function (item) {
      this.removeFilter(this.criteria, item)
      this.collapseTaxonomyList(this.taxonomy)
      this.filterText = ''
      this.filterCategoriesAndShowMore(this.filterText, this.taxonomy)
    }
  },
  watch: {
    'hideFilter': function () {
      this.clearFilters()
    }
  },
  computed: {
    filteredTaxonomy: function () {
      return this.taxonomy.filter(category => {
        return !category.hidden || this.showHiddenCategories
      })
    }
  },
  components: {
    'category-list-item': CategoryListItem
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../../assets/styles/variables.scss";
  ul {
    padding-left: 20px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .message {
    color: $green-font;
  }
  .no-results {
    color: $strong;
  }
  .category-list {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
</style>
