<template>
  <div @click="selectTopic(topic)" class="topic px-3 pointer align-items-left" :class="mode">
        <div style="padding:20px; padding-left:30px">
          <div class="row">
            <span id="colorstrip" :style="{'background-color':topic.color}"/>
          </div>
          <div class="row" style="padding-top:15px">
            <h3 class="topic-title" itemprop="title">{{$t("general.sdg3_targets." + topic.category_id)}}</h3>
          </div>
        </div>
  </div>
</template>

<script>

export default {
  name: 'TopicsListItem',
  props: {
    'topic': {
      type: Object
    },
    'selectTopic': {
      type: Function
    },
    'mode': {
      type: String,
      default: 'grid'
    }
  },
  methods: {
    /* goToTopic: function (topic) {
      this.$router.push(`/topics/${topic.id}`)
    } */
  },
  computed: {
    // isEditor: function () {
    //   if (((this.$store.state.account || {}).loves_editor || []).includes(this.topic.id)) {
    //     return true
    //   }
    //   return ((this.$store.state.account || {}).api_roles || []).includes('admin') || ((this.$store.state.account || {}).api_roles || []).includes('manager') || ((this.$store.state.account || {}).api_roles || []).includes('editor')
    // }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../assets/styles/variables.scss";
  .topic {
    flex-direction: column;
    border-radius: 15px;
    margin-bottom: 1.75rem;
    display: flex;
    background-color: $sdg3_box_bg ;
    border: 1px solid $sdg3_box_bg;
    min-height: 34px;
    h3 {
      font-weight: bold;
    }
    &.list {
      margin-bottom: 1rem;
    }
    &:hover {
      color: inherit;
      background-color: $badge-light-blue;
      // border: 1px solid $headercolor;
    }
    @media (min-width: 576px) {
    }
    @media (min-width: 768px) {
      &.grid {
        height: 150px;
      }
    }
    .topic-body {
      display: flex;
      align-items: center;
      height: 100%;
      .topic-title {
        font-size: 1.3rem;
        font-weight: normal;
        margin-bottom: 0px;
      }
    }
  }
  
  .uppercase {
    text-transform: uppercase;
  }
  
  #colorstrip{
    width: 100px; 
    height: 13px;
    size: 6;
    border-style: none;
    border-color: black;
    background-color: transparent;
  }
</style>
