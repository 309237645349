import axios from 'axios'

export default class Api {
  static host = process.env.VUE_APP_API_URL
  static getHeaders () {
    let authToken = localStorage.getItem('l_s')
    return {
      Authorization: `Token session="${authToken}"`
    }
  }

  static put (path, data, cancelToken) {
    return axios.put(
      Api.host + path,
      data,
      {
        headers: this.getHeaders(),
        cancelToken: cancelToken
      })
  }

  static get (path, data, cancelToken) {
    let options = {
      url: Api.host + path,
      method: 'GET',
      headers: this.getHeaders(),
      params: data,
      cancelToken: cancelToken
    }
    return axios(options)
  }

  static post (path, data, cancelToken) {
    return axios.post(
      Api.host + path,
      data,
      {
        headers: this.getHeaders(),
        cancelToken: cancelToken
      })
  }

  static delete (path, data, cancelToken) {
    return axios.delete(
      Api.host + path,
      {
        headers: this.getHeaders(),
        cancelToken: cancelToken
      })
  }
}
