<template>
  <div>
    <div v-if="$mq!='mobile'" class="container bigg-rec-jumbotron">
      <div class="row">
        <div class="col-12 text-center">
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <img class="bigg-rec-logo" :src="require(`../assets/images/bigg_rec_logo.svg`)" alt="ops-logo">
        </div>
        <div class="col-12 text-center platform-title">
          <p class="d-inline-block">
            {{$t('general.platform_title')}}
            <img class="sdg3-wheel" :src="require('../assets/images/sdg_wheel.png')" alt="sdg3-wheel" id="sdg3-popover">
            <sdg3-popover></sdg3-popover>
          </p>
        </div>
      </div>
    </div>
    <div v-if="$mq=='mobile'" class="container mb-4">
      <div class="row bigg-rec-jumbotron">
        <div class="col-12 text-center">
          <img class="bigg-rec-logo-mobile" :src="require(`../assets/images/bigg_rec_logo.svg`)" alt="ops-logo">
        </div>
        <div class="col-12 text-center">
          <p>
            {{$t('general.platform_title')}}
            <img class="sdg3-wheel" :src="require('../assets/images/sdg_wheel.png')" alt="sdg3-wheel" id="sdg3-popover">
            <sdg3-popover :placement="'bottom'"></sdg3-popover>
          </p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import SDG3Popover from './utils/SDG3Popover'
export default {
  name: 'WelcomeJumbotron',
  data () {
    return {
    }
  },
  components: {
    'sdg3-popover': SDG3Popover
  }
}
</script>

<style lang="scss">
  .sdg3-logo {
    width: 200px;
  }
</style>
<style lang="scss" scoped>
@import "../assets/styles/variables.scss";
@import "../assets/styles/typography.scss";

.main-title {
  margin-top: 1em;
  h1{
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 300;
    font-size: 35px;
  }
}
.bigg-rec-jumbotron {
  #sdg3-popover {
    height: 26px;
    position: relative;
    bottom: 4px;
    left: 3px;
    cursor: pointer;
  }
  p {
    font-family: "Lato", sans-serif !important;
    font-size: 2em;
    color: $dark-grey-font;
  }
  .bigg-rec-logo {
    height: 196px;
    margin-bottom: 0.5em;
  }
  .bigg-rec-logo-mobile {
    max-height: 120px;
    min-height: 81px;
    margin-bottom: 15px;
  }
  .question-icon {
    opacity: 0.4;
    top: -5px;
    position: relative;
  }
  &:hover {
    .question-icon {
      opacity: 1;
      cursor: pointer;
    }
  }

}
.top-row {
  margin: 0 auto;
}
.ops-logo, .episte-fund-logo {
  margin-bottom: 20px;
}

.ops-logo {
  height: 50px;
  margin-left: 30%;
}
.episte-fund-logo {
  height: 45px;
  margin-left: 63px;
}
.bottom-row {
  background-color: $grey;
}
button.button-box.epistemonikos {
  background-color: $epistemonikos;
  &:hover {
    background-color: $ops-portal-hover;
  }
}
.row-margin {
  margin: auto;
}

.button-text {
  margin: auto;
  text-align: center;
  width: 80%;
}

.button-box {
  border-radius: 8px;
}

.mauto {
  margin: 0 auto;
}

</style>
