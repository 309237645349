<template>
  <div class="container static-content">
    <h1>{{ $t("top_menu.terms") }}</h1>
    <!-- UPDATED 05-08-2022 -->
    <es-terms-and-conditions v-if="$i18n.locale == 'es'" />
    <!-- UPDATED 08-02-2023 -->
    <fr-terms-and-conditions v-else-if="$i18n.locale == 'fr'" />
     <!-- UPDATED 14-02-2023 -->
    <pt-terms-and-conditions v-else-if="$i18n.locale == 'pt'" />
    <!-- UPDATED 05-08-2022 -->
    <en-terms-and-conditions v-else />
  </div>
</template>

<script>
import EnTermsAndConditions from "./translations/terms_and_conditions/enTermsAndConditions.vue";
import EsTermsAndConditions from "./translations/terms_and_conditions/esTermsAndConditions.vue";
import frTermsAndConditions from "./translations/terms_and_conditions/frTermsAndConditions.vue";
import PtTermsAndConditions from './translations/terms_and_conditions/ptTermsAndConditions.vue';
export default {
  components: { frTermsAndConditions, EsTermsAndConditions, EnTermsAndConditions, PtTermsAndConditions },
  name: "TermsConditions",
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables.scss";
@import "../assets/styles/typography.scss";
</style>
