<template>
  <div>
    <p>
      L'utilisation des produits, services et/ou outils que le Centro Latinoamericano y
      del Caribe de Información en Ciencias de la Salud ("BIREME"), un centre spécialisé
      de l'Organisation panaméricaine de la santé, Bureau régional pour les Amériques de
      l'Organisation mondiale de la santé (ci-après dénommé "BIREME/OPS"), met à la
      disposition des utilisateurs constitue une acceptation des Conditions Générales
      ci-dessous. En cas de conflit entre le texte des présentes Conditions Générales et
      le texte en espagnol, ce dernier prévaut.
    </p>
    <p>
      BIREME/OPS se réserve le droit d'engager des actions en justice pour faire cesser
      toute infraction ou violation des présentes Conditions Générales. BIREME/OPS peut
      modifier les présentes Conditions Générales à sa seule discrétion et publiera ces
      modifications sur ce site Web. L'utilisation de ce site web, de ses produits,
      services et/ou outils après que de telles modifications aient été apportées
      constituera l'acceptation par l'utilisateur de ces Conditions Générales modifiées.
      L'OPS se réserve le droit exclusif de mettre fin à l'accès de l'utilisateur en cas
      d’une quelconque violation de ses droits ou de ces Conditions Générales.
    </p>
    <h2>Utilisation du nom de BIREME et/ou de l'OPS</h2>
    <p>
      Le nom, l'acronyme et/ou le logo de BIREME et de l'OPS sont protégés par le droit
      international. Sauf à des fins d'attribution de droits d'auteur, ils ne peuvent être
      utilisés sans l'autorisation écrite préalable de BIREME/OPS. Le nom, l'acronyme et
      le logo de BIREME/OPS ne peuvent être utilisés à des fins promotionnelles ou de
      toute autre manière qui indique, suggère ou puisse être interprétée comme une
      association, une affiliation, un parrainage ou une caution de BIREME/OPS.
    </p>
    <h2>Limitation de la responsabilité</h2>
    <p>
      Les produits, les services et/ou les outils que BIREME/OPS met à la disposition des
      utilisateurs sont fournis sans aucune sorte de garantie, explicite ou implicite.
      BIREME/OPS ne donne aucune garantie et ne garantit pas l'exactitude ni la véracité
      des informations, produits, services et/ou outils fournis. BIREME/OPS peut les
      modifier périodiquement sans préavis. En aucun cas, BIREME/OPS ne sera responsable
      des pertes et/ou dommages pouvant résulter de leur utilisation ou de leur
      non-disponibilité. Les utilisateurs doivent utiliser ces produits, services et/ou
      outils à leurs propres risques. En aucun cas, BIREME/OPS ne sera responsable des
      dommages qui en découleraient, même si ces derniers avaient pu être prévenus et/ou
      évités.
    </p>
    <p>
      BIREME/OPS se réserve le droit de modifier ou de suspendre toute information,
      produit, service et/ou outil mis à la disposition de ses membres et/ou utilisateurs
      par le biais de ce site web, avec ou sans préavis. L'utilisation de désignations de
      pays ou de territoires ne doit pas être considérée comme une indication de la
      position de BIREME/OPS concernant le statut légal d'un pays ou d'un territoire, ses
      autorités et institutions, ou la reconnaissance de frontières.
    </p>
    <p>
      Les désignations employées et la présentation du matériel dans les publications
      répertoriées dans les bases de données n'impliquent pas l'expression d'une
      quelconque opinion de la part de BIREME/OPS concernant le statut légal d'un pays,
      d'un territoire, d'une ville ou d'une zone ou de ses autorités, ou concernant la
      délimitation de ses frontières ou limites. Les lignes en tirets et en pointillés sur
      les cartes représentent des lignes frontalières approximatives pour lesquelles il se
      peut qu'il n'y ait pas encore d'accord complet.
    </p>
    <p>
      La mention d’entreprises ou de produits de certains fabricants dans les publications
      répertoriées dans les bases de données n'implique pas que ces entreprises ou
      produits sont cautionnés ou recommandés par BIREME/OPS de préférence à d'autres de
      nature similaire qui ne sont pas mentionnés. Sauf erreur ou omission, les noms des
      produits brevetés sont distingués par une majuscule initiale.
    </p>
    <p>
      BIREME/OPS ne sera pas responsable de tout dommage ou perte pouvant découler de, ou
      être lié à, l'utilisation ou la défaillance du site web, des informations, des
      produits, des services et/ou des outils contenus dans ce site, même dans les cas
      découlant d'une utilisation inappropriée ou frauduleuse. BIREME/OPS ne sera pas
      responsable de l'exactitude, de la disponibilité ni de la véracité des informations
      contenues sur ce site.
    </p>
    <p>
      BIREME/OPS ne donne aucune garantie de quelque nature que ce soit concernant les
      matériels, informations, produits, services et/ou outils disponibles sur le site web
      et ne sera pas responsable de tout type d'infection par des virus informatiques ou
      tout autre logiciel qui puisse affecter le fonctionnement des ordinateurs ou des
      systèmes.
    </p>
    <p>
      BIREME/OPS ne donne aucune garantie de quelque nature que ce soit concernant les
      matériels, informations, produits, services et/ou outils disponibles sur le site web
      et ne sera pas responsable de tout type d'infection par des virus informatiques ou
      tout autre logiciel qui puisse affecter le fonctionnement des ordinateurs ou des
      systèmes.
    </p>
    <p>
      L'utilisation de ce site par les utilisateurs implique l'acceptation de l'obligation
      de défendre, d'exonérer et d'indemniser BIREME/OPS et ses affiliés par rapport à
      toute action, réclamation, dommage, perte et/ou dépense (y compris les honoraires
      d'avocat) découlant de cette utilisation.
    </p>
    <p>
      BIREME/OPS se réserve le droit d'utiliser ou de divulguer des informations sur ses
      utilisateurs. BIREME/OPS se réserve également le droit d'éditer, de ne pas publier
      ou de supprimer, en tout ou en partie, tout produit, service, outil ou information
      ou matériel à sa seule discrétion.
    </p>
    <h2>Utilisations non autorisées</h2>
    <p>
      L'utilisateur s’engage á ne pas utiliser le site web, les informations, les
      produits, les services et/ou les outils qu'il contient à des fins contraires à la
      législation d'un quelconque pays ni aux présentes Conditions Générales.
      L'utilisateur ne se servira pas de ce site web, des informations, des produits, des
      services et/ou des outils d'une manière susceptible d'endommager, de désactiver, de
      surcharger ou de détériorer le site web, les informations, les produits, les
      services et/ou les outils qu'il contient ou de nuire à son utilisation par des
      tiers. L'utilisateur n'essaiera pas d'obtenir des informations, produits, services
      et/ou outils qui ne sont pas intentionnellement disponibles ou permis sur le site
      web.
    </p>
    <p>
      L'utilisateur s'engage également à respecter les droits des tiers ainsi que les lois
      sur la propriété intellectuelle lors de l'utilisation du site web, des informations,
      des produits, des services et/ou des outils qu'il contient.
    </p>
    <h2>Privilèges et immunités</h2>
    <p>
      Aucune disposition des présentes ne constitue ni ne peut être interprétée comme une
      renonciation, expresse ou implicite, aux privilèges, immunités et exemptions dont
      jouit l'OPS, conformément au droit international, aux traités ou conventions
      internationaux ou aux lois de ses États membres.
    </p>
    <h2>Forums ou plateformes de chat</h2>
    <p>
      BIREME/OPS peut proposer des plateformes de dialogue ou d'autres outils de
      communication. Lors de l'utilisation de ces instruments, les utilisateurs s'engagent
      à s'abstenir de :
    </p>
    <ol type="a">
      <li>
        Diffamer, insulter, harceler, menacer ou adopter tout autre type de comportement
        qui viole les droits d'autrui ;
      </li>
      <li>
        Diffuser, publier, distribuer ou dévoiler des informations ou du matériel
        diffamatoire, obscène, indécent, illicite ou immoral ;
      </li>
      <li>
        Télécharger ou envoyer en pièces jointes des fichiers contenant des programmes
        informatiques ou d'autres matériels protégés par des lois sur la propriété
        intellectuelle (ou par des droits de confidentialité et de publicité), sans
        l'autorisation correspondante ;
      </li>
      <li>
        Ne pas inclure les noms des auteurs ou les mentions légales concernant les
        programmes informatiques ou autres matériaux contenus dans un fichier qui est
        téléchargé sur le site web, mentir sur leur origine, ou les représenter comme
        propres ;
      </li>
      <li>
        Faire de la publicité ou vendre des biens ou des services, ou mener ou promouvoir
        des enquêtes, des concours ou des chaînes de lettres, ou télécharger à partir d'un
        forum un fichier envoyé par un autre utilisateur dont l'utilisateur sait, ou
        devrait raisonnablement savoir, qu'il ne peut être légalement distribué de cette
        manière.
      </li>
    </ol>
    <p>
      Les utilisateurs reconnaissent que toute plateforme de dialogue constitue une forme
      de communication publique et non pas privée. Ils reconnaissent également que les
      dialogues et les documents publiés sur le site ne sont pas cautionnés par BIREME/OPS
      et ne seront pas considérés comme revus, examinés ou approuvés par BIREME/OPS.
    </p>
    <h2>Signalement des infractions éventuelles</h2>
    <p>
      BIREME/OPS s'efforcera d'empêcher sur son site Web les activités illégales ou qui
      contreviennent aux Conditions Générales contenues dans le présent document.
      Toutefois, si vous estimez qu'une information publiée sur le site Web, ou son
      matériel, peut constituer une violation de vos droits ou de ceux de tiers, y
      compris, mais sans s'y limiter, des droits de propriété intellectuelle, vous devez
      contacter BIREME/OPS en envoyant une description détaillée de la violation présumée
      à l'adresse suivante : bir.online@paho.org.
    </p>
    <h2>Résolution des conflits</h2>
    <p>
      Tout litige, controverse ou réclamation qui naîtrait ou découlerait de l'utilisation
      du site Web ou des informations, produits, services et/ou outils qu'il contient sera
      réglé à l'amiable entre l'utilisateur et BIREME/OPS. À défaut de règlement à
      l'amiable dans les soixante (60) jours suivant la réception par une partie de la
      demande de l'autre partie de tenter un règlement à l'amiable, ce litige, cette
      controverse ou cette réclamation sera soumis par l'une ou l'autre des parties à un
      arbitrage conformément au Règlement d'arbitrage de la Commission des Nations Unies
      pour le droit commercial international (CNUDCI). Le tribunal arbitral n'a pas le
      pouvoir d'accorder des dommages-intérêts punitifs. Toute sentence arbitrale rendue à
      la suite d'un tel arbitrage sera considérée comme la décision finale concernant
      cette controverse, cette réclamation ou ce litige et liera les parties.
    </p>
    <p>
      <strong
        >SI VOUS N'ÊTES PAS D'ACCORD AVEC L'UN DES TERMES ET CONDITIONS CONTENUS DANS CE
        DOCUMENT, VOUS DEVEZ VOUS ABSTENIR D'UTILISER LE SITE WEB ET TOUT MATÉRIEL QU’IL
        CONTIENT.</strong
      >
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
