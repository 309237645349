<template>
  <div>
    <div class="question-builder row pt-0 pt-md-4">

      <!-- Intervention/variable -->
      <criteria-builder :criteria="criteria[3]"
                        criteriaType="intervention_variable"
                        :defaultTitle="$t('pico_builder.sdg3_list_title')"
                        listName="SDG3 goal"
                        :showCategoryList="showCategoryListSecond"
                        :taxonomy="shownSDGs"
                        :toggleElement="toggleElement"
                        :removeFilter="removeFilter"
                        :collapseTaxonomyList="collapseTaxonomyList"
                        :selectCategory="selectCategory"
                        :applyFilter="applyFilter"
                        ></criteria-builder>

      <!-- Population -->
      <criteria-builder v-if="false"
                        :criteria="criteria[0]"
                        :defaultTitle="$t('pico_builder.population_list_title')"
                        listName="Population"
                        criteriaType="population"
                        :showCategoryList="showCategoryList"
                        :taxonomy="shownPopulations"
                        :toggleElement="toggleElement"
                        :removeFilter="removeFilter"
                        :collapseTaxonomyList="collapseTaxonomyList"
                        :selectCategory="selectCategory"
                        :applyFilter="applyFilter"
                        ></criteria-builder>

      <!-- Intervention -->
      <criteria-builder :criteria="criteria[1]"
                        criteriaType="treatment"
                        :defaultTitle="$t('pico_builder.treatment_list_title')"
                        listName="Intervention"
                        :showCategoryList="showCategoryListSecond"
                        :taxonomy="shownInterventions"
                        :toggleElement="toggleElement"
                        :removeFilter="removeFilter"
                        :collapseTaxonomyList="collapseTaxonomyList"
                        :selectCategory="selectCategory"
                        :applyFilter="applyFilter"
                        ></criteria-builder>

    </div>
  </div>
</template>

<script>

// import CategoryList from './CategoryList'
import CriteriaBuilder from './CriteriaBuilder'

export default {
  name: 'PicoBrowse',
  created: function () {
    this.selectedQuestionDomain = this.questionDomainSelected.value
  },
  props: [
    'applyFilter',
    'changeCriteria',
    'collapseTaxonomyList',
    'criteria',
    'toggleTypeOfQuestionInfo',
    'openMobileQuestionBuilder',
    'questionDomainOptions',
    'questionDomainSelected',
    'removeFilter',
    'selectCategory',
    'selectQuestionDomain',
    'shownCategoriesByType',
    'shownSDGs',
    'shownInterventions',
    'shownPopulations',
    'showTypeOfQuestionInfo',
    'showTypeOfQuestionSelector',
    'toggleElement',
    'toggleTypeOfQuestionSelector'
  ],
  data () {
    return {
      selectedFilter: '',
      selectedQuestionDomain: '',
      tabIndex: 1,
      tabTitles: {
        'any': 'Any',
        'treatment': 'Intervention',
        'diagnosis': 'Test',
        'prognosis': 'Prognostic factor',
        'predictive_factors': 'Etiological factor',
        'utility_of_initial_evaluation': 'Estimate'
      },
      selectInterventionMessage: {
        'treatment': 'Select intervention',
        'diagnosis': 'Select test',
        'prognosis': 'Select prognostic factor',
        'predictive_factors': 'Select etiological factor',
        'utility_of_initial_evaluation': 'Select epidemiological estimate'
      },
    }
  },
  methods: {
    changeSelectedCriteria: function () {
      this.changeCriteria(this.tabIndex)
    },
    selectAndCloseQuestionDomain: function (option) {
      this.selectQuestionDomain(option)
    }
  },
  computed: {
    showCategoryList: function () {
      if (this.shownPopulations.length > 1) {
        return true
      }
      if (this.shownPopulations.length === 1 && this.shownPopulations[0].children.length >= 1) {
        return true
      }
      return false
    },
    showCategoryListSecond: function () {
      if (this.shownInterventions.length > 1) {
        return true
      }
      if (this.shownInterventions.length === 1 && this.shownInterventions[0].children.length >= 1) {
        return true
      }
      return false
    },
    showCategoryListByType: function () {
      let shownCategoriesDict = {
      }
      for (const type of Object.keys(this.shownCategoriesByType)) {
        if (this.shownCategoriesByType[type].length > 1) {
          shownCategoriesDict[type] = true
          continue
        }
        if (this.shownInterventions.length === 1 && this.shownInterventions[0].children.length >= 1) {
          shownCategoriesDict[type] = true
          continue
        }
        shownCategoriesDict[type] = false
      }
      return shownCategoriesDict
    },
    selectedMetadata: function () {
      let metadata = []
      for (const element of this.criteria) {
        if (element.selectedMetadata && element.selectedMetadata.length) {
          for (const metadatum of element.selectedMetadata) {
            metadata.push(metadatum)
          }
        }
      }
      return metadata
    }
  },
  components: {
    // 'category-list': CategoryList,
    'criteria-builder': CriteriaBuilder
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/styles/variables.scss";
  .question-builder {
    .type-of-question-option {
      &.active, &:hover {
        color: $green-font;
        text-decoration: underline;
      }
      a.clear {
        font-size: $smallsize;
        color: $strong;
        background-color: $grey;
        margin-left: 5px;
        padding-right: 5px;
        padding-left: 5px;
        border-radius: 5px;
        &:hover {
          color: $strong;
        }
      }
      .option-popover-container {
        padding-right: 70px;
      }
    }
  }
  .filter-card {
    border-radius: 0px 0px 15px 15px;
    font-size: $psize;
    width: 100%;
  }
  .collapsable-button {
    border-radius: 3px 3px 0px 0px;
    background: $headercolor;
    border: 1px solid $headercolor;
    color: #fff;
    font-weight: bold;
    width: 100%;
    height: 30px;
    margin-bottom: 0px;
    text-align: left;
    .fa-info-circle {
      font-size: 1.5rem;
      vertical-align: sub;
    }
    &:focus {
      outline: none;
    }
    &.collapsed {
      border-radius: 3px 3px 3px 3px;
    }
  }
  .external-link {
    color: $green-font;
    font-weight: bold;
    &:hover {
      color: $txt;
    }
  }
</style>

<style lang="scss">
  @import "../../assets/styles/variables.scss";
  // .sidebar {
  //   z-index: 999 !important;
  // }

  #question-domains-info-modal {
    .modal-dialog {
      margin-bottom: 105px;
    }
    header.modal-header {
      padding-top: 6px;
      .fa-info-circle {
        margin-left: 22px;
        font-size: 2rem;
        color: $green-font;
      }
      .fa-times {
        margin-top: 4px;
      }
    }
    .modal-body {
      margin-top: 50px;
    }
    h3 {
      color: $green-font;
    }
  }
  #question-domain-popover {
    border-color: $headercolor;
    border-width: 2px;
    border-radius: 10px;
    h3 {
      background-color: transparent;
      color: $green-font;
      font-size: $h3size;
      border-bottom: none;
    }
    .arrow {
      left: calc((1rem + 1px) * -1);
      width: 1rem;
      height: 2rem;
      margin: -1rem 0;
      &:before {
        left: 0;
        border-width: 1rem 1rem 1rem 0;
        border-right-color: $headercolor;
      }
      &:after {
        left: 3px;
        border-width: 1rem 1rem 1rem 0;
      }
    }
  }
</style>
