<template>
  <b-popover
    target="sdg3-popover"
    triggers="hover"
    placement="bottomleft"
    container="body"
    class="sdg3-popover"
  >
    <template #title>
      <img
        class="sdg3-logo"
        :src="require(`@/assets/images/UN_SDG3_${$i18n.locale}.png`)"
        alt="sdg3-logo"
      />
    </template>
    <span class="static-content">
      <h2>Objetivos de Desarrollo Sostenible (ODS)</h2>
      <p>
        En 2015, la ONU aprobó la
        <a
          href="https://www.un.org/sustainabledevelopment/development-agenda/"
          target="_blank"
          >Agenda 2030 sobre el Desarrollo Sostenible
          <font-awesome-icon icon="external-link-alt" class="ml-1" /></a
        >, una oportunidad para que los países y sus sociedades emprendan un nuevo camino
        con el que mejorar la vida de todos, sin dejar a nadie atrás. La Agenda cuenta con
        17
        <a
          href="https://www.un.org/sustainabledevelopment/sustainable-development-goals/"
          target="_blank"
          >Objetivos de Desarrollo Sostenible
          <font-awesome-icon icon="external-link-alt" class="ml-1" /></a
        >, que incluyen desde la eliminación de la pobreza hasta el combate al cambio
        climático, la educación, la igualdad de la mujer, la defensa del medio ambiente o
        el diseño de nuestras ciudades.
      </p>

      <h2>Tercer objetivo de desarrollo sostenible (ODS-3)</h2>
      <p>
        El Tercer Objetivo de Desarrollo Sostenible es garantizar una vida sana y promover
        el bienestar para todos en todas las edades. Leer mas sobre el objetivo 3 aquí (<a
          href="https://www.un.org/sustainabledevelopment/es/health/"
          target="_blank"
          >https://www.un.org/sustainabledevelopment/es/health/
          <font-awesome-icon icon="external-link-alt" class="ml-1" /></a
        >)
      </p>
    </span></b-popover
  >
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.popover {
  h2 {
    margin-top: initial;
    font-weight: bold;
    font-size: 90%;
  }
  p {
    font-size: 90%;
    line-height: initial;
  }
}
</style>
