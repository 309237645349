<template>
  <b-popover
    target="sdg3-popover"
    triggers="hover"
    placement="bottomleft"
    container="body"
    class="sdg3-popover"
  >
    <template #title>
      <img
        class="sdg3-logo"
        :src="require(`@/assets/images/UN_SDG3_${$i18n.locale}.png`)"
        alt="sdg3-logo"
      />
    </template>
    <span class="static-content">
      <h2>Sustainable Development Goals (SDG)</h2>
      <p>
        The
        <a
          href="https://www.un.org/sustainabledevelopment/sustainable-development-goals/"
          target="_blank"
          >Sustainable Development Goals
          <font-awesome-icon icon="external-link-alt" class="ml-1"
        /></a>
        are a universal call to action to end poverty, protect the planet and improve the
        lives and prospects of everyone, everywhere. The 17 Goals were adopted by all UN
        Member States in 2015, as part of the
        <a
          href="https://www.un.org/sustainabledevelopment/development-agenda/"
          target="_blank"
          >2030 Agenda for Sustainable Development
          <font-awesome-icon icon="external-link-alt" class="ml-1"
        /></a>
        which set out a 15-year plan to achieve the Goals.
      </p>

      <h2>Third Sustainable Development Goal (SDG-3)</h2>
      <p>
        The Third Sustainable Development Goal is to ensure healthy lives and promote
        well-being for all at all ages. Read more (<a
          href="https://www.un.org/sustainabledevelopment/health/"
          target="_blank"
          >https://www.un.org/sustainabledevelopment/health/
          <font-awesome-icon icon="external-link-alt" class="ml-1" /></a
        >)
      </p></span
    >
  </b-popover>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.popover {
  h2 {
    margin-top: initial;
    font-weight: bold;
    font-size: 90%;
  }
  p {
    font-size: 90%;
    line-height: initial;
  }
}
</style>
