<template>
  <div>
    <p>
      The use of products, services, and/or tools that the Centro Latinoamericano y del
      Caribe de Información en Ciencias de la Salud ("BIREME"), a specialized center of
      the Pan American Health Organization, Regional Office for the Americas of the World
      Health Organization (hereinafter referred to as "BIREME/PAHO"), makes available to
      users constitutes acceptance of the following Terms and Conditions. In case of
      conflict between the text of these Terms and Conditions and the text in the Spanish,
      the latter shall prevail.
    </p>
    <p>
      BIREME/PAHO reserves the right to take legal actions to stop any infringement or
      breach of these Terms and Conditions. BIREME/PAHO may modify these Terms and
      Conditions at its sole discretion and will publish such modifications in this
      website. Using this website, its products, services and/or tools after such changes
      are made will constitute acceptance by the user of such amended Terms and
      Conditions. PAHO reserves the exclusive right to terminate user'S access for any
      breach of rights or breach of these Terms and Conditions.
    </p>
    <h2>Use of BIREME'S and/or PAHO'S Name</h2>
    <p>
      BIREME'S and PAHO'S name, acronym and/or logo are protected by international law.
      Except for copyright attribution purposes, these may not be used without express
      prior written permission from BIREME/PAHO. The BIREME/PAHO name, acronym and logo
      may not be used for promotional purposes or in any other way that indicates,
      suggests or can be construed as an association, affiliation, sponsorship or
      endorsement of BIREME/PAHO.
    </p>
    <h2>Limitation of Liability</h2>
    <p>
      The products, services and/or tools that BIREME/PAHO makes available to users are
      provided without any kind of guarantee, either explicit or implicit. BIREME/PAHO
      makes no warranties nor guarantees the accuracy or truthfulness of the information,
      products, services and/or tools provided. BIREME/PAHO may modify these periodically
      without prior notice. Under no circumstances will BIREME/PAHO be liable for losses
      and/or damages that may arise from its use or non-availability. Users must use these
      products, services and/or tools at their own risk. In no case will BIREME/PAHO be
      liable for damages, even if they may have been warned and/or prevented.
    </p>
    <p>
      BIREME/PAHO reserves the right to modify or discontinue any information, products,
      services and/or tools made available to its members and/or users through this
      website, with or without prior notice. The use of country or territory designations
      should not be considered as indicative of BIREME/PAHO'S position regarding the legal
      status of any country or territory, its authorities and institutions, or recognition
      of borders.
    </p>
    <p>
      The designations employed and the presentation of the material in publications
      listed in the databases does not imply the expression of any opinion whatsoever on
      the part of BIREME/PAHO concerning the legal status of any country, territory, city
      or area or of its authorities, or concerning the delimitation of its frontiers or
      boundaries. Dotted and dashed lines on maps represent approximate border lines for
      which there may not yet be full agreement.
    </p>
    <p>
      The mention of specific companies or of certain manufacturers’ products in
      publications listed in the databases does not imply that they are endorsed or
      recommended by BIREME/PAHO in preference to others of a similar nature that are not
      mentioned. Errors and omissions excepted, the names of proprietary products are
      distinguished by initial capital letters.
    </p>
    <p>
      BIREME/PAHO will not be liable for any damage or loss that may arise from, or relate
      to, the use or failure of the website, information, products, services and/or tools
      contained herein, even in cases arising from improper or fraudulent use. BIREME/
      PAHO will not be responsible for the accuracy, availability or veracity of the
      information contained herein.
    </p>
    <p>
      BIREME/PAHO makes no warranties of any kind with respect to materials, information,
      products, services and/or tools available on the website and will not be responsible
      for any type of infection with computer viruses or any other program that may affect
      the operation of computers or systems.
    </p>
    <p>
      Some materials, information, products, services and/or tools to which users may
      access through this website could be owned by third parties. BIREME/PAHO does not
      guarantee their ownership, and will not be responsible to users or third parties for
      any claim derived from its use. The website, materials, information, products,
      services and/or tools may include links to external websites not managed by
      BIREME/PAHO. Such links are included for reference and their incorporation does not
      imply approval or endorsement by BIREME/PAHO. BIREME/PAHO does not assume any
      responsibility for the information contained in such websites.
    </p>
    <p>
      The use of this website by users implies the acceptance of the obligation to defend,
      hold harmless and indemnify BIREME/PAHO and its affiliates for any action, claim,
      damages, loss and/or expense (including attorneys\' fees) that is derived from such
      use.
    </p>
    <p>
      BIREME/PAHO reserves the right to use or disclose information about its users. It
      also reserves the right to edit, not publish or remove any product, services, tool
      or information or material in whole or in part at its sole discretion.
    </p>
    <h2>Unauthorized Uses</h2>
    <p>
      Users guarantee that their use of the website, information, products, services
      and/or tools contained herein will not be used for purposes that are contrary to the
      laws of any country or that contravene these terms and conditions. Users will not
      use the website, information, products, services and/or tools in a way that may
      damage, disable, overload or deteriorate the website, information, products,
      services and/or tools contained therein or its use by third parties. Users will not
      attempt to obtain any information, products, services and/or tools that are not
      intentionally available or enabled on the website.
    </p>
    <p>
      Users also undertake to respect third parties\' rights as well as intellectual
      property laws during their use of the website, information, products, services
      and/or tools contained herein.
    </p>
    <h2>Privileges and Immunities</h2>
    <p>
      Nothing contained herein shall constitute or may be construed as a waiver, express
      or implied, of the privileges, immunities and exemptions enjoyed by PAHO, in
      accordance with international law, international treaties or conventions, or the
      laws of its Members States.
    </p>
    <h2>Forum or Chat Platforms</h2>
    <p>
      BIREME/PAHO may offer dialogue platforms or other communication tools. While using
      such instruments, users undertake to refrain from:
    </p>
    <ol type="a">
      <li>
        Defaming, insulting, harassing, threating or conducting any other type of conduct
        that violates other people'S rights;
      </li>
      <li>
        Disseminating, publishing, distributing or disclosing defamatory, obscene,
        indecent, illicit or immoral information or materials;
      </li>
      <li>
        Uploading or posting as attachments files containing computer programs or other
        materials protected by intellectual property laws (or by privacy and publicity
        rights), without the corresponding authorization;
      </li>
      <li>
        Not including author'S names or legal notices, lying about the origin, or
        representing as own, computer programs or other materials contained in a file that
        is uploaded on the website;
      </li>
      <li>
        Advertising or selling goods or services, or conducting or promoting surveys,
        contests or chain letters, or downloading from a forum a file sent by another user
        that the user knows, or reasonably should know, that cannot be legally distributed
        in such way.
      </li>
    </ol>
    <p>
      Users recognize that all dialogue platforms constitute a public and not a private
      form of communication. They also acknowledge that dialogues and materials posted on
      the website are not endorsed by BIREME/PAHO and will not be considered as reviewed,
      examined or approved by BIREME/PAHO.
    </p>
    <h2>Reporting Possible Infringements</h2>
    <p>
      BIREME/PAHO will endeavor to prevent in its website activities that are illegal or
      that contravene the Terms and Conditions contained herein. However, if you believe
      that any information contained in the website, or its materials, may be in violation
      of your rights or of third parties, including, without limitation, intellectual
      property rights, you should contact BIREME/PAHO by sending a detailed description of
      the alleged violation to the following address: bir.online@paho.org
    </p>
    <h2>Conflict Resolution</h2>
    <p>
      Any dispute, controversy or claim that arises or derives from the use of the website
      or information, products, services and/or tools contained therein will be settled
      amicably between the user and BIREME/PAHO. Unless disputes or controversies are not
      settled amicably within sixty (60) days after receipt by one party of the other
      party'S request to attempt an amicable settlement, such dispute, controversy or
      claim shall be referred by either party to arbitration in accordance with the
      Arbitration Rules of the United Nations Commission on International Trade Law
      (UNCITRAL). The arbitral tribunal shall have no authority to award punitive damages.
      Any arbitration award rendered as a result of such arbitration shall be considered
      to be the final adjudication of any such controversy, claim or dispute and shall
      bind the parties.
    </p>
    <p>
      <strong
        >IF YOU DO NOT AGREE WITH ANY OF THE TERMS AND CONDITIONS CONTAINED HEREIN YOU
        MUST REFRAIN FROM USING THE WEBSITE AND ANY OTHER MATERIAL CONTAINED
        HEREIN.</strong
      >
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
